export const WEE_IO_LOGIN_DATA = "wee-io-data-login"

//PERMISSION
export const IT_ADMIN = 'ITA'
export const RECEPTIONIST = 'R'
export const HUMAN_RESOURCE = 'HR'

//PAGE
export const VISITOR = 'VISITOR'
export const LOCATION = 'LOCATION'
export const DEVICE = 'DEVICE'
export const ACTIVITY = 'ACTIVITY'
export const EMPLOYEE = 'EMPLOYEE'
export const ATTENDANCE = 'ATTENDANCE'