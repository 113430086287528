import * as keys from './key'
import * as actions from './action'

export const permission = {
    [keys.IT_ADMIN] : {
        [keys.VISITOR] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.ACCESS_LOCAION,
            actions.REMOVE
        ],
        [keys.LOCATION] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.INSERT,
            actions.UPDATE,
            actions.REMOVE,
        ],
        [keys.DEVICE] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.INSERT,
            actions.UPDATE,
            actions.REMOVE,
        ],
        [keys.ACTIVITY] : [
            actions.GET_ALL
        ],
        [keys.EMPLOYEE] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.ACCESS_LOCAION,
            actions.REMOVE
        ]
    },
    [keys.HUMAN_RESOURCE] : {
        [keys.EMPLOYEE] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.INSERT,
            actions.UPDATE_INFO,
            actions.REMOVE,
        ],
        [keys.ATTENDANCE] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.INSERT,
            actions.UPDATE,
            actions.REMOVE,
        ]
    },
    [keys.RECEPTIONIST] : {
        [keys.VISITOR] : [
            actions.GET_ALL,
            actions.DETAIL,
            actions.INSERT,
            actions.UPDATE_INFO,
            actions.ACCESS_LOCAION,
            actions.REMOVE,
        ],
        [keys.ACTIVITY] : [
            actions.GET_ALL
        ],
    }
}