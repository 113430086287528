import * as types from "../types";
import * as keys from '../../../constant/key'
import Axios from "axios";

import { host } from "../../../host/host";
import { checkErrCode } from '../../../helper/tools'
import { useHistory } from "react-router";

// data
import { ADD_EMPLOYEE_LIST_SUCCESS, PROFILE_UPDATE_SUCCESS } from "../../../data/popupData";

// action
import { openPopupNotification } from "../popupAction/popupNotificationAction";
import { getFilterAllowedLocation } from "../locationAction/locationAction"
import { storeFilterData } from '../filterAction/filterAction'
import { setFlagPrepareReload, setFlagNeedReload, setPromtNavigate } from "../systemAction/systemAction";

const SuccessCode = 0;

export const addImgSrcRecognize = (imgSrc) => ({
    type: types.EMPLOYEE_ADD_IMG_RECONIZE,
    payload: {
        imgSrc
    }
})

export const addAvatarEmployee = (imgSrc) => ({
    type: types.EMPLOYEE_ADD_AVATAR,
    payload: {
        imgSrc
    }
})

export const changeAllowedLocations = (lstLocations) => ({
    type: types.EMPLOYEE_CHANGE_ALLOWED_LOCATIONS,
    payload: {
        lstLocations
    }
})

/**
 * 
 * @param {object} objFieldChange 
 */
export const changeGeneralInfos = (objFieldChange) => ({
    type: types.EMPLOYEE_CHANGE_GENERAL_INFOS,
    payload: {
        ...objFieldChange
    }
})

export const setStatusChangeEmployeeData = (objFieldChange) => ({
    type: types.EMPLOYEE_STATUS_CHANGE_EMPLOYEE_DATA,
    payload: {
        ...objFieldChange
    }
})

// reset state employee reducer when unmount main component
export const resetItemInEmployeeReducer = (fieldName) => ({
    type: types.EMPLOYEE_RESET_STATE,
    payload: {
        fieldName
    }
})

export const addEmployee = (
    Image, VerifyImage, EmployeeID, JoinDate, FullName, 
    Gender, BirthDay, SocialID, Department, JobTitle, PermittedLocation, IsRemoved) => async dispatch => {
    // "Image": "string",
    // "VerifyImage": "string",
    // "EmployeeID": "string",
    // "JoinDate": "string",
    // "FullName": "string",
    // "Gender": "string",
    // "BirthDay": "string",
    // "SocialID": "string",
    // "Department": "string",
    // "JobTitle": "string",
    // "PermittedLocation": [
    //   "string"
    // ],
    // "IsRemoved": true

    const api = host + "user/add-employee";
    const dataQueryFormat = JSON.stringify({
        Image, VerifyImage, EmployeeID, JoinDate, FullName, 
        Gender, BirthDay, SocialID, Department, JobTitle, PermittedLocation, IsRemoved
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        if (callApiSuccess) {
            dispatch({
                type: types.ADD_EMPLOYEE,
                data: data
            });
            dispatch(resetItemInEmployeeReducer("dataItemExcuting"))
            dispatch(setFlagPrepareReload({employeeList: true}))
            dispatch(setPromtNavigate(false))
            useHistory().push("/employee/list")
            dispatch(openPopupNotification(
                ADD_EMPLOYEE_LIST_SUCCESS,
                {
                    contentFirst: `${FullName}`,        
                },
                false,
            ))
        } else {
            dispatch(setPromtNavigate(true))
            console.log(data)
            // if(data.Code === 11){
            //     dispatch(getFilterAllowedLocation(CompanyID))
            // }
            checkErr(data.Code, dispatch, {linkPath:'/employee/detail/'+data.UserID})
            dispatch({
                type: types.ADD_EMPLOYEE_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch(setPromtNavigate(true))
        dispatch({
            type: types.ADD_EMPLOYEE_ERROR,
            err
        });
    }
};

export const getFilterEmployee = ( isFilter,
    EmployeeID, Name, SocialID, Jobs, Arrlocation, NotAllowLocation,
    PageSize, PageNumber, SortType, SortOrder) => async dispatch => {
    // "EmployeeID": "string",
    // "Name": "string",
    // "SocialID": "string",
    // "Jobs": [
    //     {
    //         "Department": "string",
    //         "JobTitle": [
    //             "string"
    //         ]
    //     }
    // ],
    // "Arrlocation": [ "string" ],
    // "NotAllowLocation": true,
    // "PageSize": 0,
    // "PageNumber": 0,
    // "SortType": "SortName",
    // "SortOrder": 0
    const api = host + "user/list-employee-filter";
    let dataQuery={
        EmployeeID, Name, SocialID, Jobs, Arrlocation, NotAllowLocation,
        PageSize, PageNumber, SortType, SortOrder
    }
    const dataQueryFormat = JSON.stringify(dataQuery);
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        if (callApiSuccess) {
            dispatch(storeFilterData(keys.EMPLOYEE, {
                ...dataQuery
            }))
            dispatch({
                type: types.GET_FILTER_EMPLOYEE,
                data: data,
                isFilter
            });
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.GET_FILTER_EMPLOYEE_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.GET_FILTER_EMPLOYEE_ERROR,
            err
        });
    }
};

export const getEmployee = (UserID, CompanyID, history) => async dispatch => {
    // "UserID": "string",
    // "CompanyID": "string"
    const api = host + "user/get";
    const dataQueryFormat = JSON.stringify({
        UserID, CompanyID
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        if (callApiSuccess) {
            dispatch({
                type: types.GET_EMPLOYEE,
                data: data
            });
            history && history.push(`/employee/detail/${UserID}`)
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.GET_EMPLOYEE_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.GET_EMPLOYEE_ERROR,
            err
        });
    }
};

export const refreshEmployee = () => async dispath => {
    dispath({type:types.REFRESH_EMPLOYEE_DETAIL})
}

export const updateEmployee = (UserID, EmployeeID, JoinDate, FullName, Gender, SocialID, Image, VerifyImage, PermittedLocation, BirthDay, Department, JobTitle) => async (dispatch, getStore) => {
    // "UserID": "string",
    // "EmployeeID": "string",
    // "JoinDate": "string",
    // "FullName": "string",
    // "Gender": "string",
    // "SocialID": "string",
    // "Image": "string",
    // "VerifyImage": "string",
    // "PermittedLocation": [
    //   "string"
    // ],
    // "BirthDay": "string",
    // "Department": "string",
    // "JobTitle": "string"
    const api = host + "user/update-employee";
    const dataQueryFormat = JSON.stringify({
        UserID, EmployeeID, JoinDate, FullName, Gender, SocialID, Image, VerifyImage, PermittedLocation, BirthDay, Department, JobTitle
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        
        if (callApiSuccess) {
            dispatch({
                type: types.UPDATE_EMPLOYEE,
                data: data
            });
            dispatch(openPopupNotification(
                PROFILE_UPDATE_SUCCESS,
                {
                  contentProps: FullName,        
                },
                false,
            ))
            // dispatch(setFlagNeedReload({ employeeList: true }))
            getLstEmployeeWithCurrentCondition(getStore(), dispatch)
        } else {
            checkErr(data.Code, dispatch, {linkPath:'/employee/detail/'+data.UserID})
            dispatch({
                type: types.UPDATE_EMPLOYEE_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.UPDATE_EMPLOYEE_ERROR,
            err
        });
    }
};


export const setActiveEmployee = (UserID, CompanyID, IsRemoved) => async (dispatch, getStore) => {
    //   "UserID": "string",
    //   "CompanyID": "string",
    //   "IsRemoved": "boolen",
    const api = host + "user/set-remove";
    const dataQueryFormat = JSON.stringify({
        UserID, CompanyID, IsRemoved
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        // console.log(res);
        if (callApiSuccess) {
            dispatch({
                type: types.SET_ACTIVE_EMPLOYEE,
                data: data
            });
            getLstEmployeeWithCurrentCondition(getStore(), dispatch)
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.SET_ACTIVE_EMPLOYEE_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.SET_ACTIVE_EMPLOYEE_ERROR,
            err
        });
    }
};

const checkErr = (code, dispatch, data) => {
    let objErr = checkErrCode(keys.EMPLOYEE, code)
    console.log(code, data)
    dispatch(openPopupNotification(objErr.popupType, data, false))
}

const getLstEmployeeWithCurrentCondition = (store, dispatch) => {
    const { filterReducer, employeeReducer } = store;
    const conditionFilterEmployee = filterReducer[keys.EMPLOYEE];
    if (!conditionFilterEmployee){
        const pageSize = 10;
        const pageNumber = 1;
        const SortType = "SortTime";
        const SortOrder = -1;
        const intialConditionFilter = [
            false,
            ...new Array(6).fill(undefined),
            pageSize,
            pageNumber,
            SortType,
            SortOrder
        ]
        dispatch(getFilterEmployee(...intialConditionFilter))
        return;
    }

    dispatch(getFilterEmployee(
        employeeReducer.isFilter,
        ...Object.values(conditionFilterEmployee)
    ))

}