import React, { useEffect, useRef, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
//tools
import { immutabilityHelper } from "../../../../helper/tools";
//img
//ic
import ic_check from "../../../../images/ic_check.svg";
import ic_checked from "../../../../images/ic_checked.svg";
//data
//action
//component
//styled
const Bound = styled.span`
  display: inline-block;
  cursor: pointer;
`;

//========================MAIN COMPONENT==========================
const CheckBox = props => {
  //======================Props===================================
  //=====>Props Data
  const { data, isChecked, listDeviceSelected } = props;
  //=====>Props Function
  const { setListDeviceSelected } = props;
  //======>Props Style

  //======>Props Style
  //======================State===================================
  const [isCheckedLocal, setIsCheckedLocal] = useState(isChecked);
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  // const { itemReducer } = useSelector(state => ({
  //   item: state.itemReducer
  // }));
  //======================REF====================================
  //======================Variable================================
  //======================Function=================================
  const _handleChecked = () => {


    console.log(listDeviceSelected)
    let newListDeviceSelected = immutabilityHelper(listDeviceSelected);
    newListDeviceSelected.push(immutabilityHelper(data));

    // console.log(newListDeviceSelected);
    setListDeviceSelected(newListDeviceSelected);
    setIsCheckedLocal(!isCheckedLocal);
  };
  const _handleUnChecked = () => {
    const newListDeviceSelected = listDeviceSelected.filter(item => {
      return item.id !== data.id;
    });
    setListDeviceSelected(newListDeviceSelected);
    setIsCheckedLocal(!isCheckedLocal);
  };

  useEffect(() => {
    setIsCheckedLocal(isChecked);
  }, [isChecked]);
  //======================Lifecycle Hook===========================
  //=====================Render Main Component=====================
  return (
    <Bound className="checkbox ">
      {isCheckedLocal ? (
        <img src={ic_checked} onClick={_handleUnChecked} />
      ) : (
        <img src={ic_check} onClick={_handleChecked} />
      )}
    </Bound>
  );
};
export default React.memo(CheckBox);
