import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {login } from "../../redux/actions/loginAction/loginAction";
//ic
import ic_back_login from "../../images/ic_back_login.svg";

//component
import InputLoginControl from "../Control/InputLoginControl";
import ButtonControl from "../Control/ButtonControl";

const Bound = styled.div`
  opacity:0;
  transition: opacity 500ms;
  .back-account-checking {
    display: flex;
    margin-bottom: 40px;
    .ic_arrow_left {
      cursor: pointer;
    }
    .account-checking-text {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 125%;
      display: flex;
      align-items: center;
      color: #33adff;
      margin-left: 15px;
    }
  }
  .act-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .forgot-password {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 125%;
      color: #33adff;
      cursor: pointer;
    }
  }
`;

const BoundPasswordContainer = props => {
  let passwordLocal = "";
  const dispatch = useDispatch();
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));
  const { checkLogin, checkEmail } = loginReducer;

  const _handleLogin = () => {
    dispatch(login(checkEmail.UserName,passwordLocal));
  };
  useEffect(() => {
    props.animation();
    show()
  }, []);

  const show = () => {
    let bound = document.getElementById('bound-password-id')
    if (bound) {
      bound.style.opacity = 1
    }
  }



  return (
    <Bound id='bound-password-id'>
      <div className="back-account-checking">
        <img
          className="ic_arrow_left"
          src={ic_back_login}
          onClick={props.backMailLogin}
          alt="ic-arrow"
        />
        <p className="account-checking-text">
          You are logging in with your account {checkEmail.UserName}
        </p>
      </div>
      <InputLoginControl
        type="PASSWORD"
        isError={(checkLogin && checkLogin.Code)}
        title="Password"
        maxLength={30}
        widthStyle={540}
        heightStyle={64}
        borderRadiusStyle={5}
        borderStyle="1px solid #E8EBF1"
        backgroundColorStyle="#FDFDFD"
        placeHolder="Input password"
        colorInput='#051321'
        onChangeInput={password => (passwordLocal = password)}
        handleKeyUp={() => _handleLogin()}
      />
      <div className="act-container">
        <div></div>
        {/* <a className="forgot-password" href="/change-password">Forgot password?</a> */}
        {/* <a className="forgot-password" href="/"></a> */}

        <ButtonControl
          title="LOGIN"
          width={255}
          height={64}
          background=" #33ADFF"
          fontSize={18}
          lineHeight={64}
          cursor="pointer"
          onClickButton={() => _handleLogin()}
        />
      </div>
    </Bound>
  );
};

export default React.memo(BoundPasswordContainer);