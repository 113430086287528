import styled from "styled-components"

const TitleNotice = styled.div`
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000924;
    margin-top: 12px;
`;

export default TitleNotice