import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//img
//ic
import ic_hiden_pass from "../../images/ic_hiden_pass.svg";
import ic_show_pass from "../../images/ic_show_pass.svg";
//data
//action
//component
import LoginError from "../Control/LoginError";
//styled
const Bound = styled.div`
  position: relative;
  .input-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000924;
    margin-bottom: 10px;
  }
  .input-group {
    position: relative;
    .input-textbox {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: ${props => props.colorInput};

      background-color: ${props => props.bacgroundColorStyle};
      mix-blend-mode: normal;
      border: ${props => props.borderStyle};
      box-sizing: border-box;
      width: ${props => props.widthStyle + "px"};
      height: ${props => props.heightStyle + "px"};
      border-radius: ${props => props.borderRadiusStyle + "px"};
      padding: 0 24px;
      ::-webkit-input-placeholder {
        /* Edge */
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        display: flex;
        align-items: center;

        /* Main Blue */

        color: #000924;
        opacity: 0.3;
      }
    }
  }
  .show-password {
    position: absolute;
    right: 26px;
    cursor: pointer;
  }
`;

//========================MAIN COMPONENT==========================
const InputLoginControl = props => {
  //======================Variable================================
  //  let isShowPass = false
  //======================REF====================================
  const inputRef = React.createRef(null);

  //======================Props===================================
  //=====>Props Data
  const { isError } = props;
  //=====>Props Function
  const { onChangeInput, handleKeyUp } = props;
  //======>Props Style
  const {
    type,
    title,
    maxLength,
    widthStyle,
    heightStyle,
    colorInput,
    borderStyle,
    backgroundColorStyle,
    borderRadiusStyle,
    placeHolder
  } = props;
  //======================State===================================
  const [isShowPass, setIsShowPass] = useState(false);
  //======================Redux===================================
  //=====>Redux Action
  //   const dispatch = useDispatch();
  //=====>Redux Props
  //   const state = useSelector(state => state);
  //======================Function=================================
  const _onChangeInput = e => {
    onChangeInput(e.target.value);
  };
  const _onInputTextKeyUp = e => {
    if (e.keyCode === 13) {
      handleKeyUp();
    }
  };
  //======================Lifecycle Hook===========================
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  //=====================Render Main Component=====================
  return (
    <Bound
      widthStyle={widthStyle}
      heightStyle={heightStyle}
      borderStyle={isError ? "1px solid #ea3939" : borderStyle}
      colorInput={colorInput}
      backgroundColorStyle={backgroundColorStyle}
      borderRadiusStyle={borderRadiusStyle}
    >
      <p className="input-title">{title}</p>
      <div className="input-group">
        <input
          autoComplete="off"
          ref={inputRef}
          className="input-textbox"
          type={type === "PASSWORD" && !isShowPass ? "password" : "text"}
          maxLength={maxLength}
          id="input-textbox-id"
          // value={value}
          placeholder={placeHolder}
          onChange={_onChangeInput}
          onKeyUp={_onInputTextKeyUp}
        />
        {type === "PASSWORD" && (
          <img
            src={isShowPass ? ic_show_pass : ic_hiden_pass}
            className="show-password"
            style={isShowPass ? { top: "26px" } : { top: "24px" }}
            onClick={() => {
              if (!isShowPass) {
                document.getElementById("input-textbox-id").style.color =
                  "black";
              } else {
                document.getElementById("input-textbox-id").style.color =
                  "#969FB0";
              }
              setIsShowPass(!isShowPass);
            }}
            alt="ic-hint"
          ></img>
        )}
        {isError ? (
          <LoginError
            text={
              type === "MAIL"
                ? " Couldn't find your account"
                : type === "PASSWORD"
                ? "Wrong password"
                : // "Wrong password. Try again or click Forgot password to reset it."
                  ""
            }
          ></LoginError>
        ) : null}
      </div>
    </Bound>
  );
};
export default React.memo(InputLoginControl); //Avoid re-render === shouldComponentUpdate

InputLoginControl.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  maxLength: PropTypes.number,
  widthStyle: PropTypes.number,
  colorInput: PropTypes.string,
  heightStyle: PropTypes.number,
  borderStyle: PropTypes.string,
  backgroundColorStyle: PropTypes.string,
  borderRadiusStyle: PropTypes.number,
  placeHolder: PropTypes.string,
  onChangeInput: PropTypes.func
};
InputLoginControl.defaultProps = {
  type: "",
  title: "Email",
  maxLength: 30,
  widthStyle: 540,
  colorInput: "#000924",
  heightStyle: 64,
  borderStyle: "border: 1px solid #E8EBF1",
  backgroundColorStyle: "#FDFDFD",
  borderRadiusStyle: 5,
  placeHolder: "Input email"
};
