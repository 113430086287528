import * as types from "../types";
import * as keys from '../../../constant/key'
import { host } from "../../../host/host";
import Axios from "axios";
//
import { deviceList } from "../../../data/mockData";
// action
import { storeFilterData } from "../filterAction/filterAction";
const SuccessCode = 0;

export const getAndFilterDevice = (
  DeviceModel,
  DeviceName,
  ArrLocationID,
  UnLinkStatus,
  Status,
  PageSize,
  PageNumber,
  isFilter=undefined
) => async dispatch => {
  const apiGetAllDevice = host + "device/listDeviceFilter";
  const dataQuery = {
    DeviceModel,
    DeviceName,
    ArrLocationID,
    UnLinkStatus,
    IsRemoved: "false",
    Status,
    PageSize,
    PageNumber
  }
  const dataQueryFormat = JSON.stringify({
    ...dataQuery
  });
  dispatch({
    type: types.GET_AND_FILTER_DEVICE_START
  });

  try {
    const res = await Axios.post(apiGetAllDevice, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.GET_AND_FILTER_DEVICE_SUCCESS,
        data: data
      });
      dispatch(storeFilterData(keys.DEVICE, {
        ...dataQuery,
        isFilter
      }))
    } else {
      dispatch({
        type: types.GET_AND_FILTER_DEVICE_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_AND_FILTER_DEVICE_FAIL,
      err
    });
  }
};

export const removeDevice = ID => async dispatch => {
  const apiRemoveDevice = host + "device/removeDevice";
  const dataQueryFormat = JSON.stringify({
    ID
  });
  dispatch({
    type: types.REMOVE_DEVICE_START
  });

  try {
    const res = await Axios.post(apiRemoveDevice, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.REMOVE_DEVICE_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.REMOVE_DEVICE_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.REMOVE_DEVICE_FAIL,
      err
    });
  }
};

export const deviceLinkLocation = (
  ArrDeviceID,
  LocationID
) => async dispatch => {
  // console.log(ArrDeviceID, LocationID);
  const apiDeviceLinkLocation = host + "device/linkDeviceLocation";
  const dataQueryFormat = JSON.stringify({
    ArrDeviceID,
    LocationID
  });

  dispatch({
    type: types.DEVICE_LINK_LOCATION_START
  });

  try {
    const res = await Axios.post(apiDeviceLinkLocation, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.DEVICE_LINK_LOCATION_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.DEVICE_LINK_LOCATION_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.DEVICE_LINK_LOCATION_FAIL,
      err
    });
  }
};

export const deviceUnlinkLocation = ID => async dispatch => {
  const apiUnlinkDevice = host + "device/unlinkDevice";
  const dataQueryFormat = JSON.stringify({
    ID
  });

  dispatch({
    type: types.DEVICE_UNLINK_LOCATION_START
  });

  try {
    const res = await Axios.post(apiUnlinkDevice, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.DEVICE_UNLINK_LOCATION_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.DEVICE_UNLINK_LOCATION_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.DEVICE_UNLINK_LOCATION_FAIL,
      err
    });
  }
};
export const getListDeviceLinked = (
  LocationID,
  PageSize,
  PageNumber
) => async dispatch => {
  const apiListDeviceLinked = host + "device/listDevice";
  
  const dataQuery = {
    LocationID,
    PageSize,
    PageNumber
  }

  const dataQueryFormat = JSON.stringify({ ...dataQuery });
  dispatch({
    type: types.GET_LIST_DEVICE_LINKED_START
  });
  try {
    const res = await Axios.post(apiListDeviceLinked, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    if (callApiSuccess) {
      dispatch({
        type: types.GET_LIST_DEVICE_LINKED_SUCCESS,
        data: { ...data, dataQuery }
      });
    } else {
      dispatch({
        type: types.GET_LIST_DEVICE_LINKED_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_LIST_DEVICE_LINKED_FAIL,
      err
    });
  }
};

export const clearDataDeviceLinked = () => ({
  type: types.CLEAR_DATA_DEVICE_LINKED
})

export const getDeviceNotLink = (PageSize, PageNumber, Name) => async dispatch => {
  const apiDeviceLinkLocation = host + "device/getDeviceNotLink";
  
  const dataQuery = {
    PageSize,
    PageNumber,
    Name
  }
  const dataQueryFormat = JSON.stringify({ ...dataQuery });

  dispatch({
    type: types.GET_DEVICE_NOT_LINK_START
  });

  try {
    const res = await Axios.post(apiDeviceLinkLocation, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.GET_DEVICE_NOT_LINK_SUCCESS,
        data: { ...data, dataQuery }
      });
    } else {
      dispatch({
        type: types.GET_DEVICE_NOT_LINK_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_DEVICE_NOT_LINK_FAIL,
      err
    });
  }
};

export const clearDataDeviceNotLink = () => ({
  type: types.CLEAR_DATA_DEVICE_NOT_LINK
})

export const getDeviceNotLinkAndDeviceLinked = (
  LocationID,
  PageSize,
  PageNumber
) => async dispatch => {
  const apiDeviceLinkNotLocation = host + "device/getDeviceNotLink";
  const apiListDeviceLinked = host + "device/listDevice";
  const dataQueryFormatDeviceLinkNotLocation = JSON.stringify({
    PageSize,
    PageNumber
  });
  const dataQueryFormatListDeviceLinked = JSON.stringify({
    LocationID,
    PageSize,
    PageNumber
  });

  const requestDeviceLinkNotLocation = Axios.post(
    apiDeviceLinkNotLocation,
    dataQueryFormatDeviceLinkNotLocation
  );
  const requestListDeviceLinked = Axios.post(
    apiListDeviceLinked,
    dataQueryFormatListDeviceLinked
  );
  dispatch({
    type: types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_START
  });
  try {
    const res = await Axios.all([
      requestDeviceLinkNotLocation,
      requestListDeviceLinked
    ]);

    const dataDeviceLinkNotLocation = res[0].data;
    const dataListDeviceLinked = res[1].data;
    const callApiSuccess =
      dataDeviceLinkNotLocation.Code === SuccessCode &&
      dataListDeviceLinked.Code === SuccessCode
        ? true
        : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_SUCCESS,
        data: {
          listDeviceNotLink: dataDeviceLinkNotLocation.Data,
          listDeviceLinked: dataListDeviceLinked.Data
        }
      });
    } else {
      dispatch({
        type: types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_FAIL,
        data: {}
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_FAIL,
      err
    });
  }
};
