/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from "react";
import PropTypes from "prop-types";
import dangerStyles from "./dangerStyles";
import primaryStyles from "./primaryStyles";
import lightStyles from "./lightStyles";
import buttonStyles from "./buttonStyles";
import styled from "styled-components";

const Bound = styled.div`
  ${buttonStyles}
  ${props => props.css}
`;

function Button(props) {
  const { type, img, text, className, onClick } = props;
  let css = null;
  switch (type) {
    case "PRIMARY":
      css = primaryStyles;
      break;
    case "DANGER":
      css = dangerStyles;
      break;
    case "LIGHT":
      css = lightStyles;
      break;
    default:
      break;
  }
  return (
    <Bound css={css} className={className} onClick={() => onClick()}>
      {img && <img className="button__img" src={img} alt="img"></img>}
      <p className="button__text">{text}</p>
    </Bound>
  );
}

export default Button;
Button.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
