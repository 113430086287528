import styled from "styled-components"

const MoreDetail = styled.div`
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000924;
    padding: 0 58px;
    margin-top: 6px;
`;

export default MoreDetail;