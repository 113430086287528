import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

//component
import MainContainer from "./ui/MainContainer";
import PromtLeave from "./helper/CustomPromt/PromLeave";

function App() {
    return (
        <Router getUserConfirmation={(message, callback) =>
            PromtLeave(message, callback)}>
            <MainContainer />
        </Router>
    );
}

export default App;
