import * as types from "../types";
import { host } from "../../../host/host";
import Axios from "axios";
//
const SuccessCode = 0;

export const setSocket = socket => async dispatch => {
  dispatch({
    type: types.SET_SOCKET_SUCCESS,
    data: socket
  });
};
