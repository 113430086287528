import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

//component
import LoginPage from "./Login/LoginPage";
import MainPage from "./Main/MainPage";
import PopupNotification from "./Main/PopupContainer/PopupNotification";
import PopupContainer from "./Main/PopupContainer/PopupContainer";
import LoadingPage from './Control/LoadingPage'

const Bound = styled.div`
  display: flex;
  flex: 1;
  /* min-width:1440px; */
  position: relative;
  .popupNotification {
    z-index: 11;
    position: absolute;
    top: ${props => (props.activeOverlay ? "0" : "7%")};
    left: ${props => (props.activeOverlay ? "0" : "50%")};
    transform: ${props => (props.activeOverlay ? null : "translate(-50%)")};
    width: ${props => props.activeOverlay && "100%"};
    height: ${props => props.activeOverlay && "100%"};
    /* animate */
    animation-name: isShowPopup;
    transition-property: bottom;
    transition-duration: 500ms;
    @keyframes isShowPopup {
      from {
        top: -100px;
      }
      to {
        top: 45px;
      }
    }
  }
`;

const MainContainer = props => {
  const {
    loginReducer,
    popupNotificationReducer,
    popupContainerReducer,
    popupLoadingReducer
  } = useSelector(state => ({
    loginReducer: state.loginReducer,
    popupNotificationReducer: state.popupNotificationReducer,
    popupContainerReducer: state.popupContainerReducer,
    popupLoadingReducer: state.popupLoadingReducer
  }));
  const [isLoading, setIsLoading] = useState(popupLoadingReducer.isLoading)
  useEffect(() => {
    if(!popupLoadingReducer)
      return
    setIsLoading(popupLoadingReducer.isLoading)
  }, [popupLoadingReducer])
  return (
    <Bound activeOverlay={popupNotificationReducer.activeOverlay}>
      {!(loginReducer.checkLogin && loginReducer.checkLogin.Code === 0) ? (
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      ) : (
        <MainPage />
      )}
      {
        popupNotificationReducer.typePopup !== -1 && 
        <div className="popupNotification">
          <PopupNotification />
        </div>
      }
      {
        popupContainerReducer.typePopup !== -1 && 
        <PopupContainer />
      }
      {
        isLoading &&
        <LoadingPage />
      }
    </Bound>
  );
};
export default React.memo(MainContainer);
