import React from 'react'

import TitleNotice from "./TitleNotice"
import MoreDetail from "./MoreDetail"

const DetailNoticeErrImgNoFace = () => {
    return (
        <>
            <TitleNotice>Could not use this photo</TitleNotice>
            <MoreDetail>Make sure the image contains face photo and background</MoreDetail>
        </>
    )
}

export default DetailNoticeErrImgNoFace
