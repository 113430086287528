import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

//action
import {
  checkEmailLogin
} from "../../redux/actions/loginAction/loginAction";

//component
import InputLoginControl from "../Control/InputLoginControl";
import ButtonControl from "../Control/ButtonControl";
//tools
const Bound = styled.div`
  opacity:0;
  transition: opacity 500ms;
  .welcom-login-text {
    width: 350px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 125%;
    display: flex;
    align-items: center;
    color: #000924;
    margin-bottom: 90px;
  }
`;

const BoundMailContainer = props => {
  const [ emailLocal, setEmailLocal] = useState("")
  // let emailLocal = "";

  const dispatch = useDispatch();
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));
  const { checkEmail } = loginReducer;

  useEffect(() => {
    props.animation();
    show()
  }, []);

  const show=()=>{
    let bound  = document.getElementById('bound-email-id')
    if(bound) {
      bound.style.opacity=1
    }
  }

  const _handleCheckEmail = () => {
    dispatch(checkEmailLogin(emailLocal.trim()));    
  };
  
  return (
    <Bound id='bound-email-id'>
      <p className="welcom-login-text">
        Welcome back, log in to the admin account to continue.
      </p>
      <InputLoginControl
        type="MAIL"
        isError={checkEmail && checkEmail.Code}
        title="Email"
        maxLength={30}
        widthStyle={540}
        heightStyle={64}
        borderRadiusStyle={5}
        borderStyle={"1px solid #E8EBF1"}
        backgroundColorStyle="#FDFDFD"
        placeHolder="Input email"
        onChangeInput={email => (setEmailLocal(email))}
        handleKeyUp={() => _handleCheckEmail()}
      />
      <ButtonControl
        title="NEXT"
        width={255}
        height={64}
        fontSize={18}
        lineHeight={64}
        cursor="pointer"
        margin="40px 0 0 285px"
        onClickButton={() => _handleCheckEmail()}
      />
    </Bound>
  );
};

export default React.memo(BoundMailContainer);
