import * as types from "../../actions/types";

export const initialState = {
  type: "",
  data: {},
  error: ""
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_PASS_SUCCESS:
      return {
        ...state,
        data: action.data,
        err: "",
        type: action.type
      };
    case types.CHANGE_PASS_FAIL:
      return {
        ...state,
        data: action.data,
        error: action.err,
        type: action.type
      };
    case types.RESET_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
