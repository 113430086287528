import React from 'react'

import TitleNotice from "./TitleNotice"
import MoreDetail from "./MoreDetail"

const DetailNoticeErrImgMoreFace = () => {
    return (
        <>
            <TitleNotice>Could not use this photo</TitleNotice>
            <MoreDetail>Your facial recognition photo has more than one face</MoreDetail>
        </>
    )
}

export default DetailNoticeErrImgMoreFace
