import * as types from "../../actions/types";
import { dropDownMenuData } from "../../../data/dropDownData";
export const initialState = {
  orderPath: "",
  dropDownMenuData: dropDownMenuData,
  indexActive: -1,
  indexDropdown: -1,
  indexMainSelected: 0,
  indexSubSelected: 0
};

export default function routerReducer(state = initialState, action) {
  switch (action.type) {
    case types.SWITCH_ROUTER:
      return {
        ...state,
        indexMainSelected: action.indexMainSelected,
        indexSubSelected: action.indexSubSelected
      };
    case types.REFRESH_ROUTER:
      return {
        ...state,
        indexMainSelected:0,
        indexSubSelected:0
      }
    case types.FILLTER_ACCESS_RIGHT_DROPDOWN_MENU:
      return {
        ...state,
        dropDownMenuData: action.dropDownMenuData,
        indexDropdown: action.indexDropdown,
        orderPath: action.orderPath
      };
    default:
      return state;
  }
}
