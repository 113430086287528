import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// images
import ic_add from "../../../images/ic_add.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
import ic_tick_visitor from "../../../images/ic_tick_visitor.svg"
import ic_check from "../../../images/ic_check.svg"
/// data

/// components

import InputTemplate from "../../../ui/Control/InputTemplate";
import CheckBoxAddVisitor from "./Control/CheckBoxAddVisitor";
import CheckBox from './Control/CheckBox'
//action
////
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
import { openPopupNotification } from "../../../redux/actions/popupAction/popupNotificationAction";
import { addNewLocation, getLocationBranch } from "../../../redux/actions/locationAction/locationAction";
const Bound = styled.div`
  padding: 64px 96px;
  width: 1110px;
  height: 699px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  background: #fafafa;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  /* animate */
  animation-name: isShowPopup;
  transition-property: bottom;
  transition-duration: 500ms;
  display: relative;
  @keyframes isShowPopup {
    from {
      top: -100px;
    }
    to {
      top: 45px;
    }
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  /* width: 540px;
  margin:auto; */
  .header-container {
    .title-content {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      display: flex;
      align-items: center;
      color: #051321;
    }
  }
  .form-add-new-container {
    display: grid;
    grid-template-rows: 110px 110px 70px 20px auto;
    width: 540px;
    margin: auto;
    margin-top: 110px;
    .button-container {
      display: flex;
      justify-content: flex-end;
      .add-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 116px;
        height: 50px;
        background-color: #33adff;
        border-radius: 5px;
        cursor: pointer;
        img {
          margin-right: 15px;
        }
        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.03em;

          color: #ffffff;
        }
      }
    }
    .block-parent-location {
      display: flex;
      .parent-location-title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        margin-right:12px;
      }
      .parent-location-content {
        font-size: 13px;
        line-height: 18px;
        color: #9e9e9e;
      }
    }
    .block-tick-visitor {
      display: flex;
      align-items: center;
      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #051321;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
`;

function PopupAddChildLocation(props) {
  // let history = useHistory();
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();

  let history = useHistory();
  //=====>Redux Props
  const { data, TypePopup, locationReducer, loginReducer } = useSelector(state => ({
    TypePopup: state.popupContainerReducer.typePopup,
    data: state.popupContainerReducer.data,
    locationReducer: state.locationReducer,
    loginReducer: state.loginReducer
  }));

  //======================REF====================================
  //======================Variable================================

  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  const [isChecked, setIsCheckes] = useState(false)

  const [locationInput, setLocationInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      locationNameLocal: "",
      descriptionLocal: "",
      locationID:data.locationID,
      isAllowedVisitor: false
    }
  );

  const [validInput, setValidInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      locationNameTextWarning: ""
    }
  );
  //======================Function=================================
  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  const _handleAddNewLocation = async () => {
    const {
      locationNameLocal,
      descriptionLocal,
      isAllowedVisitor,
      locationID

    } = locationInput;

    // console.log(locationInput);
    //===============================================CHECK INPUT EMPTY START================================
    const inputEmptyWarning = {
      locationNameTextWarning:
        !locationNameLocal || locationNameLocal.trim().length === 0
          ? "Location Name must not be blank"
          : /[\*\/\\\?\$#@!%^&()]/g.test(locationNameLocal)
          ? "The location name does not contain any special characters"
          : "",
    };
    if (
      Object.keys(inputEmptyWarning).filter(element => {
        return inputEmptyWarning[element] !== "";
      }).length > 0
    ) {
      setValidInput(inputEmptyWarning);
      return;
    } else {
      dispatch(
        addNewLocation(
          locationNameLocal,
          descriptionLocal,
          isAllowedVisitor,
          locationID
        )
      );
    }

  };
  //HOOK
  // useEffect(() => {
  //   const { locationInfos } = data;
  //   if (locationInfos && locationInfos.AllowedUser){
  //     const isAllowedVisitor = !!locationInfos.AllowedUser.length
  //     setIsCheckes(isAllowedVisitor)
  //     setLocationInput(isAllowedVisitor)
  //   }
  // }, [])
  useEffect(() => {
    if ( 
      locationReducer.type === "ADD_NEW_LOCATION_FAIL" && 
      locationReducer.data && 
      locationReducer.data.Code === 17) {
      // dispatch(getAndSearchLocation(loginReducer.checkLogin.CompanyID, ""));
      const { LocationID } = locationReducer.data;
      dispatch(getLocationBranch(
        loginReducer.checkLogin.CompanyID, 
        data.locationID
      ));
      dispatch(
        openPopupNotification(
          "LOCATION_NAME_ALREADY_EXIST",
          {
            isAction: () => {
              dispatch(closePopup());
              history.push(
                `/device-setting/location/detail/${LocationID}/reload`
              );
            }
          },
          false
        ) 
      );
      
    }
  }, [locationReducer])

  useEffect(() => {
    if (locationReducer.type === "ADD_NEW_LOCATION_SUCCESS") {
      dispatch(
        openPopupNotification(
          "ADD_NEW_LOCATION_SUCCESS",
          {
            contentFirst: `${locationInput.locationNameLocal}`,
            contentProps:"has been add",
            contentLast:`${data.Name}`
          },
          false
        )
      );
      dispatch(closePopup());
    }
  }, [locationReducer]);

  const handleChecked = () => {
    let checked = !isChecked
    setIsCheckes(checked)
    setLocationInput({isAllowedVisitor: checked})
  }

  return (
    <Bound>
      <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      />
      <Content>
        <div className="header-container">
          <div className="title-content">Add child location</div>
        </div>
        <div className="form-add-new-container">
          <InputTemplate
            title="Location Name"
            maxLength={30}
            placeHolder="Input Location Name"
            background="#ffffff"
            heightInput={50}
            typeInput="LOCATION_NAME"
            textWarning={validInput.locationNameTextWarning}
            setTextWarning={text => {
              setValidInput({ locationNameTextWarning: text });
            }}
            onChangeInput={locationName => {
              setLocationInput({ locationNameLocal: !!locationName ? locationName.trim().replace(/\s+/g, " ") : "" });
            }}
          />
          <InputTemplate
            title="Description"
            maxLength={30}
            placeHolder="Input Description"
            background="#ffffff"
            heightInput={50}
            typeInput="DESCRIPTION"
            textWarning={""}
            setTextWarning={text => {
              setValidInput({ descriptionTextWarning: "" });
            }}
            onChangeInput={description => {
              setLocationInput({ descriptionLocal: description });
            }}
          />

          <div className="block-parent-location">
            <p className="parent-location-title">Parent location</p>
            <p className="parent-location-content">
              {data.Name
                ? data.Name
                : "No parent location"}
            </p>
          </div>
          <div className="block-tick-visitor">
            {/* <CheckBoxAddVisitor
              isAllowedVisitor={locationInput.isAllowedVisitor}
              onChangeInput={() => {
                setLocationInput({
                  isAllowedVisitor: !locationInput.isAllowedVisitor
                });
              }}
            /> */}
            {isChecked ?
              <img onClick={handleChecked} src={ic_tick_visitor} alt="check" />
              :
              <img onClick={handleChecked} src={ic_check} alt="check" />
            }
            <span>Receptionist can allow Visitors entry this location</span>
          </div>

          <div className="button-container">
            <div className="add-btn" onClick={_handleAddNewLocation}>
              <img src={ic_add} alt="ic_add" />
              <span>ADD</span>
            </div>
          </div>
        </div>
      </Content>
    </Bound>
  );
}

export default PopupAddChildLocation;
