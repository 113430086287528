import * as types from "../types";
import { host } from "../../../host/host";
import Axios from "axios";
//
const SuccessCode = 0;
export const generateDeviceQR = (UID) => async dispatch => {
  const apiGenerateDeviceQR = host + "admin/generateDeviceQR";
  const dataQueryFormat = JSON.stringify({
    UID
  });
  dispatch({
    type: types.GENERATE_DEVICE_QR_START
  });

  try {
    const res = await Axios.post(apiGenerateDeviceQR, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.GENERATE_DEVICE_QR_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.GENERATE_DEVICE_QR_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GENERATE_DEVICE_QR_FAIL,
      err
    });
  }
};


export const clearDeviceQR = () => ({
  type: types.CLEAR_DEVICE_QR_CODE
})