import React, { useEffect } from 'react'
import styled from 'styled-components'

// image
import ic_close_remove_popup from "../../../../images/ic_close_remove_popup.svg";

const Bound = styled.div`
    padding: 0 0 48px;
    width: 409px;
    /* height: 272px; */
    box-sizing: border-box;
    border-radius: 16px;
    background: #ffffff;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    background: #fafafa;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    text-align: center;
    position: relative;

    .close-popup {
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
    }

    .notice-image {
        margin-top: 60px;
    }
`;

const NoticePopupControl = (props) => {

    useEffect(() => {
        window.addEventListener("keydown", props.handleEventKeyPress)
        return () => {
            window.removeEventListener("keydown", props.handleEventKeyPress)
        }
    }, [props.handleEventKeyPress])

    return (
        <Bound>
            <img className="close-popup" src={ic_close_remove_popup} alt="close-popup" onClick={props.closePopup} />
            <img className="notice-image" src={props.iconNotice} alt="notice" />
            {props.detailInfo}
            {props.actionBtn}
        </Bound>
    )
}

export default NoticePopupControl
