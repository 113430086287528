import * as types from "../../actions/types";

export const initialState = {
  type: "",
  data: {},
  error: ""
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case types.GENERATE_DEVICE_QR_START:
      return {
        ...state,
        type: action.type,
      };
    case types.GENERATE_DEVICE_QR_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: "",
        type: action.type
      };
    case types.GENERATE_DEVICE_QR_FAIL:
      return {
        ...state,
        data: { ...action.data },
        error: action.err,
        type: action.type
      };
    case types.CLEAR_DEVICE_QR_CODE:
      return initialState
    default:
      return state;
  }
}
