import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import * as types from "./redux/actions/types";
import axios from "axios";
import { logout, callInSetTimeOut, dateTimeExpiredInMilisecon } from "./redux/actions/loginAction/loginAction";

import {
  openPopup,
  closePopup
} from "./redux/actions/popupAction/popupContainerAction";
// import Cookies from 'js-cookie';

//constant
import * as keys from './constant/key'

import packageJson from '../package.json';


const store = configureStore();

axios.interceptors.request.use(
  request => {
    return request;
  },
  err => {
    console.log("Request Error", err);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    // console.log("Response", err.response);
    if (err.response) {
      switch (err.response.status) {
        case 403:{
          store.dispatch(logout());
          store.dispatch(closePopup());
          store.dispatch(openPopup("ALERT_OUT_SESSION"));
        }
          break;
        case 418:{
          store.dispatch(logout());
          store.dispatch(closePopup());
          store.dispatch(openPopup("ALERT_LOGOUT"));
        }
          break;
        default:
          store.dispatch({
            type: types.OPEN_POPUP_NOTIFICATION,
            typePopup: "ERR_CONNECT_SERVER",
            data: {},
            activeOverlay: false
          });
          break;
      }
      
    } else {
      store.dispatch({
        type: types.OPEN_POPUP_NOTIFICATION,
        typePopup: "ERR_CONNECT_SERVER",
        data: {},
        activeOverlay: false
      });
    }
  }
);
// var connection =
//   navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// console.log(connection);
// console.log(connection.effectiveType)
//======================================================
console.log("version: ", packageJson.version);
const dataLogin = JSON.parse(localStorage.getItem(keys.WEE_IO_LOGIN_DATA));

if (dataLogin && dataLogin.Code === 0) {
  const dateTimeLogedIn = localStorage.getItem('dateTimeLogedIn');
  if (dateTimeLogedIn){
    const currentTime = moment();
    const totalTimeFromLogedIn = Math.abs(currentTime.diff(moment(dateTimeLogedIn)));
    if(totalTimeFromLogedIn >= dateTimeExpiredInMilisecon) {
      callInSetTimeOut(store.dispatch)
    }else{
      const milisecondsExpired = dateTimeExpiredInMilisecon - totalTimeFromLogedIn;
      window.timeoutLogout = setTimeout(() => {
        callInSetTimeOut(store.dispatch)
      }, milisecondsExpired)
      store.dispatch({ type: types.LOGIN_SUCCESS, payload: dataLogin });
    }
  }
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
