import React, { useEffect, useRef, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Axios from "axios";

//tools
//img
//ic
//data
//action
//component
//styled
const Bound = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  .count {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 10px;
    color: #33adff;
  }
  span {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #828a99;
  }
`;

//========================MAIN COMPONENT==========================
const ComponentName = props => {
  //======================Props===================================
  //=====>Props Data
  const { time, qr } = props;

  //=====>Props Function
  const { setIsTimeout } = props;
  //======>Props Style
  //======================State===================================
  const [count, setCount] = useState(time);
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  // const { itemReducer } = useSelector(state => ({
  //   item: state.itemReducer
  // }));

  //======================REF====================================
  const nameRef = React.useRef(null);
  //======================Variable================================
  const variable = "";
  //======================Function=================================
  const _myFunction = argument => {};
  //======================Lifecycle Hook===========================

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      if (count !== 0) {
        setCount(count - 1);
        // if (count === 175) {
        //   Axios.post(
        //     "https://weeio.weedigital.vn/v1/pos/connectDevice",
        //     JSON.stringify({
        //       Name:qr,
        //       Model: "2",
        //       DeviceType: "GO",
        //       PublicKey:
        //         "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1Grv+XjJoIAMlR/JaMWWf6+b5nPo4lifpFYpXMO332guLco5IuCJ4LNF63luI/Y5iukaNhH8bcFbpWvJ9BC9rcqOijPlGOfzIDyLU++rZ7fWDNiyPrUD5L0yL1SC4p4NZP87Q56jmk+FUnJmUdCBsAFrkdpDpiaww3wOGdaJIpOxScKD0CorseCuQVPlrhg8uuek+kWkDESKWc/8pXWimlKyzJAWwUnN0moH0ruWA0XE8ByIQfqP+yTE2FCwEx8lp1WU/E7s1p6KQUreBgamgcpO78ndVaUsv0cJ19er5Q1z3S2PJ0CK/Yr3LnWna4upC29OUGObEjtM1f5Uy+eChwIDAQAB",
        //       SessionID: qr
        //     })
        //   );
        // }
      } else if (count === 0) {
        setIsTimeout(true);
      }
    }, 1000);
    return () => {
      clearTimeout(idTimeout);
    };
  });

  //=====================Render Main Component=====================
  return (
    <Bound>
      <p className="count">{count}</p>
      <span>Seconds</span>
    </Bound>
  );
};
export default React.memo(ComponentName);
