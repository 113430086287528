import * as types from "../../actions/types";
import packageJson from '../../../../package.json';

export const initialState = {
    loaded: {
        locationsList: false, //"/device-setting/location"
        // visitorAddNew: false,
        visitorList: true, //"/visitor/list"
        employeeList: true
    },
    prepareReload: {
        locationsList: true, //"/device-setting/location"
        visitorAddNew: false,
        visitorEdit: false,
        visitorList: false, //"/visitor/list"
        employeeAddNew: false,
        employeeEdit: false,
        employeeList: true
    },
    needReload: {
        visitorList: false, // use in add and edit visitor
        employeeList: false
    },
    systemVersion: {
        fronEndInfos: {
            version: process.env.REACT_APP_TAG || "dev-front-end",
            build: process.env.REACT_APP_COMMIT_SHA || packageJson.version
        },
        backEndInfos: {
            version: "",
            build: ""
        }
    },
    isNotify: false
};

export default function systemReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_FLAG_PREPARE_RELOAD:
        return {
            ...state,
            prepareReload: {
                ...state.prepareReload,
                ...action.payload
            }
        }
    case types.SET_FLAG_LOADED:
        return {
            ...state,
            loaded: {
                ...state.loaded,
                ...action.payload
            }
        }
    case types.SET_FLAG_NEED_RELOAD:
        return {
            ...state,
            needReload: {
                ...state.needReload,
                ...action.payload
            }
        }
    case types.ADD_BACK_END_INFO:
        return {
            ...state,
            systemVersion: {
                ...state.systemVersion,
                backEndInfos: {
                    version: action.payload.Version,
                    build: action.payload.ReVersion
                }
            }
        }
    case types.SET_PROMT_NAVIGATE:
        return {
            ...state,
            isNotify: action.payload.isNotify
        }
    // case types.RESET_FLAG_PREPARE_RELOAD_BY_KEY:
    //     break;
    default:
      return state;
  }
}
