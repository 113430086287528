import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//img
//ic
import ic_error_login from "../../images/ic_error_login.svg";
//data
//action
//component
//styled
const Bound = styled.div`
  position: absolute;
  bottom: -24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ea3939;
  img{
    margin-right:8px;
  }
`;

//========================MAIN COMPONENT==========================
const LoginError = props => {
  //======================REF====================================
  //======================Variable================================
  //======================Props===================================
  //=====>Props Data
  const {text}=props
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  //======================Redux===================================
  //=====>Redux Action
  //=====>Redux Props
  //======================Function=================================
  //======================Lifecycle Hook===========================
  //=====================Render Main Component=====================
  return (
    <Bound>
      <img  src={ic_error_login} />
      <span>{text}</span>
    </Bound>
  );
};
export default React.memo(LoginError); //Avoid re-render === shouldComponentUpdate

LoginError.propTypes = {
  text: PropTypes.string
};
LoginError.defaultProps = {
  text: ""
};
