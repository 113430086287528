import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Bound = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  height: ${props => (props.height ? props.height : 60)}px;
  background-color: ${props => (props.bgColor ? props.bgColor : "")};
  border-radius: ${props => (props.borRadius ? props.borRadius : 30)}px;
  border-style: solid;
  border-width: 1px;
  margin: ${props => props.margin || "unset"};
  cursor: ${props => props.cursor || "pointer"};
  border-color: ${props => (props.borColor ? props.borColor : 'transparent')};
  transition: all 0.3s ease-in-out;
  .button-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      margin-left: 17px;
      width: 100%;
      height: 100%;
    }
  }
  .button-title {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    p {
      font-family: "Open Sans";
      font-size: ${props => (props.fontSize ? props.fontSize : 16)}px;
      font-weight: bold;
      line-height: ${props => (props.lineHeight ? props.lineHeight : 27)}px;
      letter-spacing: 0.05px;
      color: ${props => (props.textColor ? props.textColor : '#ffffff')};
      text-transform: uppercase;
      margin: 0;
    }
  }
`;

const ButtonControl = props => {
  const [state, setState] = useState({
    disabled: false
  });

  useEffect(() => {
    setState({
      disabled: props.disabled
    });
  }, [props.disabled]);

  const { disabled } = state;
  const {
    title,
    width,
    height,
    borRadius,
    img,
    fontSize,
    lineHeight,
    cursor,
    margin,
    bgColor,
    borColor,
    textColor
  } = props;
  return (
    <Bound
      cursor={cursor}
      width={width}
      height={height}
      fontSize={fontSize}
      lineHeight={lineHeight}
      borRadius={borRadius}
      borColor={borColor}
      bgColor={bgColor ? bgColor : disabled ? "#859CBE" : "#1279DA"}
      margin={margin}
      textColor={textColor}
      onClick={() =>
        !disabled && props.onClickButton ? props.onClickButton() : {}
      }
    >
      {img && (
        <div className="button-img">
          <img src={img} alt="img"></img>
        </div>
      )}
      <div className="button-title">
        <p>{title}</p>
      </div>
    </Bound>
  );
};



export default React.memo(ButtonControl);

