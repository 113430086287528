import * as types from "../../actions/types";

export const initialState = {
  isLoading:false
}

const popupLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.START_LOADING:
        return {
            ...state,
            isLoading:true
        }
    case types.STOP_LOADING:
        return {
            ...state,
            isLoading:false
        }
        
    default:
        return {
            ...state,
            isLoading:false
        }
  }
};

export default popupLoadingReducer;
