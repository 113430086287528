import popupNotificationReducer from "./popupNotificationReducer";
import popupContainerReducer from "./popupContainerReducer";
import popupLoadingReducer from './popupLoadingReducer'

const popupRootReducer = {
  popupNotificationReducer,
  popupContainerReducer,
  popupLoadingReducer
};

export default popupRootReducer;
