import axios from "axios";

import * as types from "../types";
import { host } from "../../../host/host";

/**
 * 
 * @param {Object.<string, boolean>} objFlag 
 */
export const setFlagPrepareReload = (objFlag) => ({
    type: types.SET_FLAG_PREPARE_RELOAD,
    payload: {
        ...objFlag
    }
})

/**
 * 
 * @param {Object.<string, boolean>} objFlag 
 */
export const setFlagLoaded = (objFlag) => ({
    type: types.SET_FLAG_LOADED,
    payload: {
        ...objFlag
    }
})

/**
 * 
 * @param {Object.<string, boolean>} objFlag 
 */
export const setFlagNeedReload = (objFlag) => ({
    type: types.SET_FLAG_NEED_RELOAD,
    payload: {
        ...objFlag
    }
})

export const getVersionBackEnd = () => async dispatch => {
    const api = host + "admin/get-version";
    // console.log(data)
    try {
        const res = await axios.get(api)
        const { data } = res;
        dispatch({
            type: types.ADD_BACK_END_INFO,
            payload: {
                ...data
            }
        })
    }
    catch(err){
        console.log(err);
    }
}

export const setPromtNavigate = (isNotify) => ({
    type: types.SET_PROMT_NAVIGATE,
    payload: {
        isNotify
    }
})