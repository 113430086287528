import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
//image
import ic_dropdown from "../../images/ic_dropdown.svg";
// action
import { setFlagPrepareReload } from "../../redux/actions/systemAction/systemAction";

// data
import { RECEPTIONIST } from "../../constant/key";
// import ic_arrowUp from "../../images/ic_arrowUp_blue.svg";
// import { switchRouter } from "../../redux/actions/routerAction/routerAction";

const Bound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .dropdown {
    position: relative;
    &-choice {
      background-color: rgba(51, 173, 255, 0.5);
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 170px;
      cursor: pointer;
      p {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 12px !important;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
      }
      img {
        position: absolute;
        width: 10px;
        height: 6px;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
      }
    }
    &-list-wrapper {
      position: relative;
      ::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #33adff;
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      width: 200px;
      overflow: auto;
      background-color: #33adff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      box-sizing: border-box;
      position: absolute;
      top: calc(100% + 10px);
      left: -30px;
      z-index: 10;
      a {
        text-decoration: none;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .dropdown-item {
        display: flex;
        width:100%;
        height: 40px;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        :hover {
          background-color: rgba(255, 255, 255, 0.25);
        }
        .contentItem {
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
        }
      }
      .active {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
  }
`;

const DropDownMenuControl = props => {
  const { data, indexSelected, changeItemDropdown } = props;
  
  const dispatch = useDispatch();
  //local
  let isMouseEnterMenuDropdown = false;
  //state
  const [isShow, setIsShow] = useState(false);
  const [indexChoice, setIndexChoice ] = useState(indexSelected)

  const { loginReducer } = useSelector((state) => ({
    loginReducer: state.loginReducer
  }))
  
  useEffect(() => {
    // console.log(data, indexChoice)
  }, [])

  useEffect(() => {
    if (isShow) {
      document.body.addEventListener("click", _handelClick);
    }
    return () => {
      document.body.removeEventListener("click", _handelClick);
    };
  }, [isShow]);

  useEffect(() => {
    if(indexChoice !== indexSelected){
      // if(indexSelected > data.length - 1){
      //   dispatch(switchRouter())
      // }
      // else{
        setIndexChoice(indexSelected)
      // }
    }
  }, [indexSelected])

  useEffect(() => {
    return () => document.body.removeEventListener("click", _handelClick);
  }, []);

  const _handelClick = () => {
    if (!isMouseEnterMenuDropdown) {
      if (isShow) {
        setTimeout(() => {
          setIsShow(false);
        }, 100);
      }
    }
  };
  const _changeItemDropdown = path => {
    changeItemDropdown(path)
    setIsShow(false);
  };

  const _handleRemote = (path) =>{
    dispatch(setFlagPrepareReload({visitorAddNew: {
      from: "drop-down-item",
      targetRemote: path
    }}))
    setIsShow(false);
  }

  const dropDownNavLink = () => {
    return data.map((item, i) => {
      return (
        <NavLink 
          to={item.path} 
          key={i} 
          className="dropdown-item" 
          activeClassName="active"
        >
          {/* <div
            className={
              indexChoice === i
                ? "dropdown-item active"
                : "dropdown-item"
            }
            onClick={() => _changeItemDropdown(item.path)}
          > */}
            <p className="contentItem">{item && (item.name || item)}</p>
          {/* </div> */}
        </NavLink>
      );
    });
  }

  const _renderDropdown = () => {
    return dropDownNavLink();
    // if(loginReducer.checkLogin && loginReducer.checkLogin.Role !== RECEPTIONIST){
    //   return dropDownNavLink();
    // }
    // const currentLocation = window.location.pathname;
    // switch(currentLocation){
    //   case "/visitor/new":
    //     return data.map((item, i) => {
    //       return (
    //           <div
    //             key={i}
    //             className={
    //               indexChoice === i
    //                 ? "dropdown-item active"
    //                 : "dropdown-item"
    //             }
    //             onClick={() => _handleRemote(item.path)}
    //           >
    //             <p className="contentItem">{item && (item.name || item)}</p>
    //           </div>
    //       );
    //     });
    //     // break;
    //   default:
    //     return dropDownNavLink();
    //     // break;
    // }
    // history.push(navigateIcon)
  };

  return (
    <Bound>
      <div className="dropdown">
        <div className="dropdown-choice" onClick={()=>{setIsShow(!isShow)}}>
          <p>{data[indexChoice].name}</p>
          <img src={ic_dropdown} alt="dropdown"></img>
        </div>
        {
          isShow&&
          <div className="dropdown-list-wrapper">
            <div className='dropdown-list'>
              {_renderDropdown()}
            </div>
          </div>
        }
        
      </div>
    </Bound>
  );
};
export default React.memo(DropDownMenuControl);
