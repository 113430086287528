import * as keys from '../constant/key'
import * as permissions from '../constant/permission'

// export const dropDownMenuData = [
  // {
  //   id: 1,
  //   indexDropdown: 1,
  //   keyAccess: "ACM",
  //   Name: "Access Control",
  //   path: "/access-control/activities"
  // },
  // {
  //   id: 2,
  //   indexDropdown: 2,
  //   keyAccess: "Employee",
  //   Name: "Employee Attendance",
  //   path: "/employee-attendance/attendance"
  // },
  // {
  //   id: 3,
  //   indexDropdown: 3,
  //   keyAccess: "Device",
  //   Name: "Device Setting",
  //   path: "/device-setting/devices"
  // },
  // {
  //   id: 4,
  //   keyAccess: "Visitor",
  //   Name: "Visitors",
  //   path: "/visitor/list"
  // },
// ];

// Chia website ra thành:
// - SECTION Visitors:
//   + Visitors

// - SECTION DEVICE SETTINGS:
//   + Devices
//   + Locations

// - SECTION ACM:
//   + Activities

// - SECTION EMPLOYEE ATTENDANCE:
//   + Employees
//   + Employee Attendance"

//Ver 2.1.0
export const dropDownMenuData = [
  {
    id: 1,
    keyAccess: "device-setting",
    name: "Device Setting",
    path: '/device-setting/location',
    sub: [
      {
        name: "Locations",
        keyAccess: 'location',
        path: "/device-setting/location",
        roles: [keys.IT_ADMIN]
      },
      {
        name: "Devices",
        keyAccess: 'device',
        path: "/device-setting/device",
        roles: [keys.IT_ADMIN]
      }
    ],
  },
  {
    id: 3,
    keyAccess: 'visitor',
    name: "Visitors",
    path: "/visitor/list",
    roles: [keys.IT_ADMIN, keys.RECEPTIONIST]
  },
  {
    id: 4,
    keyAccess: "employee",
    name: "Employee Attendance",
    path: '/employee/list',
    sub: [
      {
        name: "Employee",
        keyAccess: 'employee',
        path: "/employee/list",
        roles: [keys.IT_ADMIN, keys.HUMAN_RESOURCE]
      },
      {
        name: "Employee Attendance",
        keyAccess: 'attendance',
        path: "/employee/attendance",
        roles: [keys.HUMAN_RESOURCE]
      }
    ],
  },
  {
    id: 2,
    keyAccess: 'acm',
    name: "Access Control",
    path: "/acm/activities",
    roles: [keys.IT_ADMIN, keys.RECEPTIONIST],
    sub: [
      {
        name: "Activities",
        keyAccess: 'acm',
        path: "/acm/activities",
        roles: [keys.IT_ADMIN, keys.RECEPTIONIST]
      }
    ],
  }
];

export const dropDownMenuFullData = [
  // {
  //   id: 1,
  //   keyAccess: "ACM",
  //   Name: "Access Control",
  //   path: "/access-control/activities"
  // },
  // {
  //   id: 2,
  //   keyAccess: "Employee",
  //   Name: "Employee Attendance",
  //   path: "/employee-attendance/profile"
  // },
  {
    id: 3,
    keyAccess: "Device",
    Name: "Device Setting",
    path: "/device-setting/location"
  },
  {
    id: 4,
    keyAccess: "Visitor",
    Name: "Visitors",
    path: "/visitor"
  },
];

export const dropDownAddCompanyData = [
  "ADB Company",
  "ABBank Company",
  "Vietcombank Company",
  "Teckcombank Company",
];

export const dropDownAddGenderData = [
  // {
  //   id: 1,
  //   Name: "Male",
  // },
  // {
  //   id: 2,
  //   Name: "Female",
  // }
  "Male",
  "Female"
]

export const dropDownStatusFilter = [
  // {
  //   id: 1,
  //   Name: "All",
  // },
  // {
  //   id: 2,
  //   Name: "Active",
  // },
  // {
  //   id: 3,
  //   Name: "Expired",
  // },
  // {
  //   id: 4,
  //   Name: "Disable",
  // },
  "All",
  "Active",
  "Expired",
  "Disable"
]

export const dropDownResultFilter = [
  "All",
  "Access Granted",
  "Rejected"
]

export const dropDownWarningFilter = [
  "All",
  "None",
  "Permission denied",
  "Stranger"
]
