import moment from "moment";
import { dropDownMenuData } from '../data/dropDownData'
import * as keys from '../constant/key'
import * as popupKeys from '../data/popupData'

export const convertDateTime = (time, format) => {
  return moment(time).format(format);
};
export const immutabilityHelper=(object)=>{
  return JSON.parse(JSON.stringify(object))
}
export const buildValueScope = value => {
  if (value < 500000) {
    return "< 0.5 triệu";
  } else if (value >= 500000 && value <= 1000000) {
    return "0.5 triệu ~ 1 triệu";
  } else if (value > 1000000) {
    return "> 1 triệu";
  }
};

export const buildMoney = value => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const deg_to_dms = (deg, isLongitude) => {
  var de = deg < 0 ? (isLongitude ? "W" : "S") : isLongitude ? "E" : "N";

  var d = Math.floor(deg);
  var minfloat = (deg - d) * 60;
  var m = Math.floor(minfloat);
  var secfloat = (minfloat - m) * 60;
  var s = Math.round(secfloat);
  if (s === 60) {
    m++;
    s = 0;
  }
  if (m === 60) {
    d++;
    m = 0;
  }
  return "" + d + "°" + m + "'" + s + '"' + de;
};

// Random number from 0 to length
export const randomNumber = length => {
  return Math.floor(Math.random() * length);
};

// Generate Pseudo Random String, if safety is important use dedicated crypto/math library for less possible collisions!

export const generateID = length => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let text = "";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(randomNumber(possible.length));
  }

  return text;
};

// ===================== Format image base64 to request sever ===================== //
export const formatBase64 = imageBase64 => {
  let newBase = "";
  newBase = imageBase64.split(",")[1];
  return newBase;
};

export const genArrayLabelChart = len => {
  return new Array(len).fill(1).map((item, i) => item + i);
};

export const genArrayLabelChartQuater = len => {
  return new Array(len).fill(1).map((item, i) => {
    return `Quý ${i + 1}`;
  });
};

export const convertNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const converNumberWithDots = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/// dateTime: moment
export const month_name = dateTime => {
  const list = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return list[dateTime.getMonth()];
};

///======================

/**
 * @param {string*} date ex: "2019-11-08"
 * @param {string*} type ex: YY || YYYY || MM || DD
 */
export const getTime = (date, type) => {
  switch (type) {
    case "YYYY": {
      let dateTime = moment(date);
      return dateTime.format("YYYY");
    }
    case "MM": {
      let dateTime = moment(date);
      return dateTime.format("MM");
    }
    case "DD": {
      let dateTime = moment(date);
      return dateTime.format("DD");
    }
    case "YY": {
      let dateTime = moment(date);
      return dateTime.format("YY");
    }
    case "D/MM/YYYY": {
      let dateTime = moment(date);
      return dateTime.format("D/MM/YYYY");
    }
    default:
      break;
  }
};

/**
 * // moment(new Date(2019,11,9)).toDate()
 * // Sun Nov 03 2019 00:00:00 GMT+0700 (Giờ Đông Dương)
 * @param {date*} startDate
 * @param {date*} endDate
 * @param {number} type
 * ex: 0 ---> get data fullDate, "Thứ 4, 11/11/2019"
 * 1: get data D/MM/YY,
 * 2: get data DD/MM/YYYY
 * 3: get all days in month -->  fullDate
 * 4: get all days in month --> DD/MM/YY
 * 5: get all days in month --> DD/MM/YYYY
 */

 

export const getDaysArray = (startDate, endDate, type) => {
  const currentDate = startDate.toDate();
  const endDateTime = endDate.toDate();
  const namesDay = [
    "Chủ nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7"
  ];
  const arrayDataDays = [];

  ////////// Get days follow with startDate ---> endDate
  if (type === 0) {
    while (currentDate <= endDateTime) {
      arrayDataDays.push(
        namesDay[currentDate.getDay()] +
          ", " +
          currentDate.getDate() +
          "/" +
          (currentDate.getMonth() + 1) +
          "/" +
          currentDate.getFullYear()
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return arrayDataDays;
  }
  if (type === 1) {
    while (currentDate <= endDateTime) {
      arrayDataDays.push(
        currentDate.getDate() +
          "/" +
          (currentDate.getMonth() + 1) +
          "/" +
          currentDate
            .getFullYear()
            .toString()
            .slice(2)
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return arrayDataDays;
  }
  if (type === 2) {
    while (currentDate <= endDateTime) {
      arrayDataDays.push(
        currentDate.getDate() +
          "/" +
          (currentDate.getMonth() + 1) +
          "/" +
          currentDate.getFullYear()
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return arrayDataDays;
  }

  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  ///////// Get all days of month
  const getYearOfCurrentDate = currentDate.getFullYear();
  const getMonthOfCurrentDate = currentDate.getMonth();
  const date = new Date(getYearOfCurrentDate, getMonthOfCurrentDate, 1);
  if (type === 3) {
    while (date.getMonth() === getMonthOfCurrentDate) {
      arrayDataDays.push(
        namesDay[date.getDay()] +
          ", " +
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear()
      );
      date.setDate(date.getDate() + 1);
    }
    return arrayDataDays;
  }
  if (type === 4) {
    while (date.getMonth() === getMonthOfCurrentDate) {
      arrayDataDays.push(
        date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date
            .getFullYear()
            .toString()
            .slice(2)
      );
      date.setDate(date.getDate() + 1);
    }
    return arrayDataDays;
  }
  if (type === 5) {
    while (date.getMonth() === getMonthOfCurrentDate) {
      arrayDataDays.push(
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
      date.setDate(date.getDate() + 1);
    }
    return arrayDataDays;
  }
};

/**
 * // moment(time).format("YYYY-MM-DD")
 * // 2019-11-01
 * @param {string*} startDate
 * @param {string*} endDate
 * ex: 0 ---> get data Month: MM/YY, 1: get data Tháng 7, 2019,
 */
export const getDataMonthArray = (startDate, endDate, type) => {
  const dataStart = moment(startDate).format("YYYY-MM-DD");
  const dataEnd = moment(endDate).format("YYYY-MM-DD");
  const start = dataStart.split("-");
  const end = dataEnd.split("-");
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  if (type === 0) {
    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? "0" + month : month;
        dates.push([displayMonth, i.toString().slice(2)].join("/"));
      }
    }
    return dates;
  }
  if (type === 1) {
    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? "0" + month : month;
        dates.push(["Tháng ", displayMonth, "/", i].join(""));
      }
    }
    return dates;
  }
  if (type === 2) {
    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth].join("-"));
      }
    }
    return dates;
  }
  /////////////////////////////////////////////////
  /////////////////////////////////////////////////
  /////////////////////////////////////////////////

  const startDateGetFullMonthInYear = startDate.toDate();
  const getYearOfCurrentDate = startDateGetFullMonthInYear.getFullYear();
  const getMonthOfCurrentDate = startDateGetFullMonthInYear.getMonth();
  const date = new Date(getYearOfCurrentDate, getMonthOfCurrentDate, 1);

  if (type === 3) {
    const dates = [];
    for (let i = 1; i < 13; i++) {
      dates.push(
        (i > 9 && i > 0 ? i : "0" + i) +
          "/" +
          date
            .getFullYear()
            .toString()
            .slice(2)
      );
    }
    return dates;
  }
  if (type === 4) {
    const dates = [];
    for (let i = 1; i < 13; i++) {
      dates.push("Tháng " + i + "/" + date.getFullYear());
    }
    return dates;
  }
  if (type === 5) {
    const dates = [];
    for (let i = 1; i < 13; i++) {
      dates.push(date.getFullYear() + "-" + i);
    }
    return dates;
  }
};

/**
 * // moment(new Date(2019,11,9)).toDate()
 * // Sun Nov 03 2019 00:00:00 GMT+0700 (Giờ Đông Dương)
 * @param {string*} startDate
 * @param {string*} endDate
 * ex 0: get data fullQuater: "Quý 1/2018"
 * ex 1: get year to check ,
 */
export const getDataQuater = (startDate, endDate, type) => {
  let dateStart = startDate.toDate();
  let yearStart = dateStart.getFullYear();
  let monthStart = dateStart.getMonth();

  let dateEnd = endDate.toDate();
  let yearEnd = dateEnd.getFullYear();
  // let monthEnd = dateEnd.getMonth();

  const quarters = [
    { quarter: "Quý 1", numQuarter: 1 },
    { quarter: "Quý 2", numQuarter: 2 },
    { quarter: "Quý 3", numQuarter: 3 },
    { quarter: "Quý 4", numQuarter: 4 }
  ];
  const dataQuarters = [];
  const dataNumQuarters = [];

  //////// Get all Quarter in one year
  for (let year = yearStart; year <= yearEnd; year++) {
    quarters.forEach(item => dataQuarters.push(item.quarter + "/" + year));
    quarters.forEach(item =>
      dataNumQuarters.push({ numQuater: item.numQuarter, year: year })
    );
  }
  quarters.slice(0, parseInt(monthStart / 3 + 1));
  if (type === 0) {
    return dataQuarters;
  }
  if (type === 1) {
    return dataNumQuarters;
  }

  ////////////// Get quarter from startDate to endDate

  if (type === 2 || type === 3) {
    function getQuarter(date) {
      // const arrayYearToCheck = [];
      if (type === 2) {
        return (
          "Quý " +
          Math.ceil((date.getMonth() + 1) / 3) +
          "/" +
          date.getFullYear()
        );
      }
      if (type === 3) {
        // arrayYearToCheck.push({quarter: parseInt(Math.ceil((date.getMonth() + 1) / 3)),numQuarter:date.getFullYear()})
        return (
          // arrayYearToCheck
          // parseInt(Math.ceil((date.getMonth() + 1) / 3)) ,date.getFullYear()
          parseInt(Math.ceil((date.getMonth() + 1) / 3))
        );
      }
    }

    // Ensure start is the earlier date;
    if (dateStart > dateEnd) {
      var t = dateEnd;
      dateEnd = dateStart;
      dateStart = t;
    }

    // Copy input start date do don't affect original
    dateStart = new Date(dateStart);

    // Set to 2nd of month so adding months doesn't roll over
    // and not affected by daylight saving
    dateStart.setDate(2);

    // Initialise result array with start quarter
    var startQ = getQuarter(dateStart);
    var endQ = getQuarter(dateEnd);
    var result = [startQ];

    // List quarters from start to end
    while (startQ !== endQ) {
      dateStart.setMonth(dateStart.getMonth() + 3);
      startQ = getQuarter(dateStart);
      // getQuarterCheck(dateStart);
      result.push(startQ);
    }

    return result;
  }
};
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
export const checkMail = email => {
  var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!filter.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const isEmptyObject = v => {
  return !!v && v.constructor === Object && Object.keys(v).length === 0;
};

export const getNavigationData = () => {
  const dataLogin = JSON.parse(localStorage.getItem(keys.WEE_IO_LOGIN_DATA))
  if(!dataLogin)
    return
  let userRole = dataLogin.Role
  let dropdownData = []
  if(userRole && userRole.length > 0){
    for(let i = 0; i < dropDownMenuData.length; i++){
      if(!dropDownMenuData[i])
        continue;
      if(dropDownMenuData[i].roles && dropDownMenuData[i].roles.length > 0){
        if(dropDownMenuData[i].roles.find(role => role === userRole)){
          dropdownData.push(dropDownMenuData[i])
        }
      }
      else if(dropDownMenuData[i].sub && dropDownMenuData[i].sub.length > 0){
        for(let j = 0; j < dropDownMenuData[i].sub.length; j++){
          if(!dropDownMenuData[i].sub[j])
            continue;
          if(dropDownMenuData[i].sub[j].roles && dropDownMenuData[i].sub[j].roles.length > 0){
            if(dropDownMenuData[i].sub[j].roles.find(role => role === userRole)){
              dropdownData.push(dropDownMenuData[i])
              break;
            }
          }
        }
      }
    }
  }
  return dropdownData
}

/**
 * get new x, y postion of output
 * @param {{
 *  posX: number,
 *  posY: number,
 *  widthRecog: number,
 *  heightRecog: number
 * }}  
 * @param {number} widthOutput
 * @param {number} heightOutput 
 * @param {number} widthInput 
 * @param {number} heightInput 
 */
export const refixCaptureDimension = ({posX, posY, widthRecog, heightRecog}, widthOutput, heightOutput, widthInput, heightInput) => {

  const dimensionWidth = Math.abs(widthOutput - widthRecog)/2;

  let newX = posX;
  if(widthOutput - widthRecog > 0){
    newX -= dimensionWidth;
  }
  else{
    newX += dimensionWidth;
  }

  const dimensionHeight = Math.abs(heightOutput - heightRecog)/2;

  let newY = posY;
  if(heightOutput - heightRecog > 0){
    newY -= dimensionHeight;
  }
  else{
    newY += dimensionHeight;
  }

  if(newX < 0){
    newX = 0;
  }

  if(newX + widthOutput > widthInput){
    const tmp = newX + widthOutput - widthInput;
    newX -= tmp;
  }

  if(newY < 0){
    newY = 0;
  }

  if(newY + heightOutput > heightInput){
    const tmp = newY + heightOutput - heightInput;
    newY -= tmp;
  }
  return ({newX, newY})
}

const _handleReturnSize = (
  newSizeName, newSizeVal, 
  newPosName, newPosVal,
  posValOld, sizeValOld,
  posKeepValName, posKeepVal,
  sizeKeepValName, sizeKeepVal,
  sizeInput
) => {
  let sizeVal = newSizeVal;
  let posVal = newPosVal;

  if(sizeVal > sizeInput){
    sizeVal = sizeInput;
    posVal = 0;
    return ({
      [posKeepValName]: posKeepVal,
      [newPosName]: posVal,
      [newSizeName]: sizeVal,
      [sizeKeepValName]: sizeKeepVal
    })
  }

  const tmpVal = sizeVal - sizeValOld;
  posVal = posValOld - tmpVal/2;
  if(posVal < 0) {
    posVal = 0;
    return ({
      [posKeepValName]: posKeepVal,
      [newPosName]: posVal,
      [newSizeName]: sizeVal,
      [sizeKeepValName]: sizeKeepVal
    })
  }

  return ({
    [posKeepValName]: posKeepVal,
    [newPosName]: posVal,
    [newSizeName]: sizeVal,
    [sizeKeepValName]: sizeKeepVal
  })
}

export const refixCaptureDimensionV2 = (
  {
    posX, 
    posY,
    widthRecog, 
    heightRecog
  }, 
  widthOutput, 
  heightOutput, 
  widthInput, 
  heightInput
) => {
  let newPosX, newPosY;
  const ratioOutput = widthOutput/heightOutput;

  let tmpWidth = Math.ceil(widthRecog);
  // while(tmpWidth%2 !== 0){
  //   tmpWidth++
  // }
  // const tmpVal = tmpWidth - widthRecog;
  // newPosX = posX - tmpVal/2;
  if(tmpWidth%2 !== 0){
    tmpWidth++;
  }

  // fix height of imgRecog follow width:
  let newHeight = tmpWidth/ratioOutput;
  if(newHeight < heightRecog){
    // debugger;

    // fix width of imgRecog follow height:
    let newWidth = heightRecog*ratioOutput;
    if(newWidth < widthRecog){
      debugger;
    }
    
    if(newWidth > widthInput){
      newWidth = widthInput;
      newPosX = 0;
      return ({
        newX: newPosX,
        newY: posY,
        newHeight: heightRecog,
        newWidth
      })
    }

    const tmpWidth = newWidth - widthRecog;
    newPosX = posX - tmpWidth/2;
    if(newPosX < 0) {
      newPosX = 0;
      return ({
        newX: newPosX,
        newY: posY,
        newHeight: heightRecog,
        newWidth
      })
    }

    return ({
      newX: newPosX,
      newY: posY,
      newHeight: heightRecog,
      newWidth
    })

    // return _handleReturnSize(
    //   "newWidth", newWidth, 
    //   "newX", newPosX, 
    //   posX, widthRecog,
    //   "newY", posY, 
    //   "newHeight", heightRecog,
    //   widthInput
    // )

  }

  // return _handleReturnSize(
  //   "newHeight", newHeight, 
  //   "newY", newPosY, 
  //   posY, heightRecog,
  //   "newX", posX, 
  //   "newWidth", widthRecog,
  //   heightInput
  // )
  // debugger
  if(newHeight > heightInput){
    newHeight = heightInput;
    newPosY = 0;
    return ({
      newX: posX - 0.5,
      newY: newPosY,
      newHeight,
      newWidth: tmpWidth
    })
  }
  // debugger
  const tmpHeigth = newHeight - heightRecog;
  newPosY = posY - tmpHeigth/2;
  if(newPosY < 0) {
    newPosY = 0;
    return ({
      newX: posX - 0.5,
      newY: newPosY,
      newHeight,
      newWidth: tmpWidth
    })
  }
  // debugger
  return ({
    newX: posX - 0.5,
    newY: newPosY,
    newHeight,
    newWidth: tmpWidth
  })

}

export const getNewSize = (
  {
    posXFace,
    posYFace,
    widthFace,
    heightFace
  },
  widthExpect,
  heightExpect,
  widthInput,
  heightInput
) => {
  // debugger;
  let widthOutput;
  let heightOutput;

  heightOutput = 2*heightFace + heightFace + heightFace/2;

  if(heightOutput > heightInput){
    heightOutput = heightInput;
    widthOutput = widthExpect*heightOutput/heightExpect;

    if(widthOutput < widthFace){
      heightOutput = 2*widthFace;
      widthOutput = 4*widthFace/3;
      
      if(widthOutput > widthInput){
        // debugger;
        return {
          newX: 0,
          newY: 0, 
          widthCrop: widthInput,
          heightCrop: heightInput,
          widthWhiteFill: widthOutput - widthInput,
          heightWhiteFill: heightOutput - heightInput
        }
      }
      // debugger;
      // widthOutput <= widthInput
      return {
        newX: posXFace - (widthOutput - widthFace)/2,
        newY: 0, 
        widthCrop: widthOutput,
        heightCrop: heightInput,
        widthWhiteFill: 0,
        heightWhiteFill: heightOutput - heightInput
      }
    }

    if(widthOutput > widthInput){
      // debugger;
      return {
        newX: 0,
        newY: 0,
        widthCrop: widthInput,
        heightCrop: heightInput,
        widthWhiteFill: widthOutput - widthInput,
        heightWhiteFill: 0
      }
    }

    // widthOutput <= widthInput
    // debugger;
    return {
      newX: posXFace - (widthOutput - widthFace)/2,
      newY: 0,
      widthCrop: widthOutput,
      heightCrop: heightInput,
      widthWhiteFill: 0,
      heightWhiteFill: 0
    }
  }

  // heightOutput <= heightInput
  widthOutput = widthExpect*heightOutput/heightExpect;

  if(widthOutput < widthFace){
    heightOutput = 2*widthFace;
    widthOutput = 4*widthFace/3;
    
    if(widthOutput > widthInput){
      // debugger;
      return {
        newX: 0,
        newY: 0, 
        widthCrop: widthInput,
        heightCrop: heightInput,
        widthWhiteFill: widthOutput - widthInput,
        heightWhiteFill: heightOutput - heightInput
      }
    }
    // debugger;
    // widthOutput <= widthInput
    return {
      newX: posXFace - (widthOutput - widthFace)/2,
      newY: 0, 
      widthCrop: widthOutput,
      heightCrop: heightInput,
      widthWhiteFill: 0,
      heightWhiteFill: heightOutput - heightInput
    }
  }

  if(widthOutput > widthInput){
    // debugger;
    return {
      newX: 0,
      newY: posYFace - (heightOutput - heightFace)/2,
      widthCrop: widthInput,
      heightCrop: heightOutput,
      widthWhiteFill: widthOutput - widthInput,
      heightWhiteFill: 0
    }
  }
  // debugger;
  // widthOutput <= widthInput
  return {
    newX: posXFace - (widthOutput - widthFace)/2,
    newY: posYFace - (heightOutput - heightFace)/2,
    widthCrop: widthOutput,
    heightCrop: heightOutput,
    widthWhiteFill: 0,
    heightWhiteFill: 0
  }
}


export const formatArrToObj = (arr, parentInfo, keyContaintParentInfo, keyToContainChildren, uniqueKeyChild) => {
  let out = []
  for(let i in arr) {
      if(arr[i][keyContaintParentInfo] == parentInfo) {
          const tmpArr = arr.filter(item => item[keyContaintParentInfo] !== parentInfo)
          let children = formatArrToObj(tmpArr, arr[i][uniqueKeyChild], keyContaintParentInfo, keyToContainChildren, uniqueKeyChild)

          // if(children.length) {
              arr[i][keyToContainChildren] = children
          // }
          out.push(arr[i])
      }
  }
  return out
}

/**
 * 
 * @param {[Object]} lstNeedGetDetail 
 * @param {[Object]} treeListContain 
 * @param {string} key key to compare between object item of twice list lstNeedGetDetail - treeListContain
 * @param {string} subTreeListContainKey key of sub tree(maybe exist inside treeListContain item)
 */
export const getDetailsListByKey = (lstNeedGetDetail, treeListContain, key, subTreeListContainKey) => {
  let lstOutput = [];

  treeListContain.forEach(item => {
    if(lstNeedGetDetail.some(data => data[key] === item[key]))
      lstOutput = [...lstOutput, item];
    
    if(subTreeListContainKey && item[subTreeListContainKey].length){
      const lstOutputChildren = getDetailsListByKey(lstNeedGetDetail, item[subTreeListContainKey], key, subTreeListContainKey)
      lstOutput = [...lstOutput, ...lstOutputChildren];
    }
      
  })
  return lstOutput
}

/**
 * 
 * @param {[object]} oldList list item initial
 * @param {[object]} newList list item after change
 * @param {string} keyItemTocheck key 
 */
export const getDetailListAfterChange = (oldList, newList, keyItemTocheck) => {

  const lstNewItem = newList.filter(item => 
    oldList.every(data => data[keyItemTocheck] !== item[keyItemTocheck])
  )

  const lstItemWasRemoved = oldList.filter(item => 
    newList.every(data => data[keyItemTocheck] !== item[keyItemTocheck])
  )

  return ({
    lstNewItem,
    lstItemWasRemoved
  })
}

export const checkErrCode = (checkType, errCode) => {
  switch (checkType) {
    case keys.VISITOR:{
      switch (errCode) {
        case 11:
          // permitted location from request is invalid
          return ({
            popupType: popupKeys.PERMITTED_LOCATION_INVALID,
          })
        case 13:
          return ({
            popupType: popupKeys.EXPIRATION_DATE_INVALID,
          })
        case 16:
          return ({
            popupType: popupKeys.SOCIAL_ID_EXISTED,
          })
        case 119:
          return ({
            popupType: popupKeys.FACE_HAS_ENROLLED,
          })
        default:
          break;
      }
    }
      break;
    case keys.EMPLOYEE: {
      switch (errCode) {
        case 17:
          return ({
            popupType: popupKeys.ADD_EMPLOYEE_FAIL_EMPLOYEE_ID,
          })
        case 119:
          return ({
            popupType: popupKeys.FACE_HAS_ENROLLED,
          })
        case 16:
          return ({
            popupType: popupKeys.SOCIAL_ID_EXISTED,
          })
        default:
          break;
      }
      break;
    }
    default:
      break;
  }
}


export const sortLstStringByUnicode = (lstString) => {
  try {
    return [...lstString].sort((a,b) => {
      let index = 0;
      while(index < Math.max(a.length, b.length)) {
        if(a[index] !== b[index])
          return (a[index] - b[index]) || a[index].localeCompare(b[index])
        index++;
      }
    
      return 	a - b || a.localeCompare(b)
    })
  }catch(err){
    console.log("Error sort list", err)
  }
}
// check font vietnamese
export const RemoveAccents = strAccents => {
  let strAccentsCheck = strAccents.split("");
  let strAccentsOut = [];
  let strAccentsLen = strAccentsCheck.length;
  let accents =
    "ẢẠÀÁÂÃÄÅĂẤẨẪẬĂẮẰẲẴẶàáảạâãäåỒỔỖỐÒÓỎỌÔÕÕÖØỏọơớờởỡợốồổỗộậăấầẩẫắằẳẵặỪỬỮỰƯưứừửữựòóôõöøỢỞỠƠỜỚỎÕỌểềếễệỄỀẾỂỆÈÉÊËẺẼẸèéẻẽẹêëðÇçĐđÌÍÎÏỈĨỊìíîïỉĩịũủụưứừửữựỨỤƯỦŨÙÚÛÜùúûüÑñŠšýỳỷỹỵŸÿýÝỶỸỴỲŽž";

  let accentsOut =
    "AAAAAAAAAAAAAAAAAAAaaaaaaaaOOOOOOOOOOOOOoooooooooooooaaaaaaaaaaaUUUUUuuuuuuooooooOOOOOOOOOeeeeeEEEEEEEEEEEEeeeeeeeeCcDdIIIIIIIiiiiiiiuuuuuuuuuUUUUUUUUUuuuuNnSsyyyyyYyyYYYYYZz";
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join("");
  return strAccentsOut;
};