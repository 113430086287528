import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
/// data

/// components
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
// removeVisitor
//action
import { removeLocation } from "../../../redux/actions/locationAction/locationAction";

const Bound = styled.div`
  padding: 32px 0px;
  width: 409px;
  height: 316px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  background: #fafafa;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  /* animate */
  animation-name: isShowPopup;
  transition-property: bottom;
  transition-duration: 500ms;
  display: relative;
  @keyframes isShowPopup {
    from {
      top: -100px;
    }
    to {
      top: 45px;
    }
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  /* margin-top: 162px; */
  text-align: center;
  .ic-warning-remove {
  }
  .confirm-remove-title {
    margin-top: 12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #262626;
    .block-device-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        :nth-child(1) {
          display: inline-block;
          font-weight: bold;
        }
        :nth-child(2) {
          max-width: 200px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
          color: #33adff;
          margin: 0 5px;
        }
      }
    }
  }
  .confirm-remove-content {
    padding: 0 35px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000924;
  }
  .block-btn {
    margin-top: 11px;
    display: flex;
    justify-content: center;
    .remove-btn,
    .cancel-btn {
      width: 160px;
      height: 50px;

      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.03em;

      cursor: pointer;
    }
    .remove-btn {
      background: #ea3939;
      color: #ffffff;
      margin-right: 12px;
    }
    .cancel-btn {
      color: #33adff;
      background: #ffffff;
      border: 0.572641px solid #33adff;
    }
  }
`;

function PopupRemoveLocation(props) {
  let history = useHistory();
  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));

  const { data, TypePopup } = useSelector(state => ({
    TypePopup: state.popupContainerReducer.typePopup,
    data: state.popupContainerReducer.data
  }));
  //======================REF====================================
  //======================Variable================================

  //======================Function=================================

  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  const _onConfirmRemove = () => {
    dispatch(
      removeLocation(loginReducer.checkLogin.CompanyID, data.locationID, data.parentLocationID)
    );
    dispatch(closePopup());
  };
  //======================Lifecycle Hook===========================
  const _handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    // console.log(key, keyCode)
    //  Escape 27
    //  Enter 13
    switch(keyCode){
      case 27:
        _onClosePopup()
        break;
      case 13:
        _onConfirmRemove()
        break;
      default:
        break;
    }

  }, []);

  useEffect(() => {
    window.addEventListener("keydown", _handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", _handleUserKeyPress)
    }
  }, [_handleUserKeyPress])

  //=====================Render Main Component====================
  return (
    <Bound>
      {/* <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      /> */}
      <Content>
        <img
          className="ic-warning-remove"
          src={ic_warning_remove}
          alt="ic_warning"
        />
        <div className="confirm-remove-title">
          <span>Are you sure you want to remove</span>
          <div className="block-device-remove">
            <span>location</span>
            <span>{data.Name}</span>
            <span style={{color: "#33adff"}}>?</span>
          </div>
        </div>
        <p className="confirm-remove-content">
          This location will be deleted immediatly and unlink all devices and
          all child locations at this location. You can't undo this action.{" "}
        </p>
        <div className="block-btn">
          <div className="remove-btn" onClick={_onConfirmRemove}>
            REMOVE
          </div>
          <div className="cancel-btn" onClick={_onClosePopup}>
            CANCEL
          </div>
        </div>
      </Content>
    </Bound>
  );
}

PopupRemoveLocation.propTypes = {
  data: PropTypes.object
};

export default PopupRemoveLocation;
