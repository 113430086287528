import { css } from 'styled-components';

const buttonStyles = css`
  display: flex;
  justify-content:center;
   align-items:center;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  height:50px;
  .button__img{
    display:inline-block;
    margin-right:8px;
}
.button__text{
    display:inline-block;
}
`;

export default buttonStyles;
