import * as types from "../types";
// import { menuFilterData } from "../../../data/menuData";
import { dropDownMenuData } from "../../../data/dropDownData";
import { getNavigationData } from '../../../helper/tools'

export const switchRouter = ( path, indexDropdownCurrent, orderPath ) => async dispatch => {
  let indexMainSelected = 0
  let indexSubSelected = 0
  console.log(path)
  const pathSplit = path.split('/')
  console.log(pathSplit)
  if(pathSplit.length<2)
    return
  if (path === "/change-password") {

  }
  else{
    const dropdownData = getNavigationData()
    console.log(dropdownData)
    for(let i = 0; i < dropdownData.length; i++){
      if(!dropdownData[i])
        continue
      if(dropdownData[i].keyAccess && dropdownData[i].keyAccess === pathSplit[1]){
        indexMainSelected = i
        if(dropdownData[i].sub && dropdownData[i].sub.length > 0 &&
          pathSplit.length>2){
          for(let j = 0; j < dropdownData[i].sub.length; j++){
            if(!dropdownData[i].sub[j])
              continue
            if(dropdownData[i].sub[j].keyAccess && dropdownData[i].sub[j].keyAccess === pathSplit[2]){
              indexMainSelected = i
              indexSubSelected = j
              break;
            }
          }
        }
        break;
      }
    }
  }
  console.log(indexMainSelected, indexSubSelected)
  dispatch({
    type: types.SWITCH_ROUTER,
    // indexActive: indexActive,
    // indexDropdown: indexDropdown
    indexMainSelected,
    indexSubSelected
  });
};

export const filterAccessRightsDropdownMenu = accessRights => async dispatch => {
  let newDataDropdown = [...dropDownMenuData];
  if (accessRights && accessRights.ROOT) {
    newDataDropdown = [...dropDownMenuData];
  } else if (accessRights) {
    if (!accessRights.ACM) {
      newDataDropdown = newDataDropdown.filter(element => {
        return element.keyAccess !== "ACM";
      });
    }

    if (!accessRights.Device) {
      newDataDropdown = newDataDropdown.filter(element => {
        return element.keyAccess !== "Device";
      });
    }

    if (!accessRights.Employee) {
      newDataDropdown = newDataDropdown.filter(element => {
        return element.keyAccess !== "Employee";
      });
    }
  }
  // console.log(newDataDropdown);

  let indexActive = 1;
  let indexDropdown = newDataDropdown.length > 0 && newDataDropdown[0].id;
  // console.log(indexDropdown);

  dispatch({
    type: types.FILLTER_ACCESS_RIGHT_DROPDOWN_MENU,
    indexActive: indexActive,
    indexDropdown: indexDropdown,
    orderPath: newDataDropdown[0],
    dropDownMenuData: newDataDropdown
  });
};
