import * as types from "../../actions/types";

export const initialState = {
  type:''
};

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case types.STORE_FILTER_DATA:
        return {
            ...state,
            type: action.type,
            [action.payload.filterType]:{
                ...action.payload.filterData
            }
        };
    case types.CLEAR_FILTER_DATA:
        return {
            ...state,
            type: action.type,
            [action.filterType]:{ }
        }
    default:
        return state;
  }
}
