import * as types from "../types";

export const openPopupNotification = (
  typePopup,
  data,
  activeOverlay = false
) => dispatch => {
  dispatch({
    type: types.CLOSE_POPUP_NOTIFICATION
  });
  dispatch({
    type: types.OPEN_POPUP_NOTIFICATION,
    typePopup,
    data,
    activeOverlay
  });
};

export const closePopupNotification = () => dispatch =>
  dispatch({
    type: types.CLOSE_POPUP_NOTIFICATION
  });

export const resetPopupNotification = dispatch =>
  dispatch({
    type: types.RESET_POPUP_NOTIFICATION
  });
