import * as types from "../../actions/types";

export const initialState = {
  type: "",
  data: {},
  error: ""
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SOCKET_SUCCESS:
      console.log(action.data)
      return {
        ...state,
        data:action.data,
        error:"",
        type: action.type
      };
    default:
      return state;
  }
}
