import * as types from "../../actions/types";

export const initialState = {
  error: "",
  data: null,
  dataError: null,
  isLoading: false,
  lstLocationFilter: [],
  lstLocationByID: [],
  tmpLocationByIDAll: [],
  tmpLocationFilterAll: []
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_NEW_LOCATION_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.ADD_NEW_LOCATION_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.ADD_NEW_LOCATION_FAIL:
      return {
        ...state,
        data: action.data,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.GET_AND_SEARCH_LOCATION_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.GET_AND_SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.GET_AND_SEARCH_LOCATION_FAIL:
      return {
        ...state,
        // data: null,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.GET_DETAIL_LOCATION_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.GET_DETAIL_LOCATION_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.GET_DETAIL_LOCATION_FAIL:
      return {
        ...state,
        data: null,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.REMOVE_LOCATION_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.REMOVE_LOCATION_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.REMOVE_LOCATION_FAIL:
      return {
        ...state,
        data: null,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.GET_LOCATION_BRANCH_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.GET_LOCATION_BRANCH_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.GET_LOCATION_BRANCH_FAIL:
      return {
        ...state,
        data: null,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    //================== UPDATE LOCATION DETAIL
    case types.UPDATE_LOCATION_DETAIL_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.UPDATE_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        type: action.type,
        isLoading: true
      };
    case types.UPDATE_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        // data: action.data,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.GET_FILTER_ALLOWED_LOCATION:
      return {
        ...state,
        lstLocationFilter: action.data,
        tmpLocationFilterAll: action.initialData,
        type: action.type,
        isLoading: true
      };
    case types.GET_FILTER_ALLOWED_LOCATION_ERROR:
      return {
        ...state,
        lstLocationFilter: null,
        err: action.err,
        type: action.type,
        isLoading: true
      };
    case types.GET_LOCATION_ARRAY_BY_ID_ARRAY:
      return {
        ...state,
        lstLocationByID: action.data,
        tmpLocationByIDAll: action.initialData,
        type: action.type,
        isLoading: true
      };
    case types.GET_LOCATION_ARRAY_BY_ID_ARRAY_ERROR:
      return {
        ...state,
        lstLocationByID: [],
        err: action.err,
        type: action.type,
        isLoading: true
      };
    default:
      return state;
  }
}


