import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';

//constant
import * as keys from '../../../../constant/key'
//image
import btn_close from '../../../../images/btn_close.svg'

// action
import { closePopup } from '../../../../redux/actions/popupAction/popupContainerAction';
import { addImgSrcRecognize as addImgSrcRecognizeVisitor, setStatusChangeVisitorData } from '../../../../redux/actions/visitorAction/visitorAction';
import { addImgSrcRecognize as addImgSrcRecognizeEmployee, setStatusChangeEmployeeData } from '../../../../redux/actions/employeeAction/employeeAction';

// component
import Button from "../../../Control/Button";

const ButtonConfirmStyle = styled(Button)`
    width: 215px;
    /* background: #33ADFF; */
`;

const ButtonRetryStyle = styled(Button)`
    width: 215px;
    /* background: #FFFFFF; */
    /* border: 0.6px solid #33ADFF; */
    /* color: #33ADFF; */
`;

const Bound = styled.div`
    width: 980px;
    height: 620px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FAFAFA;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    padding: 0 67px 0 85px;

    figure.capture {
        width: 334px;
        height: 479px;
        padding: 8px;
        border: 1px dashed #000000;
        border-radius: 5px;
        flex-shrink: 0;
        margin: 0 35px 0 0;

        img {
            display: block;
            margin: 0 auto;
            width: 320px;
            height: 480px;
        }
    }

    .title-text {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 32px;
    }

    .btn-wrap {
        display: flex;
        justify-content: space-between;

        & > *:not(:last-child) {
            margin-right: 13px;
        }
    }
    .btn-close {
        position: absolute;
        top: 32px;
        right: 32px;
        cursor: pointer;
    }
`;

const ConfirmCaptureImage = ({setStep, lstStep, imgCapture, userType, imgRecognize: imgRecognizeTest}) => {

    const dispatch = useDispatch();
    
    const { visitorReducer, employeeReducer } = useSelector((state) => ({
        visitorReducer: state.visitorReducer,
        employeeReducer: state.employeeReducer
    }))

    let imgRecognize = null

    switch (userType) {
        case keys.VISITOR:{
            imgRecognize = imgCapture?imgCapture:visitorReducer.dataItemExcuting.imgRecognizeSrc;
        }
            break;
        case keys.EMPLOYEE:{
            imgRecognize = imgCapture?imgCapture:employeeReducer.dataItemExcuting.imgRecognizeSrc;
        }
            break;
        default:
            break;
    }
    

    const _handleConfirmImgRecognize = () => {
        switch (userType) {
            case keys.VISITOR:{
                dispatch(addImgSrcRecognizeVisitor(imgCapture))
                dispatch(setStatusChangeVisitorData({imgRecognizeSrc: true}))
            }
                break;
            case keys.EMPLOYEE:{
                dispatch(addImgSrcRecognizeEmployee(imgCapture))
                dispatch(setStatusChangeEmployeeData({imgRecognizeSrc: true}))
            }
                break;
            default:
                break;
        }
        
        dispatch(closePopup())
    }

    return (
        <Bound>
            <figure className="capture">            
                <img src={imgRecognize} alt="capture"  />
            </figure>
            <div>
                {
                    process.env.NODE_ENV === 'development'?
                    <img src={imgRecognizeTest} alt="image-test" />
                    :
                    null
                }
                <div className="title-text">Is this picture a clear representation of the visitor?</div>
                <div className="btn-wrap">
                    <ButtonConfirmStyle  
                        type="PRIMARY"
                        text="CONFIRM"
                        onClick={_handleConfirmImgRecognize}
                    />
                    <ButtonRetryStyle  
                        type="LIGHT"
                        text="RETRY"
                        onClick={() => setStep?setStep(lstStep.CAPTURE):dispatch(closePopup())}
                    />
                </div>
            </div>
            <div className='btn-close'>
                <img src={btn_close} alt="icon-close" onClick={() => setStep?setStep(lstStep.CAPTURE):dispatch(closePopup())} />
            </div>
        </Bound>
    )
}

export default ConfirmCaptureImage
