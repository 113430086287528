import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';

import styled from "styled-components"

// component
import CaptureVideo from './CaptureVideo';
import ConfirmCaptureImage from './ConfirmCaptureImage';
import NoticePopupControl from './NoticePopupControl';
import DetailNoticeErrImgNoFace from './EleNoticePopupControl/DetailNoticeErrImgNoFace';
import DetailNoticeErrImgMoreFace from './EleNoticePopupControl/DetailNoticeErrImgMoreFace';
// import Button from "../../../Control/Button";

// images
import ic_close_bg_red from "../../../../images/ic_close_bg_red.svg";
import ic_warning_remove from "../../../../images/ic_warning_remove.svg";

// style
import ButtonRetry from "./EleNoticePopupControl/ButtonRetry"
import TitleNotice from "./EleNoticePopupControl/TitleNotice"

// action
import { closePopup } from '../../../../redux/actions/popupAction/popupContainerAction';
// import MoreDetail from "./EleNoticePopupControl/MoreDetail"

// const ButtonRetryStyle = styled(Button)`
//     margin: 21px auto 0;
//     width: 160px;
// `;

// const TitleNotice = styled.div`
//     font-family: "Open Sans";
//     font-style: normal;
//     font-weight: 600;
//     font-size: 20px;
//     color: #000924;
// `;

// const MoreDetail = styled.div`
//     font-family: "Open Sans";
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     color: #000924;
//     padding: 0 58px;
// `;

const GroupBtn = styled.div`
    display: flex;
    margin-top: 19px;

    & > *:not(:last-child){
        margin-right: 12px;
    }
`;

const lstStep = {
    CAPTURE: "CAPTURE",
    ERROR_CAPTURE: "ERROR_CAPTURE",
    ERROR_CAPTURE_MORE_FACE: "ERROR_CAPTURE_MORE_FACE",
    ERROR_CAPTURE_NO_FACE: "ERROR_CAPTURE_NO_FACE",
    TIMEOUT: "TIMEOUT",
    CONFIRM_CAPTURE: "CONFIRM_CAPTURE"
}

const PopupCameraContainer = (props) => {

    const [imgRecognize, setImgRecognize] = useState("")
    const [imgCapture, setImgCapture] = useState("")
    const [step, setStep] = useState(lstStep.CAPTURE)
    const [stepComponent, setStepComponent] = useState(null)
    
    const dispatch = useDispatch();

    const _handleEventKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        // console.log(key, keyCode)
        //  Escape 27
        //  Enter 13
        switch (keyCode) {
            case 27:
                dispatch(closePopup())
                break;
            case 13:
                setStep(lstStep.CAPTURE)
                break;
            default:
                break;
        }

    }, []);

    useEffect(() => {
        switch(step){
            case lstStep.CAPTURE:
                setStepComponent(
                    <CaptureVideo 
                        setStep={setStep} 
                        lstStep={lstStep} 
                        setImgCapture={setImgCapture} 
                        setImgRecognize={setImgRecognize} 
                    />
                )
                break;
            case lstStep.ERROR_CAPTURE_MORE_FACE:{
                setStepComponent(
                    <NoticePopupControl 
                        iconNotice={ic_close_bg_red}
                        closePopup={() => dispatch(closePopup())}
                        detailInfo={<DetailNoticeErrImgMoreFace />}
                        actionBtn={
                            <ButtonRetry 
                                type="PRIMARY"
                                text="RETRY"
                                onClick={() => setStep(lstStep.CAPTURE)} 
                            />
                        }
                        handleEventKeyPress={_handleEventKeyPress}
                    />
                )
                break;
            }
            case lstStep.ERROR_CAPTURE_NO_FACE:{
                setStepComponent(
                    <NoticePopupControl 
                        iconNotice={ic_close_bg_red}
                        closePopup={() => dispatch(closePopup())}
                        detailInfo={<DetailNoticeErrImgNoFace />}
                        actionBtn={
                            <ButtonRetry 
                                type="PRIMARY"
                                text="RETRY"
                                onClick={() => setStep(lstStep.CAPTURE)} 
                            />
                        }
                        handleEventKeyPress={_handleEventKeyPress}
                    />
                )
                break;
            }
            case lstStep.TIMEOUT: {
                setStepComponent(
                    <NoticePopupControl 
                        iconNotice={ic_warning_remove}
                        closePopup={() => dispatch(closePopup())}
                        detailInfo={<TitleNotice>Camera timed out</TitleNotice>}
                        actionBtn={
                            <GroupBtn>
                                <ButtonRetry 
                                    type="PRIMARY"
                                    text="RETRY"
                                    onClick={() => setStep(lstStep.CAPTURE)} 
                                />
                                <ButtonRetry 
                                    type="LIGHT"
                                    text="CANCEL"
                                    onClick={() => dispatch(closePopup())} 
                                />
                            </GroupBtn>
                        }
                        handleEventKeyPress={_handleEventKeyPress}
                    />
                )
                break;
            }
            case lstStep.CONFIRM_CAPTURE:
                setStepComponent(
                    <ConfirmCaptureImage 
                        setStep={setStep} 
                        lstStep={lstStep} 
                        imgCapture={imgCapture}
                        userType={props.data.userType}
                        imgRecognize={imgRecognize}
                        // setImgCapture={setImgCapture} 
                    />
                )
                break;
            default:
                break;
        }
    }, [step])

    return (
        <>{stepComponent}</>
    )
}

export default PopupCameraContainer
