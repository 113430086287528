import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

// data
import * as type from "../../../data/popupData";

// action
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";

// style
import ButtonRetry from "./Camera/EleNoticePopupControl/ButtonRetry";

// image
import ic_close_bg_red from "../../../images/ic_close_bg_red.svg";

// component
import PopupQRCode from "./PopupQRCode";
import PopupRemoveDevice from "./PopupRemoveDevice";
import PopupUnlinkLocation from "./PopupUnlinkLocation";
import PopupAddNewLocation from "./PopupAddNewLocation";
import PopupAddChildLocation from "./PopupAddChildLocation";
import PopupRemoveLocation from "./PopupRemoveLocation";
import PopupConfirmChangeDetailLocation from "./PopupConfirmChangeDetailLocation";
import PopupAlertLogout from "./PopupAlertLogout";
import PopupCameraContainer from "./Camera/PopupCameraContainer";
import NoticePopupControl from "./Camera/NoticePopupControl";
import DetailNoticeErrImgNoFace from "./Camera/EleNoticePopupControl/DetailNoticeErrImgNoFace";
import DetailNoticeErrImgMoreFace from "./Camera/EleNoticePopupControl/DetailNoticeErrImgMoreFace";
import ConfirmCaptureImage from "./Camera/ConfirmCaptureImage";
import PopupDisableVistor from "./PopupDisableVistor";
import PopupDisableEmployee from "./PopupDisableEmployee";
import PopupConfirmChange from "./PopupConfirmChange";
import PopupAddVisitor from "./PopupAddVisitor";



const Bound = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props =>
    (props.activeOverlay && "rgba(0, 0, 0, 0.9)") || "transparent"};
  .block-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const PopupContainer = props => {
  let isMouseEnterPopup = false;
  const { popupContainerReducer } = useSelector(state => ({
    popupContainerReducer: state.popupContainerReducer
  }));
  const dispatch = useDispatch();
  const [state, setState] = useState({
    content: null,
    activeOverlay: false
  });

  useEffect(() => {
    let content;
    if (popupContainerReducer.typePopup !== -1) {
      switch (popupContainerReducer.typePopup) {
        case type.POPUP_CAMERA: {
          content = <PopupCameraContainer data={popupContainerReducer.data} />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.POPUP_CONFIRM_IMAGE_RECOGNIZE_FACE: {
          content = <ConfirmCaptureImage imgCapture={popupContainerReducer.data.imgCapture} userType={popupContainerReducer.data.userType} />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.POPUP_NOTICE_ERROR_IMG_NO_FACE:{
          content = <NoticePopupControl 
            iconNotice={ic_close_bg_red}
            closePopup={() => dispatch(closePopup())}
            detailInfo={<DetailNoticeErrImgNoFace />}
            actionBtn={
              <ButtonRetry 
                type="PRIMARY"
                text="RETRY"
                onClick={() => dispatch(closePopup())} 
              />
            }
          />
          setState({ activeOverlay: true, content });
          break;
        }
        case type.POPUP_NOTICE_ERROR_IMG_MORE_FACE:{
          content = <NoticePopupControl 
            iconNotice={ic_close_bg_red}
            closePopup={() => dispatch(closePopup())}
            detailInfo={<DetailNoticeErrImgMoreFace />}
            actionBtn={
              <ButtonRetry 
                type="PRIMARY"
                text="RETRY"
                onClick={() => dispatch(closePopup())} 
              />
            }
          />
          setState({ activeOverlay: true, content });
          break;
        }
        case type.REMOVE_DEVICE: {
          content = <PopupRemoveDevice data={popupContainerReducer.data} />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.SHOW_QR_CODE: {
          content = <PopupQRCode />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.UNLINK_LOCATION: {
          content = <PopupUnlinkLocation />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.ADD_NEW_LOCATION: {
          content = <PopupAddNewLocation />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.REMOVE_LOCATION: {
          content = <PopupRemoveLocation />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.ADD_CHILD_LOCATION: {
          content = <PopupAddChildLocation />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.CONFIRM_CHANGE_DETAIL_LOCATION: {
          content = (
            <PopupConfirmChangeDetailLocation
              data={popupContainerReducer.data}
            />
          );
          setState({ activeOverlay: true, content });
          break;
        }
        case type.ALERT_LOGOUT: {
          content = <PopupAlertLogout data={popupContainerReducer.data} content='Your account was logged in at another computer' />;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.ALERT_OUT_SESSION: {
          content = <PopupAlertLogout data={popupContainerReducer.data} content='Your login session has expired'/>;
          setState({ activeOverlay: true, content });
          break;
        }
        case type.POPUP_DISABLE_VISTIOR: {
          content = <PopupDisableVistor data={popupContainerReducer.data}/>
          setState({ activeOverlay: true, content});
          break;
        }
        case type.POPUP_DISABLE_EMPLOYEE: {
          content = <PopupDisableEmployee data={popupContainerReducer.data}/>
          setState({ activeOverlay: true, content});
          break;
        }
        case type.POPUP_CONFIRM_CHANGE: {
          content = <PopupConfirmChange data={popupContainerReducer.data}/>
          setState({ activeOverlay: true, content});
          break;
        }
        case type.POPUP_ADD_VISITOR: {
          content = <PopupAddVisitor data={popupContainerReducer.data}/>
          setState({ activeOverlay: true, content});
          break;
        }
        default:
          break;
      }
    }
  }, []);

  return (
    <Bound activeOverlay={state.activeOverlay}>
      <div
        className="block-popup"
        onMouseEnter={() => {
          return (isMouseEnterPopup = true);
        }}
        onMouseOver={() => {
          if (!isMouseEnterPopup) {
            isMouseEnterPopup = true;
          }
        }}
        onMouseLeave={() => {
          return (isMouseEnterPopup = false);
        }}
      >
        {state.content}
      </div>
    </Bound>
  );
};

PopupContainer.propTypes = {};

export default React.memo(PopupContainer);
