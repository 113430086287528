import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from 'react-router-dom'

/// data
import * as type from "../../../data/popupData";

/// images
import ice_error from "../../../images/ice_error.svg";
import ice_warning from "../../../images/ice_warning.svg";
import ice_success from "../../../images/ice_success.svg";
import { closePopupNotification } from "../../../redux/actions/popupAction/popupNotificationAction";

const Bound = styled.div`
  z-index: 11;
  position: ${props => props.activeOverlay && "absolute"};
  top: ${props => (props.activeOverlay && props.innerTop) || "7%"};
  left: ${props => props.activeOverlay && "50%"};
  transform: ${props => props.activeOverlay && "translate(-50%)"};
`;
const PopupNotificationStyle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background-color: ${props => props.bgColor || "transparent"};
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 20px;
  height: calc(44px - 10px);
  width: ${props => props.widthPopup || "auto"};
  width: auto;
  border-radius: 5px;
  .block-icon {
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block-err-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .btn-actionView {
      width: 72px;
      height: 28px;
      background-color: #ffffff;
      color: #ea3939;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-weight: bold;
    }
  }
  .contentMockup {
    display: flex;
    font-weight: normal;
    .contentFrist{
      font-weight: bold;
    }
    .contentProps {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: ${props => props.widthContentProps};
    }
    .contentProps-update{
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: ${props => props.widthContentProps};
    }
  }
`;

const PopupNotification = props => {
  //=======================================//
  //=============== REDUX =================//

  //// Similar mapDispatchToProps
  const dispatch = useDispatch();
  const history = useHistory()
  //// Similar mapStateToProps
  const { popupNotificationReducer } = useSelector(state => ({
    popupNotificationReducer: state.popupNotificationReducer
  }));

  //========================//
  //======== State ========//

  const [state, setState] = useState({
    type: "",
    keyDetail: "",
    showBtn: false, //// popupErr
    backgroundColor: "",
    widthPopup: "",
    icon: "",
    contentMockup: "",
    contentProps: "",
    contentFirst: "",
    contentLast: "",
    nameBtn: ""
  });
  const [isAutoClose, setIsAutoClose] = useState(true);
  //============Variable============
  const POPUP_ERR = "POPUP_ERR";
  const POPUP_ALERT = "POPUP_ALERT";
  const POPUP_SUCCESS = "POPUP_SUCCESS";
  const POPUP_UPDATE_SUCCESS = "POPUP_UPDATE_SUCCESS"

  //========================/////////////////=========================//
  //========================/////////////////=========================//

  useEffect(() => {
    const { data, typePopup } = popupNotificationReducer;
    console.log(data)
    switch (typePopup) {
      case type.ERR_CONNECT_SERVER:
      case type.EMPLOYEE_ID_ALREADY_EXIT:
      case type.FACE_HAS_ENROLLED:
      case type.SOCIAL_ID_EXISTED:
      case type.CHANGE_PASS_NOT_MATCH:
      case type.UNABLE_EDIT_PROFILE:
      case type.UNABLE_CHANGE_PASS_NOT_FILL:
      case type.NOT_FOUND_CAMERA:
      case type.CURRENT_PASS_WRONG:
      case type.UNABLE_REMOVE_PROFILE_VISITOR:
      case type.CITIZEN_ID_IS_ALREADY:
      case type.ADD_EMPLOYEE_FAIL_EMPLOYEE_ID:
      case type.CANNOT_RECOGNIZE_FACE:
      case type.LOCATION_NAME_ALREADY_EXIST:
      case type.FILE_TYPE_INVALID:
      case type.ADD_USER_ERROR_EMPTY_FIELD:
      case type.ADD_USER_ERROR_EMPTY_AVATAR:
      case type.EXPIRATION_DATE_INVALID:
      case type.PERMITTED_LOCATION_INVALID:
        setState({
          type: POPUP_ERR,
          keyDetail: typePopup,
          showBtn: type.dataPopupNotificationERR[typePopup].showBtn,
          nameBtn: type.dataPopupNotificationERR[typePopup].nameBtn,
          backgroundColor: "#EA3939",
          widthPopup: type.dataPopupNotificationERR[typePopup].width,
          icon: ice_error,
          contentMockup: type.dataPopupNotificationERR[typePopup],
          contentProps: data.contentProps,
          linkPath: data.linkPath
        });

        const dataCheck = type.dataPopupNotificationERR[typePopup];
        dataCheck.isAutoClose === type.IS_NOT_CLOSE_POPUP
          ? setIsAutoClose(false)
          : setIsAutoClose(true);

        break;
      case type.MUST_HAVE_REMOVED_EMPLOYEE:
      case type.MUST_TAKE_PICTURE_EMPLOYEE:
      case type.MUST_TAKE_PICTURE_VISITOR:
      case type.SOME_FIELD_NOT_FILLED:
      case type.PLEASE_ENTER_ALL_INPUT:
      case type.EDIT_USER_DATA_NOT_CHANGE:
        setState({
          type: POPUP_ALERT,
          keyDetail: typePopup,
          backgroundColor: "#E98C00",
          widthPopup: type.dataPopupNotificationALERT[typePopup].width,
          icon: ice_warning,
          contentMockup: type.dataPopupNotificationALERT[typePopup],
          contentProps: data.contentProps
        });
        break;
      case type.PASS_HAS_BEEN_CHANGED:
      case type.ADD_EMPLOYEE_PROFILE_SUCCESS:
      case type.ADD_VISITOR_PROFILE_SUCCESS:
      case type.ADD_DEVICE_SUCCESS:
      case type.REMOVE_DEVICE_SUCCESS:
      case type.ADD_NEW_LOCATION_SUCCESS:
      case type.REMOVE_LOCATION_SUCCESS:
      case type.DEVICE_LINK_LOCATION_SUCCESS:
      case type.DEVICE_UNLINK_LOCATION_SUCCESS:
      case type.ADD_VISITOR_LIST_SUCCESS:
        {
          let dataMockup = type.dataPopupNotificationSUCCESS[typePopup];
          setState({
            type: POPUP_SUCCESS,
            keyDetail: typePopup,
            backgroundColor: "#3AAF3F",
            widthPopup: data.width || dataMockup.width,
            icon: ice_success,
            contentMockup: type.dataPopupNotificationSUCCESS[typePopup],
            contentProps: data.contentProps,
            contentFirst: data.contentFirst || dataMockup.contentFirst,
            contentLast: data.contentLast || dataMockup.contentLast,
          });
          break;
        }
      case type.REMOVE_EMPLOYEE_SUCCESS:
      case type.REMOVE_VISITOR_SUCCESS:
      case type.PROFILE_UPDATE_SUCCESS:
      case type.REMOVE_VISITOR_STATUS_DISABLED_SUCCESS:
        {
          let dataMockup = type.dataPopupNotificationUPDATESUCCESS[typePopup];
          setState({
            type: POPUP_UPDATE_SUCCESS,
            keyDetail: typePopup,
            backgroundColor: "#3AAF3F",
            widthPopup: data.width || dataMockup.width,
            icon: ice_success,
            contentMockup: type.dataPopupNotificationUPDATESUCCESS[typePopup],
            contentProps: data.contentProps,
            contentFirst: data.contentFirst || dataMockup.contentFirst,
            contentLast: data.contentLast || dataMockup.contentLast,
          });
          break;
        }
      default:
        break;
    }
  }, [popupNotificationReducer]);

  useEffect(() => {
    // console.log(isAutoClose);
    if (state.type !== "" && isAutoClose) {
      setTimeout(() => {
        dispatch(closePopupNotification());
      }, 5000);
    }
  }, [dispatch, state.type, isAutoClose]);

  const _handleAction = () => {
    const { data } = popupNotificationReducer;
    const { linkPath } = state
    console.log(data);
    if (data.isAction) {
      data.isAction();
    }
    else if (linkPath) {
      history.push(linkPath)
    }
    dispatch(closePopupNotification());
  };

  //===================================================//
  //================ Render Component ================//
  return (
    <Bound activeOverlay={popupNotificationReducer.activeOverlay}>
      <PopupNotificationStyle
        widthPopup={state.widthPopup && `calc(${state.widthPopup} - 35px)`}
        bgColor={state.backgroundColor}
        widthContentProps={
          popupNotificationReducer.data &&
            popupNotificationReducer.data.contentProps &&
            popupNotificationReducer.data.contentProps.length > 50
            ? "300px"
            : "auto"
        }
      >
        <div className="block-icon" id="cac">
          {state.icon !== "" && (
            <img className="img-icon" src={state.icon} alt="err" />
          )}
        </div>
        {state.type === POPUP_ERR && (
          <div className="block-err-content">
            <div className="contentMockup">
              {state.contentMockup.contentFirst}&nbsp;
              <p className="contentProps">{state.contentProps}</p>&nbsp;
              {state.contentMockup.contentLast}
            </div>
            {state.showBtn && (
              <div className="btn-actionView" onClick={_handleAction}>
                {state.nameBtn}
              </div>
            )}
          </div>
        )}

        {state.type === POPUP_ALERT && (
          <React.Fragment>
            <div className="contentMockup">
              {state.contentMockup.contentFirst}&nbsp;
              <p className="contentProps">{state.contentProps}</p>&nbsp;
              {state.contentMockup.contentLast}
            </div>
          </React.Fragment>
        )}

        {state.type === POPUP_SUCCESS && (
          <React.Fragment>
            <div className="contentMockup">
              <p className="contentFrist">{state.contentFirst}</p>&nbsp;
              <p className="contentProps">{state.contentProps}</p>&nbsp;
              <p>{state.contentLast}</p>
            </div>
          </React.Fragment>
        )}

        {state.type === POPUP_UPDATE_SUCCESS && (
          <React.Fragment>
            <div className="contentMockup">
              <p>{state.contentFirst}</p>&nbsp;
              <p className="contentProps-update">{state.contentProps}</p>&nbsp;
              <p>{state.contentLast}</p>
            </div>
          </React.Fragment>
        )}
      </PopupNotificationStyle>
    </Bound>
  );
};
export default React.memo(PopupNotification);

/**
 * [description]: TypeChecking
 */
PopupNotification.propTypes = {
  data: PropTypes.object,
  onClosePopup: PropTypes.func,
  handleAction: PropTypes.func
};
