import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//host
import { host } from "../../../host/host";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
//image
import ic_dropdown_white from "../../../images/ic_dropdown_white.svg";
import logo_wee_digital from "../../../images/logo_wee_digital.svg";
import ic_avatar_default from '../../../images/avatar_default.svg'

//acion
import { logout } from "../../../redux/actions/loginAction/loginAction";
import { filterAccessRightsDropdownMenu, switchRouter } from "../../../redux/actions/routerAction/routerAction";
import { setFlagPrepareReload } from "../../../redux/actions/systemAction/systemAction";

//data
import {
  menuAccessControlData,
  menuEmployeeAttendanceData,
  menuDeviceData
} from "../../../data/menuData";
import { dropDownMenuData, dropDownMenuFullData } from "../../../data/dropDownData";
//constant
import * as keys from '../../../constant/key'
//component
import DropDownMenuControl from "../../Control/DropDownMenuControl";
import LogOutControl from "../../Control/LogOutControl";
//tools
import { getNavigationData } from '../../../helper/tools'

const Bound = styled.div`
    position: absolute;
    width: 100%;
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 1110px 1fr;
    flex-direction: row;
    background-color: #000924;
    /* z-index: 3; */
  .menu-bar-container {
    grid-column: 2 / span 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo-company {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img.img-logo-weedigital {
          width: 122px;
          height: auto;
          cursor: pointer;
        }
        a{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
            img {
                width: 122px;
                height: auto;
            }
        }
        .dropdown-menu {
            margin-left: 20px;
        }
    }
    .menu-bar {
        display: flex;
        flex:1;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
        padding-left: 135px;
        .link-item {
            display: inline-block;
            margin-right: 54px;
            a {
                text-decoration: none;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
            }
            .active-bar {
                width: 24px;
                height: 1px;
                background: #33adff;
                border-radius: 4px;
                opacity: 0;
            }
        }
        .active {
            a {
                color: #33adff;
            }
            .active-bar {
                opacity: 1;
            }
        }
    }
    .avatar-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        p{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            margin-right: 12px;
        }
        .avatar {
            width: 42px;
            height: 42px;
            border-radius:50%;
        }
        .dropdown-logout {
            width: 12px;
            height: 12px;
            position: relative;
            box-sizing: content-box;
            margin-left: 10px;
            background-image:url("${ic_dropdown_white}");
            background-size:100% 100%;
            cursor: pointer;
        }
    }
}
`;

const MenuBar = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { routerReducer } = useSelector(state => ({
    routerReducer: state.routerReducer
  }));
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));

  const {
    FullName,
    Role,
    UID,
    CompanyID,
    UserName,
    AvatarID
  } = loginReducer.checkLogin;
  const [isShowPopupLogout, setIsShowPopupLogout] = useState(false);
  const [subMenu, setSubMenu] = useState([])
  // const dropDownMenuData = routerReducer.dropDownMenuData;
  const dropdownData = getNavigationData()
  let navigateIcon = ''
  switch (Role) {
    case keys.IT_ADMIN:
      navigateIcon = '/device-setting/location'
      break;
    case keys.RECEPTIONIST:
      navigateIcon = '/visitor/list'
      break;
    case keys.HUMAN_RESOURCE:
      navigateIcon = '/employee-attendance/employee'
    default:
      break;
  }
  useEffect(() => {
    if (!routerReducer || !dropdownData)
      return
    let mainMenu = dropdownData[routerReducer.indexMainSelected]
    if (!mainMenu)
      return
    // console.log(routerReducer)
    if (!mainMenu.sub || mainMenu.sub.length === 0) {
      setSubMenu([])
      return
    }
    let sub = []
    mainMenu.sub.forEach(subItem => {
      if (subItem.roles) {
        if (subItem.roles.find(item => item === Role)) {
          sub.push(subItem)
        }
      }
    });
    if(sub.length>0)
      setSubMenu(sub)
    else
      setSubMenu([])
  }, [routerReducer])

  const _renderMenu = () => {
    const currentLocation = window.location.pathname;
    return subMenu.map((item, i) => {
      return (
        <div
          key={i}
          className={
            routerReducer.indexSubSelected === i
              ? "link-item active"
              : "link-item"
          }>
          <Link to={item.path === currentLocation?item.path+"/reload":item.path}>{item.name}</Link>
          <div className="active-bar"></div>
        </div>
      )
    })
  };
  const _showPopupLogout = () => {
    setIsShowPopupLogout(!isShowPopupLogout);
  };
  const _handleLogout = () => {

    // if(loginReducer.checkLogin && loginReducer.checkLogin.Role !== keys.RECEPTIONIST){
    //   dispatch(switchRouter(''))
    //   dispatch(logout());
    //   return;
    // }

    // const currentLocation = window.location.pathname;
    // switch(currentLocation){
    //   case "/visitor/new":
    //     dispatch(setFlagPrepareReload({visitorAddNew: {
    //       from: "logout-event"
    //     }}))
    //     break;
    //   default:
    //     dispatch(switchRouter(''))
    //     dispatch(logout());
    //     break;
    //   }
    dispatch(switchRouter(''))
    dispatch(logout());
  };

  const imgError = () => {
    let image = document.getElementById('img-avatar')
    if (image) {
      image.onerror = ''
      image.src = ic_avatar_default
    }
  }

  const _handleRemote = () => {
    if(!loginReducer.checkLogin) return;
    const currentLocation = window.location.pathname;
    switch(loginReducer.checkLogin.Role){
      case keys.IT_ADMIN:
        switch(currentLocation){
          case "/device-setting/location":
            history.push("/device-setting/location/reload")
            // dispatch(setFlagPrepareReload({locationsList: true}))
            break;
          default:
            history.push(navigateIcon)
            break;
        }
        break;
      case keys.RECEPTIONIST:
        switch(currentLocation){
          // case "/visitor/new":
          //   dispatch(setFlagPrepareReload({visitorAddNew: {
          //     from: "logo-wee-io",
          //     targetRemote: navigateIcon
          //   }}))
          //   break;
          case "/visitor/list":
            dispatch(setFlagPrepareReload({visitorList: true}))
            break;
          default:
            history.push(navigateIcon)
            break;
        }
        break;
      default:
        history.push(navigateIcon)
        break;
    }
    // const hompagesRouteLink = [
    //   "/device-setting/location",
    //   "/visitor/list",
    //   "/employee-attendance/employee"
    // ]

  }

  //======================Lifecycle Hook===========================
  // useEffect(() => {
  //   // console.log(AccessRights);
  //   dispatch(filterAccessRightsDropdownMenu(AccessRights));
  // }, [loginReducer.checkLogin]);

  //=====================Render Main Component=====================
  return (
    <Bound>
      <div className="menu-bar-container">
        <div className="logo-company">
          {/* <Link to={navigateIcon}>
          <img 
            src={logo_wee_digital} 
            alt="img-logo-weedigital" 
          />
          </Link> */}
          <img 
            src={logo_wee_digital} 
            onClick={_handleRemote}
            alt="img-logo-weedigital" 
            className="img-logo-weedigital" 
          />
          <div className="dropdown-menu">
            <DropDownMenuControl
              data={dropdownData}
              indexSelected={routerReducer.indexMainSelected}
              changeItemDropdown={(path) => {
                // dispatch(switchRouter(path))
              }}
            // dropDownMenuFullData={dropDownMenuFullData}
            // indexDropdown={routerReducer.indexDropdown}
            />
          </div>
        </div>
        <div className="menu-bar">
          {_renderMenu()}
        </div>
        <div className="avatar-login">
          <p>{FullName}</p>
          <img
            id="img-avatar"
            className="avatar"
            src={
              AvatarID ?
                `${host}admin/get-image/${AvatarID}`
                : ic_avatar_default}
            alt="avatar-login"
            onError={() => imgError()}
          />
          <div
            className="dropdown-logout"
            onClick={() => {
              _showPopupLogout();
            }}
          >
            {isShowPopupLogout && (
              <div>
                <LogOutControl
                  isShowPopupLogout={isShowPopupLogout}
                  handleLogout={_handleLogout}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Bound>
  );
};
export default React.memo(MenuBar);
