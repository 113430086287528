import React, { useCallback, useEffect, useRef } from 'react'

// styles
import { Bound, Content }  from "./index.style"

//images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";

const ModalPrompt = ({
    cancelLabel,
    confirmLabel,
    onCancel,
    onConfirm,
    title,
    note
}) => {

    const modalRef = useRef();

    useEffect(() => {
        if (modalRef && modalRef.current) {
            modalRef.current.focus();
        }
    }, []);

    const _handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        console.log(key, keyCode)
        //  Escape 27
        //  Enter 13
        switch (keyCode) {
            case 27:
                onConfirm()
                break;
            case 13:
                onCancel()
                break;
            default:
                break;
        }

    }, []);

    useEffect(() => {
        window.addEventListener("keydown", _handleUserKeyPress)
        return () => {
            window.removeEventListener("keydown", _handleUserKeyPress)
        }
    }, [_handleUserKeyPress])
    
    return (
        <Bound tabIndex="1" ref={modalRef}>
            <Content>
                <img
                    className="ic-warning-remove"
                    src={ic_warning_remove}
                    alt="ic_warning"
                />
                <div className="title-popup">{title}</div>
                {
                    !!note &&
                    <div className="note-popup">
                        {
                            note.map((item, key) => 
                                <div key={key}>{item}</div>
                            )
                        }
                    </div>
                }
                <div className="block-btn">
                    <div className="add-visitor-btn" onClick={onCancel}>
                        {cancelLabel}
                    </div>
                    <div className="discard-btn" onClick={onConfirm}>
                        {confirmLabel}
                    </div>
                </div>
            </Content>
        </Bound>
    )
}

export default ModalPrompt
