import * as types from "../../actions/types";
import { immutabilityHelper } from "../../../helper/tools";

export const initialState = {
  typePopup: -1,
  typeAction: "",
  activeOverlay: false,
  data: {},
  dataNotify:{},
};

const popupContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    ///////////////// OPEN POPUP //////////////////
    case types.OPEN_POPUP_CONTAINER:
      return {
        ...state,
        typePopup: action.typePopup,
        typeAction: action.type,
        activeOverlay: action.activeOverlay,
        data: { ...action.data },
        dataNotify:( action.data?immutabilityHelper(action.data):{})
      };
    ///////////////// CLOSE POPUP //////////////////
    case types.CLOSE_POPUP_CONTAINER:
      return {
        ...state,
        typePopup: -1,
        typeAction: "",
        activeOverlay: false,
        data: {}
      };

    ///////////////// RESET POPUP //////////////////
    case types.RESET_POPUP_CONTAINER:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default popupContainerReducer;
