import * as types from "../../actions/types";

export const initialState = {
    data: [],
    total: 0,
    err: ''
};

export default function attendanceReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FILTER_ATTENDANCE:{
            return {
                ...state,
                data:action.data.Data ? action.data.Data :[],
                total: action.total
            }
        }
        case types.GET_FILTER_ATTENDANCE_ERROR:{
            return {
                ...state,
                // data:[],
                // total: 0,
                err:action.error,
            }
        }

        default:
            return state;
    }
}
