import * as types from "../../actions/types";

export const initialState = {
  typePopup: -1,
  typeAction: "",
  activeOverlay: false,
  data: {}
};

const popupNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    ///////////////// OPEN POPUP //////////////////
    case types.OPEN_POPUP_NOTIFICATION:
      return {
        ...state,
        typePopup: action.typePopup,
        typeAction: action.type,
        activeOverlay: action.activeOverlay,
        data: action.data
      };
    ///////////////// CLOSE POPUP //////////////////
    case types.CLOSE_POPUP_NOTIFICATION:
      return {
        ...state,
        typePopup: -1,
        typeAction: "",
        activeOverlay: false,
        data: {}
      };

    ///////////////// RESET POPUP //////////////////
    case types.RESET_POPUP_NOTIFICATION:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default popupNotificationReducer;
