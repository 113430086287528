import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const Bound = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    width:100vw;
    height:100vh;
    /* width:80px;
    height:80px; */
    /* bottom:20px;
    right:20px; */
    background-color: rgba(0,0,0, 0.3);
    z-index:999;
`
let timer = null
const LoadingPage = props => {
    const [content, setContent] = useState()
    useEffect(() => {
        timer = setTimeout(() => {
            setContent(
            <Bound>
                <Spinner/>
            </Bound>
            )
        }, 100);
        return () => clearTimeout(timer)
    }, [])
    return (
        content
    )
}

export default React.memo(LoadingPage)
