import React, { useEffect, useRef, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
//tools
//image
import ic_check from "../../../../images/ic_check.svg";
import ic_tick_visitor from "../../../../images/ic_tick_visitor.svg";
import ic_avatar_default from '../../../../images/ic_avatar_default.svg'
import image_upload_avatar from '../../../../images/image_upload_avatar.svg'
import image_reupload_avatar from '../../../../images/image_reupload_avatar.svg'
//data
import { FILE_TYPE_INVALID } from "../../../../data/popupData";
import { EMPLOYEE } from "../../../../constant/key";
//action
import { addAvatarVisitor } from "../../../../redux/actions/visitorAction/visitorAction";
import { openPopupNotification } from "../../../../redux/actions/popupAction/popupNotificationAction";
import { addAvatarEmployee } from "../../../../redux/actions/employeeAction/employeeAction";
//component
//styled
const Bound = styled.div`
    width: 16px;
    height: 16px;
    cursor: pointer;  
  .upload-avatar-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 348px;
    background: #FFFFFF;
    border: 1px dashed #000924;
    border-radius: 5px;
    margin-top: 14px;;
    .title-avatar{
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #969FB0;
      margin-right: 23px
    }
    .avatar-default{
      height: 40px;
      width: 40px;
      border-radius: 100%;
      box-sizing: border-box;
      object-fit: cover;
    }

    .wrapper-img-info {
      margin-left: 12px;
      .upload-avatar{
        position: relative;
        width: 103px;
        height: 20px;
        margin: 0 auto;
        input {
          z-index: 3;
          cursor: pointer;
        }
        input[type="file"] {
          cursor: pointer;
          color: #1279da;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 103px;
          height: 20px;
        }
        input[type="file"], /* FF, IE7+, chrome (except button) */
        input[type="file"]::-webkit-file-upload-button { /* chromes and blink button */
          cursor: pointer; 
        }
      }
      .image-type{
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        /* width: 9ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */

        span {
          margin: 0;
          padding: 0;
          vertical-align: middle;
          display: inline-block;

          &:first-child {
            max-width: 11ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .no-file-choosen {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
        color: #969FB0;
      }
    }
  }
`;

//========================MAIN COMPONENT==========================
const CheckBoxAddVisitor = ({
  imgAvatarStore,
  isCopyFromImgRecognize=true,
  handleChangeCheck, // func to set isCopyFromImgRecognize
  userType
}) => {
  //======================Props===================================
  //=====>State
  const [imageUpload, setImageUpload] = useState(imgAvatarStore || ic_avatar_default);
  const [nameImg, setNameImg] = useState("");
  const [typeImg, setTypeImg] = useState("");
  //=====>REF
  const inputFileRef = React.useRef(null);
  //=====>Props Data
  // const { imageAvatar } = props
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  const [isCheckedLocal, setIsCheckedLocal] = useState(isCopyFromImgRecognize);
  useEffect(() => {
    setIsCheckedLocal(isCopyFromImgRecognize);
  }, [isCopyFromImgRecognize])

  useEffect(() => {
    setImageUpload(imgAvatarStore || ic_avatar_default)
  }, [imgAvatarStore])
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  // const { itemReducer } = useSelector(state => ({
  //   item: state.itemReducer
  // }));
  //======================REF====================================
  //======================Variable================================
  //======================Function=================================
  const handleChecked = () => {
    setIsCheckedLocal(!isCheckedLocal);
    handleChangeCheck && handleChangeCheck(!isCheckedLocal)
  };

  const _getPhotoInput = e => {
    _handleFile(e);
  };

  const _handleFile = e => {
    e.preventDefault();
    let reader = new FileReader();
    // debugger;
    //event drag and input file
    let file =
      (!!e.target && !!e.target.files && e.target.files[0]) ||
      (!!e.dataTransfer && !!e.dataTransfer.files && e.dataTransfer.files[0]);
    
    if(!file) return;
    
    const validTypeFile = file.type.match(/image\/(png|jpg|jpeg)/);
    if(!validTypeFile){
      dispatch(openPopupNotification(
        FILE_TYPE_INVALID,
        {},
        false
      ))
      return;
    }
    // console.log(file)
    setNameImg(file.name.split("").reverse().join("").replace(/\w*\./,"").split("").reverse().join(""))
    setTypeImg(file.type.replace(/image\//, ""))

    if (file) {
      reader.onload = () => {
        var img = new Image();
        img.onload = async function () {
          const imageBase64 = img.src;
          await onChangeUploadImage(imageBase64)
          if (inputFileRef.current) {
            inputFileRef.current.value = "";
          }
        };
        img.src = reader.result;
      };
    }
    reader.readAsDataURL(file);
  };

  // const imgAvatarStore = imgAvatarStore || imageUpload;
  const onChangeUploadImage = (imageBase64) => {
    setImageUpload(imageBase64)
    switch(userType){
      case EMPLOYEE:
        dispatch(addAvatarEmployee(imageBase64))
        break;
      default:
        dispatch(addAvatarVisitor(imageBase64))
        break;
    }
  } 
  
  
  //=====================Render Main Component=====================
  return (
    <Bound className="checkbox " >
      {isCheckedLocal ?
        <img onClick={handleChecked} src={ic_tick_visitor} alt="check" />
        :
        <img onClick={handleChecked} src={ic_check} alt="check" />
      }
      {
        !isCheckedLocal &&
        <div className="upload-avatar-container">
          <p className='title-avatar'>Avatar</p>
          <img className='avatar-default' src={imageUpload} alt='ic_avatar_default' />
          <div className='wrapper-img-info'>
            <div className='upload-avatar'>
              {
                imageUpload === ic_avatar_default ?
                <img src={image_upload_avatar} alt='image_upload_avatar' />
                :
                <img src={image_reupload_avatar} alt='image_reupload_avatar' />
              }
              <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                onChange={_getPhotoInput}
              />
            </div>
            {
              imageUpload !== ic_avatar_default && !!nameImg &&
              <p className='image-type'>
                <span title={nameImg}>{nameImg}</span>
                <span>{"." + typeImg}</span>
              </p>
            }
            {
              imageUpload === ic_avatar_default &&
              <p className="no-file-choosen">No file chosen</p>
            }
          </div>
        </div>
      }
    </Bound>
  );
};
export default React.memo(CheckBoxAddVisitor);
