import * as types from "../../actions/types";

export const initialState = {
    lstVisitor: [],
    visitorDetail: {},
    total: 0,
    pageSize: 0,
    pageNum: 0,
    data: {},
    isFilter: false,
    dataItemExcuting: {
        generalInfos: {},
        allowedLocations: [],
        statusChange: {
            imgRecognizeSrc: false,
            avatarSrc: false,
            // generalInfos: {},
            // allowedLocations: [],
        },
        imgRecognizeSrc: "",
        avatarSrc: "",
    },
};

export default function visitorReducer(state = initialState, action) {
    switch (action.type) {
        case types.VISITORS_STATUS_CHANGE_VISITOR_DATA:
            return {
                ...state,
                dataItemExcuting: {
                    ...state.dataItemExcuting,
                    statusChange: {
                        ...state.dataItemExcuting.statusChange,
                        ...action.payload
                    }
                }
            }
        case types.VISITORS_CHANGE_ALLOWED_LOCATIONS: 
            return {
                ...state,
                dataItemExcuting: {
                    ...state.dataItemExcuting,
                    allowedLocations: action.payload.lstLocations
                }
            }
        case types.VISITORS_CHANGE_GENERAL_INFOS:
            return {
                ...state,
                dataItemExcuting: {
                    ...state.dataItemExcuting,
                    generalInfos: {
                        ...state.dataItemExcuting.generalInfos,
                        ...action.payload
                    }
                }
            }
        case types.VISITORS_ADD_AVATAR:
            return {
                ...state,
                dataItemExcuting: {
                    ...state.dataItemExcuting,
                    avatarSrc: action.payload.imgSrc
                }
            }
        case types.VISITORS_ADD_IMG_RECONIZE:
            return {
                ...state,
                dataItemExcuting: {
                    ...state.dataItemExcuting,
                    imgRecognizeSrc: action.payload.imgSrc
                }
            }
        case types.VISITORS_RESET_STATE:
            return {
                ...state,
                [action.payload.fieldName]: initialState[action.payload.fieldName]
            };
        case types.GET_FILTER_VISTOR:{
            return {
                ...state,
                lstVisitor:action.data.Data?action.data.Data:[],
                total: action.data.Total || 0,
                isFilter: action.isFilter,
                err:'',
                type:action.type,
            }
        }
        case types.GET_FILTER_VISTOR_ERROR:{
            return {
                ...state,
                // lstVisitor:[],
                // total: 0,
                err:action.error,
                type:action.type,
            }
        }
        case types.GET_VISTOR:{
            return {
                ...state,
                visitorDetail:action.data.User,
                err:'',
                type:action.type,
                data:{},
            }
        }
        case types.REFRESH_VISTOR_DETAIL:{
            return {
                ...state,
                visitorDetail:{},
                err:'',
                type:action.type,
            }
        }
        case types.GET_VISTOR_ERROR:{
            return {
                ...state,
                visitorDetail:{},
                err:action.error,
                type:action.type,
            }
        }
        case types.ADD_VISTOR:
        case types.UPDATE_VISTOR:
        case types.SET_ACTIVE_VISITOR:{
            return {
                ...state,
                data:action.data,
                err:'',
                type:action.type,
            }
        }
        case types.ADD_VISTOR_ERROR:
        case types.UPDATE_VISTOR_ERROR:
        case types.SET_ACTIVE_VISITOR_ERROR:{
            return {
                ...state,
                data:action.data,
                err:action.error,
                type:action.type,
            }
        }

        default:
            return state;
    }
}
