import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Button from "../../Control/Button";

/// images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
/// data

/// components
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
// removeVisitor
//action
import { removeLocation } from "../../../redux/actions/locationAction/locationAction";
const ButtonOK = styled(Button)`
  width: 160px;
`;
const Bound = styled.div`
  padding: 32px 0px;
  width: 350px;
  height: 272px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  background: #fafafa;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  /* animate */
  animation-name: isShowPopup;
  transition-property: bottom;
  transition-duration: 500ms;
  display: relative;
  @keyframes isShowPopup {
    from {
      top: -100px;
    }
    to {
      top: 45px;
    }
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  /* margin-top: 162px; */
  text-align: center;
  .ic-warning-remove {
  }
  .warning-title {
    padding: 22px 47px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #051321;
  }
  .block-btn {
    display: flex;
    justify-content: center;
  }
`;

function PopupAlertLogout(props) {
  // console.log(history)

  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  //======================REF====================================
  //======================Variable================================

  //======================Function=================================

  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  //======================Lifecycle Hook===========================
  //=====================Render Main Component====================
  return (
    <Bound>
      <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      />
      <Content>
        <img
          className="ic-warning-remove"
          src={ic_warning_remove}
          alt="ic_warning"
        />
        <div className="warning-title">
          <span>{props.content}</span>
        </div>
        <div className="block-btn">
          <ButtonOK type="LIGHT" text="OK" onClick={_onClosePopup} />
        </div>
      </Content>
    </Bound>
  );
}

PopupAlertLogout.propTypes = {
  data: PropTypes.object
};

export default PopupAlertLogout;
