import * as types from "../../actions/types";

// tools
import { sortLstStringByUnicode } from "../../../helper/tools";

export const initialState = {
    lstCompany: [],
    lstJob: []
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_COMPANY:
        return {
            ...state,
            lstCompany: action.data.ListDependedCompany?
                [
                    action.data.CompanyName,
                    ...sortLstStringByUnicode(action.data.ListDependedCompany)
                ]
                :
                [action.data.CompanyName]
        }
    case types.GET_COMPANY_ERROR:
        return {
            ...state,
            lstCompany:[]
        };
    case types.GET_COMPANY_INFO:
        return {
            ...state,
            lstJob: action.data.Job?action.data.Job : []
        }
    case types.GET_COMPANY_INFO_ERROR:
        return {
            ...state,
            lstCompany:[]
        };
        
    default:
        return state;
  }
}
