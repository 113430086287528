import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import QRCode from "qrcode.react";
import CountDown from "./Control/CountDown";
import Button from "../../Control/Button";
//img
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
import ic_refresh from "../../../images/ic_refresh.svg";
//tools
//ic
import ic_qr_time_out from "../../../images/ic_qr_time_out.svg";
//data
//action
import { generateDeviceQR, clearDeviceQR } from "../../../redux/actions/qrAction/qrAction";
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
//component
import Spinner from "../../Control/Spinner";
//styled
const ButtonRefresh = styled(Button)`
  width: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 170%);
`;
const Bound = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  padding: 56px 97px;
  width: 1114px;
  height: 699px;
  background-color: #fff;
  .header-container {
    .title-content {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      display: flex;
      align-items: center;
      color: #000924;
      margin-bottom: 12px;
    }
    .title-detail {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #051321;
      margin-bottom: 73px;
    }
  }
  .qr-code-container {
    text-align: center;
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }
`;

const SetTimoutQRCode = styled.div`
  margin: auto;
  width: 350px;
  height: 350px;
  border: 14px solid #e8ebf1;
  background: url(${ic_qr_time_out});
  box-sizing: border-box;
  position: relative;
  .title-set-timout-qr-code {
    font-family: "Open Sans";
    width: fit-content;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #33adff;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 110%;
    left: 50%;
  }

  .block-refresh-qr-code {
    width: 380px;
    height: 382px;
    background-color: #ffffffa6;
    position: absolute;
    top: -1px;
    left: 0px;
    .button-refresh-qr-code {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #33adff;
      border-radius: 5px;
      margin: auto;
      width: 160px;
      height: 50px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

//========================MAIN COMPONENT==========================
const PopupQRCode = props => {
  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  const [deviceQR, setDeviceQR] = useState(null);
  const [isTimeout, setIsTimeout] = useState(false);
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  const { loginReducer, qrReducer } = useSelector(state => ({
    loginReducer: state.loginReducer,
    qrReducer: state.qrReducer
  }));
  const { UID } = loginReducer.checkLogin;
  //======================REF====================================
  //======================Variable================================
  //======================Function=================================
  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  //======================Lifecycle Hook===========================
  useEffect(() => {
    dispatch(generateDeviceQR(UID));
    return () => {
      dispatch(clearDeviceQR())
    }
  }, []);
  useEffect(() => {
    if (qrReducer.type === "GENRATE_DEVICE_QR_SUCCESS") {
      setDeviceQR(qrReducer.data);
      setIsTimeout(false);
    }
    if (qrReducer.type === "GENRATE_DEVICE_QR_FAIL") {
      // setIsTimeout(true);
      _onClosePopup();
    }
  }, [qrReducer]);

  //=====================Render Main Component=====================
  return (
    <Bound>
      <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      />
      <div className="header-container">
        <div className="title-content"> Add new device </div>
        <div className="title-detail">
          Please use QR Scanner on Wee Device to scan QR code
        </div>
      </div>
      <div className="qr-code-container">
        {!isTimeout ? (
          deviceQR && deviceQR.Code === 0 ? (
            <React.Fragment>
              <QRCode value={deviceQR.QR} size={380} />
              <CountDown
                time={deviceQR.Timeout}
                setIsTimeout={setIsTimeout}
                qr={deviceQR.QR}
              />
            </React.Fragment>
          ) : (
            <Spinner />
          )
        ) : (
          <SetTimoutQRCode>
            <ButtonRefresh
              type="PRIMARY"
              text="REFRESH"
              img={ic_refresh}
              onClick={() => {
                dispatch(generateDeviceQR(UID));
              }}
            />
            <p className="title-set-timout-qr-code">QR code has timed out </p>
          </SetTimoutQRCode>
        )}
      </div>
    </Bound>
  );
};

PopupQRCode.propTypes = {
  propsItem: PropTypes.string.isRequired
};

PopupQRCode.defaultProps = {
  propsItem: "argument"
};

const Memo_PopupQRCode = React.memo(PopupQRCode);

Memo_PopupQRCode.displayName = "Memo_PopupQRCode";

export default Memo_PopupQRCode;
