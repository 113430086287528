import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import mainReducer from "./redux/reducers/mainReducer";
export default function configureStore() {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })) ||
    compose;
  const store = createStore(
    mainReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}
