import * as types from "../types";

export const storeFilterData = (filterType, filterData) => ({
    type: types.STORE_FILTER_DATA,
    payload: {
        filterType,
        filterData:{...filterData}
    }
})

export const clearFilterData = (filterType) => ({
    type: types.CLEAR_FILTER_DATA,
    payload: {
        filterType
    }
})