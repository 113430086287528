import * as types from "../../actions/types";

export const initialState = {
  isLoading: false,
  type: "",
  data: {},
  dataDeviceFilter: {
    lst: [],
    total: 0
  },
  dataDeviceNotLink: {
    lst: [],
    total: 0
  },
  dataDeviceLinked: {
    lst: [],
    total: 0
  },
  error: ""
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_AND_FILTER_DEVICE_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.GET_AND_FILTER_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        dataDeviceFilter: {
          lst: action.data.Data || [],
          total: action.data.Total
        },
        err: "",
        type: action.type
      };
    case types.GET_AND_FILTER_DEVICE_FAIL:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        error: action.err,
        type: action.type
      };
      case types.REMOVE_DEVICE_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.REMOVE_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        err: "",
        type: action.type
      };
    case types.REMOVE_DEVICE_FAIL:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        error: action.err,
        type: action.type
      };
      case types.DEVICE_LINK_LOCATION_START:
      return {
        ...state,
        type: action.type,
        isLoading: false
      };
    case types.DEVICE_LINK_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        err: "",
        type: action.type
      };
    case types.DEVICE_LINK_LOCATION_FAIL:
      return {
        ...state,
        isLoading: true,
        data: { ...action.data },
        error: action.err,
        type: action.type
      };
      case types.GET_DEVICE_NOT_LINK_START:
      return {
        ...state,
        type: action.type,
        isLoading: true
      };
    case types.GET_DEVICE_NOT_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // data: { ...action.data },
        dataDeviceNotLink: {
          lst: action.data.Data || [],
          total: action.data.Total,
          dataQuery: action.data.dataQuery
        },
        err: "",
        type: action.type
      };
    case types.GET_DEVICE_NOT_LINK_FAIL:
      return {
        ...state,
        isLoading: false,
        // data: { ...action.data },
        error: action.err,
        type: action.type
      };
    case types.CLEAR_DATA_DEVICE_NOT_LINK:
      return {
        ...state,
        // isLoading: false,
        // data: { ...action.data },
        // error: action.err,
        dataDeviceNotLink: {
          ...initialState.dataDeviceNotLink
        },
        type: action.type
      }
      case types.DEVICE_UNLINK_LOCATION_START:
        return {
          ...state,
          type: action.type,
          isLoading: false
        };
      case types.DEVICE_UNLINK_LOCATION_SUCCESS:
        return {
          ...state,
          isLoading: true,
          data: { ...action.data },
          err: "",
          type: action.type
        };
      case types.DEVICE_UNLINK_LOCATION_FAIL:
        return {
          ...state,
          isLoading: true,
          data: { ...action.data },
          error: action.err,
          type: action.type
        };
        case types.GET_LIST_DEVICE_LINKED_START:
          return {
            ...state,
            type: action.type,
            isLoading: false
          };
        case types.GET_LIST_DEVICE_LINKED_SUCCESS:
          return {
            ...state,
            isLoading: true,
            // data: { ...action.data },
            dataDeviceLinked: {
              lst: action.data.Data || [],
              total: action.data.Total,
              dataQuery: action.data.dataQuery
            },
            err: "",
            type: action.type
          };
        case types.GET_LIST_DEVICE_LINKED_FAIL:
          return {
            ...state,
            isLoading: true,
            // data: { ...action.data },
            error: action.err,
            type: action.type
          };
          case types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_START:
          return {
            ...state,
            type: action.type,
            isLoading: false
          };
        case types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_SUCCESS:
          return {
            ...state,
            isLoading: true,
            data: { ...action.data },
            err: "",
            type: action.type
          };
        case types.GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_FAIL:
          return {
            ...state,
            isLoading: true,
            data: { ...action.data },
            error: action.err,
            type: action.type
          };

          

        
      
    default:
      return state;
  }
}
