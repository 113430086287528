import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//image
import ic_arrowUp_white from "../../images/ic_arrowUp_white.svg";

const Bound = styled.div`
  position: relative;
  img {
    position: absolute;
    top: 26px;
    right: -8px;
  }
  .dropdown-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 164px;
    /* height: 25px; */
    padding: 0 18px;
    position: absolute;
    top: 34px;
    right: -10px;
    z-index:3;
    a {
      text-decoration: none;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height:40px;
      .contentItem {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000924;
        transition: 0.2s all ease-in-out;
      }
      :hover {
        .contentItem {
          /* background-color: black; */
          color: #000924;
          font-weight: bold;
        }
      }
    }
    .active {
      /* background-color: rgba(255,255,255,0.25);     */
      color: #000924;
      font-weight: bold;
    }
  }
`;
const LogOutControl = props => {
  //local
  let isMouseEnterMenuDropdown = false;
  //state
  const [indexChoice, setIndexChoice] = useState(0);
  const [isShow, setIsShow] = useState(
    props.isShowPopupLogout ? props.isShowPopupLogout : false
  );
  //props function
  const { handleLogout } = props;

  useEffect(() => {
    if (isShow) {
      document.body.addEventListener("click", _handelClick);
    }

    return () => {
      document.body.removeEventListener("click", _handelClick);
    };
  }, [isShow]);

  useEffect(() => {
    return () => document.body.removeEventListener("click", _handelClick);
  }, []);

  const _handelClick = () => {
    if (!isMouseEnterMenuDropdown) {
      if (isShow) {
        setTimeout(() => {
          setIsShow(false);
        }, 0);
      }
    }
  };

  const _handleLogout = () => {
    handleLogout();
  };

  const _renderDropdownLogOut = () => {
    return (
      <React.Fragment>
        <Link to="/change-password">
          <div
            className="dropdown-item"
            // onClick={() => _changeItemDropdown(item, i)}
          >
            <div className="contentItem">Change Password</div>
          </div>
        </Link>
          <Link to='/support'>
          <div className="dropdown-item">
            <div className="contentItem">Support</div>
          </div>
        </Link>
        <div className="dropdown-item" onClick={() => _handleLogout()}>
          <div className="contentItem">Log Out</div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Bound>
      {isShow && (
        <React.Fragment>
          <img src={ic_arrowUp_white} alt="image-arrowUp" />
          <div
            className="dropdown-list"
            onMouseEnter={() => {
              isMouseEnterMenuDropdown = true;
            }}
            onMouseLeave={() => {
              isMouseEnterMenuDropdown = false;
            }}
          >
            {_renderDropdownLogOut()}
          </div>
        </React.Fragment>
      )}
    </Bound>
  );
};

export default React.memo(LogOutControl);

LogOutControl.PropTyes = {};
