import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactDOM, { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

//image
import img_background from "../../images/img_background.svg";
import logo_wee_digital from "../../images/logo_wee_digital.svg";

//component
import BoundEmailContainer from "./BoundMailContainer";
import BoundPasswordContainer from "./BoundPasswordContainer";

const Bound = styled.div`
  display: flex;
  flex: 1;
  /* min-height: 900px;
  min-width:1440px; */
  background-image: url(${img_background});
  /* background-position:center ; */
  background-repeat: no-repeat;
  background-size:  cover;
  position: relative;
  .login-email {
    right: 165px;
  }
  .login-password {
    right: calc(100vw - 730px - 165px);
  }
  .login-container {
    width: 730px;
    height: 684px;
    padding: 60px 95px;
    background-color: rgba(255, 255, 255);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    transition: 500ms;
    transform: translate(0, -50%);
    .comp-logo {
      height: 48px;
    }
    .title-login {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 64px;
      line-height: 87px;
      letter-spacing: 0.02em;
      color: #191919;
      margin: 52px 0 21px;
    }
  }
`;

const LoginPage = props => {
  //ref
  const loginContainerRef = React.useRef(null);

  const [isValidEmail, setValidEmail] = useState(false);

  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));
  const { checkEmail } = loginReducer;

  
  useEffect(() => {
    if (!checkEmail) {
      return;
    }
    if (checkEmail.Code === 0) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [checkEmail]);

  return (
    <Bound>
      <div
        className="login-container"
        id="login-container"
        ref={loginContainerRef}
      >
        <img className="comp-logo" src={logo_wee_digital} alt="img-logo"/>
        <p className="title-login">Login</p>
        {(isValidEmail) ? (
          <BoundPasswordContainer
            email={loginReducer.checkEmail.Email}
            GroupAbridgedID={loginReducer.checkEmail.GroupAbridgedID}
            backMailLogin={() => {
              setValidEmail(false);
            }}
            animation={() => {
              findDOMNode(loginContainerRef.current).classList.add(
                "login-password"
              );
              findDOMNode(loginContainerRef.current).classList.remove(
                "login-email"
              );
            }}
          />
        ) : (
          <BoundEmailContainer
            animation={() => {
              findDOMNode(loginContainerRef.current).classList.add(
                "login-email"
              );
              findDOMNode(loginContainerRef.current).classList.remove(
                "login-password"
              );
            }}
          />
        )}
      </div>
    </Bound>
  );
};
export default React.memo(LoginPage);
