import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
/// data

/// components
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
// removeVisitor
//action
import { updateLocationDetail } from "../../../redux/actions/locationAction/locationAction";

const Bound = styled.div`
  padding: 32px 0px;
  width: 409px;
  height: 316px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  background: #fafafa;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  /* animate */
  animation-name: isShowPopup;
  transition-property: bottom;
  transition-duration: 500ms;
  display: relative;
  @keyframes isShowPopup {
    from {
      top: -100px;
    }
    to {
      top: 45px;
    }
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  /* margin-top: 162px; */
  text-align: center;
  .ic-warning-remove {
  }
  .confirm-remove-title {
    margin-top: 12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #262626;
  }
  .confirm-remove-content {
    padding: 0 35px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000924;
    margin: 25px 0 0 0;
    p {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .text {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
  }

  .block-btn {
    margin-top: 11px;
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
    .remove-btn,
    .cancel-btn {
      width: 160px;
      height: 50px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.03em;
      cursor: pointer;
    }
    .remove-btn {
      background: #33adff;
      color: #ffffff;
    }
    .cancel-btn {
      margin-right: 12px;
      color: #33adff;
      background: #ffffff;
      border: 0.572641px solid #33adff;
    }
  }
`;

function PopupConfirmChangeDetailLocation(props) {
  // let history = useHistory();
  // console.log(history)

  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //======>Props Style
  //======================State===================================
  //======================Redux===================================
  //=====>Redux Action
  const dispatch = useDispatch();
  //=====>Redux Props
  const { loginReducer } = useSelector(state => ({
    loginReducer: state.loginReducer
  }));

  const { data } = useSelector(state => ({
    data: state.popupContainerReducer.data
  }));

  //======================REF====================================
  //======================Variable================================

  //======================Function=================================

  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  const _onConfirmRemove = () => {
    dispatch(
      updateLocationDetail(
        data.nameRequest,
        data.descRequest,
        data.CompanyID,
        data.locationID,
        data.checkBox
      )
    );
    dispatch(closePopup());
  };
  //======================Lifecycle Hook===========================
  const _handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    // console.log(key, keyCode)
    //  Escape 27
    //  Enter 13
    switch (keyCode) {
      case 27:
        _onClosePopup()
        break;
      case 13:
        _onConfirmRemove()
        break;
      default:
        break;
    }

  }, []);

  useEffect(() => {
    window.addEventListener("keydown", _handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", _handleUserKeyPress)
    }
  }, [_handleUserKeyPress])
  //=====================Render Main Component====================
  return (
    <Bound>
      {/* <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      /> */}
      <Content>
        <img
          className="ic-warning-remove"
          src={ic_warning_remove}
          alt="ic_warning"
        />
        <div className="confirm-remove-title">
          <span>Please review your changes</span>
        </div>
        <div className="confirm-remove-content">
          <p>
            <span>
              Change {data.nameRequest ? " Location Name: " : " Description: "}
            </span>
            &ensp;
            <span
              className="text"
              style={{ color: "#000924", fontWeight: "bold" }}
            >
              {data.nameRequest ? data.oldName : data.oldDesc}
            </span>
          </p>
          <p>
            <span>to</span>
            &ensp;
            <span
              className="text"
              style={{ color: "#33ADFF", fontWeight: "bold" }}
            >
              {data.nameRequest ? data.nameRequest : data.descRequest}
            </span>
          </p>
        </div>
        <div className="block-btn">
          <div className="cancel-btn" onClick={_onClosePopup}>
            CANCEL
          </div>
          <div className="remove-btn" onClick={_onConfirmRemove}>
            SAVE CHANGE
          </div>
        </div>
      </Content>
    </Bound>
  );
}

PopupConfirmChangeDetailLocation.propTypes = {
  data: PropTypes.object
};

export default PopupConfirmChangeDetailLocation;
