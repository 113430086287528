import * as types from "../types";
import { host } from "../../../host/host";
import Axios from "axios";

// tools
import { formatArrToObj } from "../../../helper/tools";

//
const SuccessCode = 0;

export const getAndSearchLocation = (
  CompanyID,
  LocationName
) => async dispatch => {
  const apiSearchLocation = host + "location/details/search";
  const dataQueryFormat = JSON.stringify({
    CompanyID,
    LocationName
  });
  dispatch({
    type: types.GET_AND_SEARCH_LOCATION_START
  });
  try {
    const res = await Axios.post(apiSearchLocation, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.GET_AND_SEARCH_LOCATION_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.GET_AND_SEARCH_LOCATION_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_AND_SEARCH_LOCATION_FAIL,
      err
    });
  }
};
export const removeLocation = (CompanyID, LocationID, parentLocationID) => async dispatch => {
  const apiRemoveLocation = host + "location/remove";
  const dataQueryFormat = JSON.stringify({
    CompanyID,
    LocationID
  });
  dispatch({
    type: types.REMOVE_LOCATION_START
  });
  try {
    const res = await Axios.post(apiRemoveLocation, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.REMOVE_LOCATION_SUCCESS,
        data: {
          ...data,
          parentLocationID
        }
      });
    } else {
      dispatch({
        type: types.REMOVE_LOCATION_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.REMOVE_DEVICE_FAIL,
      err
    });
  }
};

export const addNewLocation = (
  Name,
  Description,
  IsAllowedVisitor,
  ParentID
) => async dispatch => {
  const apiAddNewLocation = host + "location/add";
  const dataQueryFormat = JSON.stringify({
    Name,
    Description,
    IsAllowedVisitor,
    ParentID
  });
  dispatch({
    type: types.ADD_NEW_LOCATION_START
  });
  try {
    const res = await Axios.post(apiAddNewLocation, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      dispatch({
        type: types.ADD_NEW_LOCATION_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.ADD_NEW_LOCATION_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.ADD_NEW_LOCATION_FAIL,
      err
    });
  }
};
export const getLocationBranch = (CompanyID, LocationID) => async dispatch => {
  const apiGetLocationBranch = host + "location/details/branch";
  const dataQueryFormat = JSON.stringify({
    CompanyID,
    LocationID,
  });
  dispatch({
    type: types.GET_LOCATION_BRANCH_START
  });
  try {
    const res = await Axios.post(apiGetLocationBranch, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    if (callApiSuccess) {
      dispatch({
        type: types.GET_LOCATION_BRANCH_SUCCESS,
        data: data
      });
    } else {
      dispatch({
        type: types.GET_LOCATION_BRANCH_FAIL,
        data: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_LOCATION_BRANCH_FAIL,
      err
    });
  }
};
// export const getListDeviceLinked = (
//   LocationID,
//   PageSize,
//   PageNumber
// ) => async dispatch => {
//   const apiListDeviceLinked = host + "device/listDevice";
//   const dataQueryFormat = JSON.stringify({
//     LocationID,
//     PageSize,
//     PageNumber
//   });
//   dispatch({
//     type: types.GET_LIST_DEVICE_LINKED_START
//   });
//   try {
//     const res = await Axios.post(apiListDeviceLinked, dataQueryFormat);
//     const { data } = res;
//     const callApiSuccess = data.Code === SuccessCode ? true : false;
//     if (callApiSuccess) {
//       dispatch({
//         type: types.GET_LIST_DEVICE_LINKED_SUCCESS,
//         data: data
//       });
//     } else {
//       dispatch({
//         type: types.GET_LIST_DEVICE_LINKED_FAIL,
//         data: data
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: types.GET_LIST_DEVICE_LINKED_FAIL,
//       err
//     });
//   }
// };
// "Name": "string",
// "Description": "string",
// "CompanyID": "string",
// "LocationID": "string",
// "IsAllowedVisitor": true

/**
 * 
 * @param {*string} name 
 * @param {*string} desc 
 * @param {*string} companyID 
 * @param {*string} locationID 
 * @param {*boolean} isAllowed 
 */
export const updateLocationDetail = (Name, Description, CompanyID, LocationID, IsAllowedVisitor) => async dispatch => {
  const apiListDeviceLinked = host + "location/update";
  const dataQueryFormat = JSON.stringify({
    Name: Name !== "" ? Name : null,
    Description: Description/*  !== "" ? Description : null */,
    CompanyID,
    LocationID,
    IsAllowedVisitor
  })

  dispatch({
    type: types.UPDATE_LOCATION_DETAIL_START
  })

  try {
    const res = await Axios.post(apiListDeviceLinked, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    if (callApiSuccess) {
      dispatch({
        type: types.UPDATE_LOCATION_DETAIL_SUCCESS,
        data: data
      })
    } else {
      dispatch({
        type: types.UPDATE_LOCATION_DETAIL_FAIL,
        data: data
      })
    }
  } catch (error) {
    dispatch({
      type: types.UPDATE_LOCATION_DETAIL_FAIL,
      err: error
    })
  }
}

export const linkDevice = () => async dispatch => {
  // const apiAddnewLocation = hostDevice + "admin/device/get-filter";
  // const dataQueryFormat = JSON.stringify({});
  // dispatch({
  //   type: types.ADD_NEW_LOCATION_START
  // });
  // try {
  //   const res = await Axios.post(apiAddnewLocation, dataQueryFormat);
  //   const { data } = res;
  //   const callApiSuccess = data.Code === SuccessCode ? true : false;
  //   // console.log(res);
  //   if (callApiSuccess) {
  //     dispatch({
  //       type: types.ADD_NEW_LOCATION_SUCCESS,
  //       data: data
  //     });
  //   } else {
  //     dispatch({
  //       type: types.ADD_NEW_LOCATION_FAIL,
  //       data: data
  //     });
  //   }
  // } catch (err) {
  //   dispatch({
  //     type: types.ADD_NEW_LOCATION_FAIL,
  //     err
  //   });
  // }
};

export const getFilterAllowedLocation = (CompanyID, Name) => async dispatch => {
  // "CompanyID": "string"
  // "Name": "string",
  const api = host + "user/filter-in-list-allowed-location";
  const dataQueryFormat = JSON.stringify({
      CompanyID, Name
  });
  try {
      const res = await Axios.post(api, dataQueryFormat);
      const { data } = res;
      const callApiSuccess = data.Code === SuccessCode ? true : false;
      // console.log(res);
      if (callApiSuccess) {
		  
		const listLocation = formatArrToObj(data.ListLocation, "000000000000000000000000", "ParentID", "children", "ID")
		
		dispatch({
			type: types.GET_FILTER_ALLOWED_LOCATION,
      data: listLocation,
      initialData: data.ListLocation || []
		});
      } else {
          dispatch({
              type: types.GET_FILTER_ALLOWED_LOCATION_ERROR,
              data: data
          });
      }
  } catch (err) {
      dispatch({
          type: types.GET_FILTER_ALLOWED_LOCATION_ERROR,
          err
      });
  }
};

export const getLocationArrByIDArr = (CompanyID, PermittedLocation) => async dispatch => {
  // "CompanyID": "string"
  // "PermittedLocation": [ "string" ],
  const api = host + "user/get-location-detail";
  const dataQueryFormat = JSON.stringify({
      CompanyID, PermittedLocation
  });
  try {
      const res = await Axios.post(api, dataQueryFormat);
      const { data } = res;
      const callApiSuccess = data.Code === SuccessCode ? true : false;
      // console.log(res);
      
      if (callApiSuccess) {
          const listLocation = formatArrToObj(data.ListLocation, "000000000000000000000000", "ParentID", "children", "ID")
          // debugger
          dispatch({
              type: types.GET_LOCATION_ARRAY_BY_ID_ARRAY,
              data: listLocation,
              initialData: data.ListLocation || []
          });
      } else {
          dispatch({
              type: types.GET_LOCATION_ARRAY_BY_ID_ARRAY_ERROR,
              data: data
          });
      }
  } catch (err) {
      dispatch({
          type: types.GET_LOCATION_ARRAY_BY_ID_ARRAY_ERROR,
          err
      });
  }
};