
export const deviceList = [
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: '',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: '',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: '',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: '',
    GateOut: "Gate In",
    IsOnline: 'Offline',
    LastActivity: Date.now(),
  },
  {
    DeviceModel: 'WeeG0101',
    DeviceName: 'WEECH218745',
    LocationLink: 'G-Floor Reception 1E',
    GateOut: "Gate In",
    IsOnline: 'Online',
    LastActivity: Date.now(),
  }
]



export const locationData = [

  {
    id: "1",
    locationName: "G-Floor Reception 1",
    dateCreated: Date.now(),
    locationChild: [
      {
        id: "1.1",
        locationName: "G-Floor Reception 1.1",
        dateCreated: Date.now(),
        locationChild: [
          {
            id: "1.1.1",
            locationName: "G-Floor Reception 1.1.1",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.1.2",
            locationName: "G-Floor Reception 1.1.2",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.1.3",
            locationName: "G-Floor Reception 1.1.3",
            dateCreated: Date.now(),
            locationChild: [{
              id: "1.1.3.1",
              locationName: "G-Floor Reception 1.1.3.1",
              dateCreated: Date.now(),
              locationChild: []
            }]

          }
        ]
      },
      {
        id: "1.2",
        locationName: "G-Floor Reception 1.2",
        dateCreated: Date.now(),
        locationChild: [
          {
            id: "1.2.1",
            locationName: "G-Floor Reception 1.2.1",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.2.2",
            locationName: "G-Floor Reception 1.2.2",
            dateCreated: Date.now(),
            locationChild: []

          }
        ]
      },
      {
        id: "1.3",
        locationName: "G-Floor Reception 1.3",
        dateCreated: Date.now(),
        locationChild: []

      }
    ]

  },
  {
    id: "2",
    locationName: "G-Floor Reception 2",
    dateCreated: Date.now(),
    locationChild: [
      {
        id: "2.1",
        locationName: "G-Floor Reception 2.1",
        dateCreated: Date.now(),
        locationChild: [
          {
            id: "1.1.1",
            locationName: "G-Floor Reception 2.1.1",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.1.2",
            locationName: "G-Floor Reception 2.1.2",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.1.3",
            locationName: "G-Floor Reception 2.1.3",
            dateCreated: Date.now(),
            locationChild: []

          }
        ]
      },
      {
        id: "2.2",
        locationName: "G-Floor Reception 2.2",
        dateCreated: Date.now(),
        locationChild: [
          {
            id: "1.2.1",
            locationName: "G-Floor Reception 2.2.1",
            dateCreated: Date.now(),
            locationChild: []

          },
          {
            id: "1.2.2",
            locationName: "G-Floor Reception 2.2.2",
            dateCreated: Date.now(),
            locationChild: []
          }
        ]
      },
      {
        id: "2.3",
        locationName: "G-Floor Reception 2.3",
        dateCreated: Date.now(),
        locationChild: []
      }
    ]

  },
  {
    id: "4",
    locationName: "G-Floor Reception 4",
    dateCreated: Date.now(),
    locationChild: []
  }


]


export const listLinkDevice = [
  {
    id:'1',
    DeviceName: 'WEECH218745',
    Gate: 'IN'
  },
  {
    id:'2',

    DeviceName: 'WEECH21843434',
    Gate: 'OUT'
  },
  {
    id:'3',

    DeviceName: 'WEECH21874445',
    Gate: 'IN'
  },
  {
    id:'4',

    DeviceName: 'WEECH21843433434',
    Gate: 'OUT'
  },
  {
    id:'5',

    DeviceName: 'WEECH218745',
    Gate: 'IN'
  },
  {
    id:'6',

    DeviceName: 'WEECH2182243434',
    Gate: 'OUT'
  },
  {
    id:'7',

    DeviceName: 'WEECH21872245',
    Gate: 'IN'
  },
  {
    id:'8',

    DeviceName: 'WEECH2184443434',
    Gate: 'OUT'
  }
]
