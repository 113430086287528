//========= POPUP_NOTIFICATION TYPE =========//
export const OPEN_POPUP_NOTIFICATION = "OPEN_POPUP_NOTIFICATION";
export const CLOSE_POPUP_NOTIFICATION = "CLOSE_POPUP_NOTIFICATION";
export const RESET_POPUP_NOTIFICATION = "RESET_POPUP_NOTIFICATION";

export const OPEN_POPUP_CONTAINER = "OPEN_POPUP_CONTAINER";
export const CLOSE_POPUP_CONTAINER = "CLOSE_POPUP_CONTAINER";
export const RESET_POPUP_CONTAINER = "RESET_POPUP_CONTAINER";

//==========ROUTER===============================
export const SWITCH_ROUTER = "SWITCH_ROUTER";
export const FILLTER_ACCESS_RIGHT_DROPDOWN_MENU = "FILLTER_ACCESS_RIGHT_DROPDOWN_MENU";
export const REFRESH_ROUTER = "REFRESH_ROUTER"

//========= USER MANAGE TYPE =========//
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAIL = "CHANGE_PASS_FAIL";
export const RESET_USER = "RESET_USER";

//==========AUTH TYPE=================
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const CHECK_EMAIL_LOGIN_START = "CHECK_EMAIL_LOGIN_START";
export const CHECK_EMAIL_LOGIN_SUCCESS = "CHECK_EMAIL_LOGIN_SUCCESS";
export const CHECK_EMAIL_LOGIN_FAIL = "CHECK_EMAIL_LOGIN_FAIL";
//========= DEVICE TYPE =========//
export const GET_AND_FILTER_DEVICE_START = "GET_AND_FILTER_DEVICE_START";
export const GET_AND_FILTER_DEVICE_SUCCESS = "GET_AND_FILTER_DEVICE_SUCCESS";
export const GET_AND_FILTER_DEVICE_FAIL = "GET_AND_FILTER_DEVICE_FAIL";

export const REMOVE_DEVICE_START = "REMOVE_DEVICE_START";
export const REMOVE_DEVICE_SUCCESS = "REMOVE_DEVICE_SUCCESS";
export const REMOVE_DEVICE_FAIL = "REMOVE_DEVICE_FAIL";

export const DEVICE_LINK_LOCATION_START = "DEVICE_LINK_LOCATION_START";
export const DEVICE_LINK_LOCATION_SUCCESS = "DEVICE_LINK_LOCATION_SUCCESS";
export const DEVICE_LINK_LOCATION_FAIL = "DEVICE_LINK_LOCATION_FAIL";

export const DEVICE_UNLINK_LOCATION_START = "DEVICE_UNLINK_LOCATION_START";
export const DEVICE_UNLINK_LOCATION_SUCCESS = "DEVICE_UNLINK_LOCATION_SUCCESS";
export const DEVICE_UNLINK_LOCATION_FAIL = "DEVICE_UNLINK_LOCATION_FAIL";

export const GET_DEVICE_NOT_LINK_START = "GET_DEVICE_NOT_LINK_START";
export const GET_DEVICE_NOT_LINK_SUCCESS = "GET_DEVICE_NOT_LINK_SUCCESS";
export const GET_DEVICE_NOT_LINK_FAIL = "GET_DEVICE_NOT_LINK_FAIL";

export const CLEAR_DATA_DEVICE_NOT_LINK = "CLEAR_DATA_DEVICE_NOT_LINK";

export const GET_LIST_DEVICE_LINKED_START = "GET_LIST_DEVICE_LINKED_START";
export const GET_LIST_DEVICE_LINKED_SUCCESS = "GET_LIST_DEVICE_LINKED_SUCCESS";
export const GET_LIST_DEVICE_LINKED_FAIL = "GET_LIST_DEVICE_LINKED_FAIL";

export const CLEAR_DATA_DEVICE_LINKED = "CLEAR_DATA_DEVICE_LINKED"

export const GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_START =
  "GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_START";
export const GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_SUCCESS =
  "GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_SUCCESS";
export const GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_FAIL =
  "GET_DEVICE_NOT_LINK_AND_DEVICE_LINKED_FAIL";

// export const GET_DEVICE_NOT_LINK_START =
//   "GET_DEVICE_NOT_LINK_START";
// export const GET_DEVICE_NOT_LINK_SUCCESS =
//   "GET_DEVICE_NOT_LINK_SUCCESS";
// export const GET_DEVICE_NOT_LINK_FAIL =
//   "GET_DEVICE_NOT_LINK_FAIL";

// export const GET_DEVICE_LINKED_START =
//   "GET_DEVICE_LINKED_START";
// export const GET_DEVICE_LINKED_SUCCESS =
//   "GET_DEVICE_LINKED_SUCCESS";
// export const GET_DEVICE_LINKED_FAIL =
//   "GET_DEVICE_LINKED_FAIL";


//LOCATION

export const GET_LOCATION_BRANCH_START = "GET_LOCATION_BRANCH_START";
export const GET_LOCATION_BRANCH_SUCCESS = "GET_LOCATION_BRANCH_SUCCESS";
export const GET_LOCATION_BRANCH_FAIL = "GET_LOCATION_BRANCH_FAIL";

export const ADD_NEW_LOCATION_START = "ADD_NEW_LOCATION_START";
export const ADD_NEW_LOCATION_SUCCESS = "ADD_NEW_LOCATION_SUCCESS";
export const ADD_NEW_LOCATION_FAIL = "ADD_NEW_LOCATION_FAIL";

export const ADD_CHILD_LOCATION_START = "ADD_CHILD_LOCATION_START";
export const ADD_CHILD_LOCATION_SUCCESS = "ADD_CHILD_LOCATION_SUCCESS";
export const ADD_CHILD_LOCATION_FAIL = "ADD_CHILD_LOCATION_FAIL";

export const REMOVE_LOCATION_START = "REMOVE_LOCATION_START";
export const REMOVE_LOCATION_SUCCESS = "REMOVE_LOCATION_SUCCESS";
export const REMOVE_LOCATION_FAIL = "REMOVE_LOCATION_FAIL";

export const GET_AND_SEARCH_LOCATION_START = "GET_AND_SEARCH_LOCATION_START";
export const GET_AND_SEARCH_LOCATION_SUCCESS =
  "GET_AND_SEARCH_LOCATION_SUCCESS";
export const GET_AND_SEARCH_LOCATION_FAIL = "GET_AND_SEARCH_LOCATION_FAIL";

export const GET_DETAIL_LOCATION_START = "GET_DETAIL_LOCATION_START";
export const GET_DETAIL_LOCATION_SUCCESS = "GET_DETAIL_LOCATION_SUCCESS";
export const GET_DETAIL_LOCATION_FAIL = "GET_DETAIL_LOCATION_FAIL";

export const UPDATE_LOCATION_DETAIL_START = "UPDATE_LOCATION_DETAIL_START";
export const UPDATE_LOCATION_DETAIL_SUCCESS = "UPDATE_LOCATION_DETAIL_SUCCESS";
export const UPDATE_LOCATION_DETAIL_FAIL = "UPDATE_LOCATION_DETAIL_FAIL";

//======================SOCKET============
export const SET_SOCKET_START = "SET_SOCKET_START";
export const SET_SOCKET_SUCCESS = "SET_SOCKET_SUCCESS";

export const GENERATE_DEVICE_QR_START = "GENERATE_DEVICE_QR_START";
export const GENERATE_DEVICE_QR_SUCCESS = "GENRATE_DEVICE_QR_SUCCESS";
export const GENERATE_DEVICE_QR_FAIL = "GENRATE_DEVICE_QR_FAIL";
export const CLEAR_DEVICE_QR_CODE = "CLEAR_DEVICE_QR_CODE";

// VISITORS
export const VISITORS_ADD_IMG_RECONIZE = "VISITORS_ADD_IMG_RECONIZE";
export const VISITORS_ADD_AVATAR = "VISITORS_ADD_AVATAR";
export const VISITORS_CHANGE_ALLOWED_LOCATIONS = "VISITORS_CHANGE_ALLOWED_LOCATIONS";
export const VISITORS_CHANGE_GENERAL_INFOS = "VISITORS_CHANGE_GENERAL_INFOS";
export const VISITORS_STATUS_CHANGE_VISITOR_DATA = "VISITORS_STATUS_CHANGE_VISITOR_DATA";
export const VISITORS_RESET_STATE = "VISITORS_RESET_STATE";
export const GET_FILTER_VISTOR = "GET_FILTER_VISTOR";
export const GET_FILTER_VISTOR_ERROR = "GET_FILTER_VISTOR_ERROR";
export const GET_VISTOR = "GET_VISTOR";
export const GET_VISTOR_ERROR = "GET_VISTOR_ERROR";
export const REFRESH_VISTOR_DETAIL = "REFRESH_VISTOR_DETAIL"
export const ADD_VISTOR = "ADD_VISTOR";
export const ADD_VISTOR_ERROR = "ADD_VISTOR_ERROR";
export const UPDATE_VISTOR = "UPDATE_VISTOR";
export const UPDATE_VISTOR_ERROR = "UPDATE_VISTOR_ERROR";
export const GET_FILTER_ALLOWED_LOCATION = "GET_FILTER_ALLOWED_LOCATION";
export const GET_FILTER_ALLOWED_LOCATION_ERROR = "GET_FILTER_ALLOWED_LOCATION_ERROR";
export const GET_LOCATION_ARRAY_BY_ID_ARRAY = "GET_LOCATION_ARRAY_BY_ID_ARRAY";
export const GET_LOCATION_ARRAY_BY_ID_ARRAY_ERROR = "GET_LOCATION_ARRAY_BY_ID_ARRAY_ERROR";
export const SET_ACTIVE_VISITOR = "SET_ACTIVE_VISITOR"
export const SET_ACTIVE_VISITOR_ERROR = "SET_ACTIVE_VISITOR_ERROR"

// EMPLOYEE
export const EMPLOYEE_ADD_IMG_RECONIZE = "EMPLOYEE_ADD_IMG_RECONIZE";
export const EMPLOYEE_ADD_AVATAR = "EMPLOYEE_ADD_AVATAR";
export const EMPLOYEE_CHANGE_ALLOWED_LOCATIONS = "EMPLOYEE_CHANGE_ALLOWED_LOCATIONS";
export const EMPLOYEE_CHANGE_GENERAL_INFOS = "EMPLOYEE_CHANGE_GENERAL_INFOS";
export const EMPLOYEE_STATUS_CHANGE_EMPLOYEE_DATA = "EMPLOYEE_STATUS_CHANGE_EMPLOYEE_DATA";
export const EMPLOYEE_RESET_STATE = "EMPLOYEE_RESET_STATE";
export const GET_FILTER_EMPLOYEE = "GET_FILTER_EMPLOYEE";
export const GET_FILTER_EMPLOYEE_ERROR = "GET_FILTER_EMPLOYEE_ERROR";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const REFRESH_EMPLOYEE_DETAIL = "REFRESH_EMPLOYEE_DETAIL"
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_ERROR = "ADD_EMPLOYEE_ERROR";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";
export const SET_ACTIVE_EMPLOYEE = "SET_ACTIVE_EMPLOYEE"
export const SET_ACTIVE_EMPLOYEE_ERROR = "SET_ACTIVE_EMPLOYEE_ERROR"

//ACTIVITIES
export const GET_FILTER_ACTIVITY = "GET_FILTER_ACTIVITY";
export const GET_FILTER_ACTIVITY_ERROR = "GET_FILTER_ACTIVITY_ERROR";
export const EXPORT_ACTIVITY = "EXPORT_ACTIVITY";
export const EXPORT_ACTIVITY_ERROR = "EXPORT_ACTIVITY_ERROR";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";
export const CLEAR_ACTIVITY = "CLEAR_ACTIVITY";

export const START_LOADING = "START_LOADING"
export const STOP_LOADING = "STOP_LOADING"

//COMPANY
export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR"
export const GET_COMPANY_INFO = "GET_COMPANY_INFO"
export const GET_COMPANY_INFO_ERROR = "GET_COMPANY_INFO_ERROR"

//FILTER
export const STORE_FILTER_DATA = "STORE_FILTER_DATA"
export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA"

// SYSTEM
export const  SET_FLAG_PREPARE_RELOAD = "SET_FLAG_PREPARE_RELOAD"
export const  SET_FLAG_LOADED = "SET_FLAG_LOADED"
export const  SET_FLAG_NEED_RELOAD = "SET_FLAG_NEED_RELOAD"
export const  ADD_BACK_END_INFO = "ADD_BACK_END_INFO"
export const  SET_PROMT_NAVIGATE = "SET_PROMT_NAVIGAGTE"

// ATTENDANCE
export const GET_FILTER_ATTENDANCE = "GET_FILTER_ATTENDANCE";
export const GET_FILTER_ATTENDANCE_ERROR = "GET_FILTER_ATTENDANCE_ERROR";
export const EXPORT_ATTENDANCE = "EXPORT_ATTENDANCE";
export const EXPORT_ATTENDANCE_ERROR = "EXPORT_ATTENDANCE_ERROR";

//AVTIVITY LOG
export const GET_LOG_USER = "GET_LOG_USER";
export const RESET_LOG_USER = "RESET_LOG_USER";