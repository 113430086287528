import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router';

//images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
//action
import { closePopup } from '../../../redux/actions/popupAction/popupContainerAction'
import { switchRouter } from '../../../redux/actions/routerAction/routerAction';
import { logout } from '../../../redux/actions/loginAction/loginAction';

const Bound = styled.div`
    width: 409px;
    height: 316px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #ffffff;    
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    padding: 60px 38px;
    /* animate */
    animation-name: isShowPopup;
    transition-property: bottom;
    transition-duration: 500ms;
    display: relative;
    @keyframes isShowPopup {
        from {
            top: -100px;
        }
        to {
            top: 45px;
        }
    }
    .ic-close-remove-popup {
        position: absolute;
        right: 22px;
        top: 22px;
        cursor: pointer;
    }
`;
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-items: center;
    img{
        text-align:center;
    }
    .title-popup{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #051321;
        text-align: center;
        padding-top: 12px;
        width: 269px; 
    }
    .block-btn {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        .add-visitor-btn,
        .discard-btn {
            width: 160px;
            height: 50px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.03em;
            cursor: pointer;
        }
        .add-visitor-btn {
            color: #ffffff;
            background: #33adff;        
            margin-right: 12px;
            border: 0.6px solid #33ADFF;
        }
        .discard-btn {
            background: #ffffff;
            color: #33ADFF;
            border: 0.6px solid #33ADFF;
            box-sizing: border-box;
        }
    }    
`
const PopupAddVisitor = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();

    const onClosePopup = () => {
        dispatch(closePopup())
    };

    const _handleAddVisitor = () => {
        dispatch(closePopup())
    }

    const _handleDiscard = () => {
        const { data } = props;
        
        const { targetRemote, from } = data;

        if(from === "logout-event"){
            dispatch(switchRouter(''))
            dispatch(logout());
            dispatch(closePopup());
            return;
        }

        if(!targetRemote) {
            dispatch(closePopup())
            return;
        }
        
        history.push(targetRemote)
        dispatch(closePopup())
    }

    const _handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        // console.log(key, keyCode)
        //  Escape 27
        //  Enter 13
        switch (keyCode) {
            case 27:
                _handleDiscard()
                break;
            case 13:
                _handleAddVisitor()
                break;
            default:
                break;
        }

    }, []);

    useEffect(() => {
        window.addEventListener("keydown", _handleUserKeyPress)
        return () => {
            window.removeEventListener("keydown", _handleUserKeyPress)
        }
    }, [_handleUserKeyPress])

    return (
        <Bound>
            <img
                className="ic-close-remove-popup"
                src={ic_close_remove_popup}
                onClick={onClosePopup}
                alt="ic_close"
            />
            <Content>
                <img
                    className="ic-warning-remove"
                    src={ic_warning_remove}
                    alt="ic_warning"
                />
                <p className="title-popup">Please confirm visitor registration information</p>
                <div className="block-btn">
                    <div className="add-visitor-btn" onClick={_handleAddVisitor}>
                        ADD VISITOR
                    </div>
                    <div className="discard-btn" onClick={_handleDiscard}>
                        DISCARD
                    </div>
                </div>
            </Content>
        </Bound>
    )
}

export default React.memo(PopupAddVisitor)

