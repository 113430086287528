import * as types from "../../actions/types";

export const initialState = {
    logUser: null,
};

export default function attendanceReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_LOG_USER: {
            return {
                logUser: action.data
            }
        }
        case types.RESET_LOG_USER: {
            return {
                logUser: null
            }
        }
        default:
            return state;
    }
}