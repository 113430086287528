import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
/// images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";

//action
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";

const Bound = styled.div`
    width: 540px;
    height: 372px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #ffffff;
    font-family: "Open Sans";
    font-style: normal;
    /* font-weight: bold; */
    background: #fafafa;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    /* padding: 33px 40px; */
    padding: 36px 0 51px;
    /* animate */
    animation-name: isShowPopup;
    transition-property: bottom;
    transition-duration: 500ms;
    display: flex;
    flex-direction: column;

    @keyframes isShowPopup {
        from {
            top: -100px;
        }
        to {
            top: 45px;
        }
    }
    .ic-close-remove-popup {
        position: absolute;
        right: 22px;
        top: 22px;
        cursor: pointer;
    }

    .note {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000924;
        padding: 0 104px;
        text-align: center;

        span {
            color: #33ADFF;
        }
    }

    .block-btn {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        .dischard-btn,
        .save-btn {
            width: 160px;
            height: 50px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.03em;
            cursor: pointer;
        }
        .dischard-btn {
            background: #ffffff;
            color: #33ADFF;
            margin-right: 12px;
            border: 0.6px solid #33ADFF;
        }
        .save-btn {
            color: #ffffff;
            background: #33adff;
        }
    }

    
`;
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background: #828A99;
        border-radius: 9px;
    }

    img{
        text-align:center;
    }
    .title-popup{
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #051321;
        text-align: center;
        padding: 12px 0 37px 0;
    }    
    .content-change-popup{
        display: flex;
        flex-direction: column;        
        /* overflow: auto; */
        padding-bottom: 25px;
        
        p{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000924;
            padding-top: 5px;
            span{
                margin-left: 5px;
            }
        }

        ol {
            margin: 0;
            padding: 0 35px;
            list-style-type: none;
            &, & * {
                font-family:"Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                color: #000924;
            }

            & > li:not(:first-child) {
                margin-top: 6px;
            }
            ul {
                padding: 0 0 0 12px;
                list-style-type: '\\002D\\0020\\0020';
                
            }
        }
        
    }

    

    

`

const PopupConfirmChange = ({
    data: {
        contentConfirm,
        handleSaveChange,
        handleDiscardChange
    }
}) => {

    const dispatch = useDispatch()

    // const { TypePopup, data } = useSelector(state => ({
    //     TypePopup: state.popupContainerReducer.typePopup,
    //     data: state.popupContainerReducer.data
    // }));

    const onClosePopup = () => {
        dispatch(closePopup())
    };

    // console.log(data);
    console.log(handleSaveChange);
    
    
   
    return (
        <Bound>
            {/* <img
                className="ic-close-remove-popup"
                src={ic_close_remove_popup}
                onClick={onClosePopup}
                alt="ic_close"
            /> */}
            <Content>
                <img
                    className="ic-warning-remove"
                    src={ic_warning_remove}
                    alt="ic_warning"
                />
                <p className="title-popup">Please review your changes</p>
                <div className="content-change-popup">
                    {contentConfirm}
                </div>

            </Content>
            <div className="note">Please select <span>SAVE CHANGE</span> if you want to save change before you go, or <span>DISCARD CHANGE</span> to go without saving changes</div>
            <div className="block-btn">
                <div className="dischard-btn" onClick={handleDiscardChange?handleDiscardChange:()=>{}}>
                    DISCARD CHANGE
                </div>
                <div className="save-btn" onClick={handleSaveChange?handleSaveChange:()=>{}}>
                    SAVE CHANGE
                </div>
            </div>

        </Bound>
    )
}
export default React.memo(PopupConfirmChange)
