import * as types from "../types";

/**
 * @param {string*} typePopup ex: number defined exact popup to show.
 * @param {obj*}  data data folow with popupNotification
 * @param {boolean}  activeOverlay can touch i
 */
export const openPopup = (typePopup,data,activeOverlay = false, isDisable) => dispatch => {
  // console.log(typePopup,data)
  dispatch({
    type: types.OPEN_POPUP_CONTAINER,
    typePopup,
    data,
    activeOverlay,
    isDisable
  });
};

/**
 * [description]: closePopupNotification
 */
export const closePopup = () => dispatch =>
  dispatch({
    type: types.CLOSE_POPUP_CONTAINER
  });

export const resetPopupNotification = dispatch =>
  dispatch({
    type: types.RESET_POPUP_CONTAINER
  });
