import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//img
//ic
//data
//action
//component
//styled
const Bound = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #34ADFF;
    margin: -4px 0 0 -4px;
  }
  div:nth-child(1) {
    animation-delay: -0.036s;
  }
  div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  div:nth-child(2) {
    animation-delay: -0.072s;
  }
  div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  div:nth-child(3) {
    animation-delay: -0.108s;
  }
  div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  div:nth-child(4) {
    animation-delay: -0.144s;
  }
  div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  div:nth-child(5) {
    animation-delay: -0.18s;
  }
  div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  div:nth-child(6) {
    animation-delay: -0.216s;
  }
  div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  div:nth-child(7) {
    animation-delay: -0.252s;
  }
  div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  div:nth-child(8) {
    animation-delay: -0.288s;
  }
  div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

//========================MAIN COMPONENT==========================
const Spinner = props => {
  //======================Variable================================
  //   const variable = "";
  //======================Props===================================
  //=====>Props Data
  //=====>Props Function
  //   const { props } = props;
  //======>Props Style
  //======================State===================================
  //   const [stateItem, setStateItem] = useState("");
  //======================Redux===================================
  //=====>Redux Action
  //   const dispatch = useDispatch();
  //=====>Redux Props
  //   const state = useSelector(state => state);
  //======================Function=================================
  //   const _myFunction = argument => {};
  //======================Lifecycle Hook===========================
  //=====================Render Main Component=====================
  return (
    <Bound className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Bound>
  );
};
export default React.memo(Spinner); //Avoid re-render === shouldComponentUpdate

//================================Convert Lifecycle Hook======================================================
//[] === ComponentDidMount (run when init component)
// useEffect(() => {
//     console.log("I just mounted!");
//   }, []);
//return closure function === ComponentWillUnmount
//   useEffect(() => {
//     return () => console.log("I am unmounting");
//   }, []);
//[props.count] === ComponentWillrecieveProp\ComponentDidUpdate (run when argument change)
//   useEffect(() => {
//     console.log("count changed", props.count);
//   }, [props.count]);
//Empty === ComponentDidUpdate(allow run )
//   useEffect(() => {
//     console.log("Just updated...");
//   });

Spinner.propTypes = {
  propsItem: PropTypes.string.isRequired
};
Spinner.defaultProps = {
  propsItem: "argument"
};
