import React, { useRef, useEffect, useState } from 'react'
import styled from "styled-components";
import * as faceapi from 'face-api.js';
import { useDispatch } from 'react-redux';
import resizeImage from "resize-image";
import moment from 'moment'


// component
import Button from "../../../Control/Button";

// image
import ic_close_black from "../../../../images/ic_close_black.svg"
import ice_error from "../../../../images/ice_error.svg";

// action
import { closePopup } from "../../../../redux/actions/popupAction/popupContainerAction"

// tool
import { refixCaptureDimension, getNewSize } from "../../../../helper/tools";

const MODEL_URL = '/models'

const ButtonCaptureStyle = styled(Button)`
    margin: 38px auto 0;
    width: 272px;
`;

const VIDEO_DIMENSION = {
    WIDTH: 480,
    HEIGHT: 640
}

const CAPTURE_DIMENSION = {
    WIDTH: 320,
    HEIGHT: 480
}

const Bound = styled.div`
    position: relative;
    transition: height .2s ease-in-out;
   

    .error-camera {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EA3939;
        margin: 0 64px 10px;
        height: 44px;
        border-radius: 5px;
        /* transition: transform .3s ease-in-out; */
        animation-name: errors;
        animation-duration: 1s;
        position: absolute;
        top: -54px;


        & > img {
            margin-left: 19px;
            cursor: pointer;
        }

        span {
            margin-left: 10px;
            color: #FFFFFF;
        }

        button {
            outline: none;
            background: #FFFFFF;
            border: none;
            padding: 5px 10px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #EA3939;
            margin-left: 20px;
            border-radius: 3px;
            margin-right: 12px;
            cursor: pointer;
        }
    }

    @keyframes errors {
        from {
            transform: translate(0, -20vh);
        }
        to {
            transform: translate(0, 0);
        }
    }

    .face-recognize-area {
        position: relative;
        width: 480px;
        height: 640px;
        overflow: hidden;
        video {
            /* border: 1px solid white; */
            /* visibility: hidden; */
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .recognize {
            position: absolute;
            border: 1px solid blue;
            /* width: 320px; */
            /* height: 480px; */

            /* ::before {
                content: '';
                width: 240px;
                background: red;
                height: 1px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            } */
        }
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        /* visibility: hidden; */
        /* border: 2px solid red; */
    }

    .black-screen {
        position: absolute;
        top: 0;
        left: 0;
        width: ${VIDEO_DIMENSION.WIDTH + "px"};
        height: ${VIDEO_DIMENSION.HEIGHT + "px"};
        background: #000000;
    }

    .close-popup {
        position: absolute;
        top: 0;
        right: -60%;
        cursor: pointer;
    }
`;

const TIME_OUT_CAMERA = process.env.NODE_ENV === "development" ? 300000 : 30000; //30 seconds

const CaptureVideo = ({setStep, lstStep, imgCapture, setImgCapture, setImgRecognize}) => {

    const [isLoadedVideo, setIsLoadedVideo] = useState(false);
    // const [stream, setStream] = useState(null);
    const [numOfFace, setNumOfFace] = useState(0);
    const [faceImages, setFaceImages] = useState([])
    const [isMounted, setIsMounted] = useState(false);
    const [errorCamera, setErrorCamera] = useState(false);
    const [isRenderErrorCamera, setIsRenderErrorCamera] = useState(true);
    // const [timeOutCamera, setTimeOutCamera] = useState(null);
    const captureVideo = useRef(null);
    const wrapFaceRecognize = useRef(null);
    const intervalTimeOutCamera = useRef(null);
    const streamRef = useRef(null);
    const errorCameraRef = useRef(null);
    const recognizeEle = useRef(null);
    const dispatch = useDispatch();
    
    
    useEffect(() => {
        setIsMounted(true);
    }, [])

    useEffect(() => {
        if(!isMounted) return;

        // let intervalTimeOutCamera = null;
        
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68TinyNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
          // faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
        ]).then(_startVideo);
        
        const video = captureVideo.current;
        // const canvas = canvasFace.current;
        const wrapRecognize = wrapFaceRecognize.current;
        let interval = null;

        const handleEventPlayVideo = () => {
            const positionVideo = video.getBoundingClientRect();

            const canvasConfig = {
                width: 480,
                height: 640,
            };

            const canvasRecognizeImg = recognizeEle.current = document.createElement('canvas')
            wrapRecognize.append(canvasRecognizeImg);
            canvasRecognizeImg.width = canvasConfig.width;
            canvasRecognizeImg.height = canvasConfig.height;
            canvasRecognizeImg.style.visibility = "hidden";
            const ctx = canvasRecognizeImg.getContext('2d');
            const positionCanvas = canvasRecognizeImg.getBoundingClientRect();
            
            const canvas = faceapi.createCanvasFromMedia(video);
            wrapRecognize.append(canvas)
            canvas.width = VIDEO_DIMENSION.WIDTH;
            canvas.height = VIDEO_DIMENSION.HEIGHT;

            const divRecognize = document.createElement("div");
            wrapRecognize.append(divRecognize);
            divRecognize.classList.add("recognize");
            divRecognize.style.width = canvasConfig.width / 2 + "px";
            divRecognize.style.height = canvasConfig.height / 2 + "px";
            divRecognize.style.left = canvasConfig.width / 4 + "px";
            divRecognize.style.top = canvasConfig.height / 4 + "px";
            const positionDivRecognize = divRecognize.getBoundingClientRect();

            const displaySize = {
                width: VIDEO_DIMENSION.WIDTH,
                height: VIDEO_DIMENSION.HEIGHT,
            };

            const realWidthCanvas = Math.ceil(
              (video.videoWidth * canvasRecognizeImg.width) / positionVideo.width
            );

            const realXStartCrop = Math.ceil((video.videoWidth - realWidthCanvas) / 2);

            interval = setInterval(async () => {
                const logInterval = "interval-" + moment();
                console.time(logInterval);
                ctx.clearRect(0, 0, canvasConfig.width, canvasConfig.height);
                ctx.drawImage(video, realXStartCrop, 0, realWidthCanvas, video.videoHeight, 0, 0, 480, 640);
                
                const detections = await faceapi
                    .detectAllFaces(
                        canvasRecognizeImg,
                        // video,
                        new faceapi.TinyFaceDetectorOptions({
                            inputSize: 160,
                            scoreThreshold: 0.65,
                        })
                    )
                    .withFaceLandmarks(true);


                const detectionsInRecognize = _handleCheckFaceInRecognize(
                    detections,
                    positionDivRecognize,
                    positionCanvas
                );
                canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)

                const resizeDetections = faceapi.resizeResults(
                    detections,
                    displaySize
                );

                const detectionsMatch = _checkMatchFace(
                    resizeDetections,
                    canvasConfig
                );
                setNumOfFace(detectionsMatch.length);
                
                if (detectionsMatch.length > 0) {
                    divRecognize.style.border = "2px solid green";
                } else {
                    divRecognize.style.border = "1px solid blue";
                }

                const faceDetection = detectionsMatch.map(item => item.alignedRect)
                faceapi.drawDetection(canvas, faceDetection, {
                    withScore:
                        process.env.NODE_ENV === "production" ? false : true,
                });
                const landmarksDetection = detectionsMatch.map(item => item.landmarks)
                faceapi.drawLandmarks(canvas, landmarksDetection);

                if(detectionsInRecognize[0]){
                    const { detection } = detectionsInRecognize[0];
                    const item = detection;

                    const detectSize = {
                        posXFace: item.box.x,
                        posYFace: item.box.y,
                        widthFace: item.box.width,
                        heightFace: item.box.height
                    }
                    const { 
                        newX: newXCrop, 
                        newY: newYCrop,
                        widthCrop, 
                        heightCrop, 
                        widthWhiteFill, 
                        heightWhiteFill
                    } = getNewSize(
                        detectSize, 
                        CAPTURE_DIMENSION.WIDTH, 
                        CAPTURE_DIMENSION.HEIGHT, 
                        VIDEO_DIMENSION.WIDTH, 
                        VIDEO_DIMENSION.HEIGHT
                    )


                    const rect = new faceapi.Rect(
                        newXCrop>0?newXCrop:0, 
                        newYCrop>0?newYCrop:0, 
                        widthCrop, 
                        heightCrop
                    );
                    
                    const faceImages = await faceapi.extractFaces(canvasRecognizeImg, [rect])
                    const imgCapture = faceImages[0].toDataURL("image/png");

                    const faceImagesInitial = await faceapi.extractFaces(canvasRecognizeImg, [item])
                    const imgCaptureInitial = faceImagesInitial[0].toDataURL("image/png");
                    setImgRecognize(imgCaptureInitial);
                    
                    const imgResize = resizeImage.resize(
                        faceImages[0],
                        320,
                        320,
                        resizeImage.PNG
                    );

                    const imgCaptureHtml = new Image();
                    imgCaptureHtml.onload = function() {
                        // debugger
                        const canvas = document.createElement('canvas');
                        canvas.width = this.width + (320 - this.width);
                        canvas.height = this.height + (480 - this.height);
                        const ctx = canvas.getContext('2d');
                        ctx.fillStyle = "white";
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(
                            imgCaptureHtml,
                            (320 - this.width) / 2,
                            (480 - this.height) / 2,
                            this.width,
                            this.height
                        );

                        // const outputImgRecog = canvas.toDataURL("image/png");
                        const outputImgRecog = resizeImage.resize(
                            canvas, 
                            CAPTURE_DIMENSION.WIDTH, 
                            CAPTURE_DIMENSION.HEIGHT, 
                            resizeImage.PNG
                        );
                        
                        setFaceImages(outputImgRecog);
                        console.timeEnd(logInterval);
                    }
                    imgCaptureHtml.src = imgResize;
                    
                    // setFaceImages(imgResize);
                    // ======

                    // setFaceImages(faceImages)
                }
                
            })
        }

        video.addEventListener('play', handleEventPlayVideo)

        return () => {
            clearInterval(interval)
            clearTimeout(intervalTimeOutCamera.current)
            video.src=""
            video.removeEventListener('play', handleEventPlayVideo)
            _stopWebcam()
        }
    }, [isMounted])

    const _checkMatchFace = ( resizeDetections, canvasConfig ) => {
        const EPSILON = 5;
        const checkEyeBalance = (leftEye, rightEye) => 
            Math.abs(leftEye[0].y - rightEye[3].y) <= EPSILON
        const checkEyePosition = (leftEye, rightEye) => 
            Math.abs(leftEye[3].x - rightEye[0].x) < canvasConfig.width/4
        const faceIsCenterImg = (leftEyeBrow, rightEyeBrow, jawOutline) => {
            const isFirstXMatch =
                (jawOutline[0].x >= canvasConfig.width / 4 - EPSILON);
            const isLastXMatch =
                (jawOutline[16].x <= canvasConfig.width / 4 + canvasConfig.width/2 + EPSILON) 
            const isCenterVertical = isFirstXMatch && isLastXMatch; 

            const heightFace = jawOutline[8].y - leftEyeBrow[2].y;
            const yUpHead = leftEyeBrow[2].y - heightFace / 2;
            const isFirstYMatch = 
                (yUpHead >= canvasConfig.height / 4 - EPSILON);
            const isLastYMatch =
                jawOutline[8].y <= canvasConfig.height - canvasConfig.height / 4 + EPSILON;
            const isCenterHorizontal = isFirstYMatch && isLastYMatch;
            return isCenterVertical && isCenterHorizontal;
        }
        
        return resizeDetections.filter(item => {
            const { landmarks } = item
            const leftEyeBrow = landmarks.getLeftEyeBrow(); 
            const rightEyeBrow = landmarks.getRightEyeBrow(); 
            const leftEye = landmarks.getLeftEye();
            const rightEye = landmarks.getRightEye(); 
            const jawOutline = landmarks.getJawOutline(); 
            // const isEyeBalance = checkEyeBalance(leftEye, rightEye)
            const isEyePositionMatch = checkEyePosition(leftEye, rightEye)
            // console.log(/* isEyeBalance, */ "----", isEyePositionMatch);
            return (
                // isEyeBalance &&
                isEyePositionMatch &&
                faceIsCenterImg(leftEyeBrow, rightEyeBrow, jawOutline)
            );
        })
    };

    const _handleCheckFaceInRecognize = (detections, positionDivRecognize, positionCanvas) => {
        const detectionMatch = (face, positionDivRecognize, positionCanvas) => {
            const { detection } = face;
            const { box } = detection;
            // const { box } = face;
            const { x, width } = box;
            const { x: xReg, width: widthReg } = positionDivRecognize
            const { x: xCanvas } = positionCanvas;

            const xUpLeftInSide =
                xCanvas + x >= xReg && xCanvas + x < xReg + widthReg;
            const xUpRightInside =
                xCanvas + x + width > xReg && xCanvas + x + width <= xReg + widthReg;
            const xContainDivRecognize = 
                xCanvas + x <= xReg && xCanvas + x + width >= xReg + widthReg;
            // debugger;
            return xUpLeftInSide || xUpRightInside || xContainDivRecognize;
        }

        const lstDectectionMatch = detections.filter((item) =>
            detectionMatch(item, positionDivRecognize, positionCanvas)
        );

        return lstDectectionMatch;
    };

    const _startVideo = async () => {
        try {
            const streamUrl = await navigator.mediaDevices.getUserMedia({
                // video: true
                // video: { width: 480, height: 640 },
                // video: { width: 480 },
                // video: { height: 640 },
                video: { width: { ideal: 4096 }, height: { ideal: 2160 } },
            });
            if(!captureVideo.current){
                streamUrl && streamUrl.getTracks().forEach((track) => track.stop())
                return;
            }
            // setStream(streamUrl)
            captureVideo.current.srcObject = streamUrl;
            streamRef.current = streamUrl
            setIsLoadedVideo(true)
            intervalTimeOutCamera.current = setTimeout(() => {
                _stopWebcam()
                // setIsTimeOutCamera(true)
                setStep(lstStep.TIMEOUT)
                return;
            }, TIME_OUT_CAMERA)

            // setTimeOutCamera(intervalTimeOutCamera)
        }
        catch(err){
            console.log(err)
            setErrorCamera(true);
        }
    }


    const _handleCapture = () => {
        // clearTimeout(timeOutCamera);
        if(errorCamera){
            setIsRenderErrorCamera(true);
            return;
        }

        if(numOfFace === 0){
            _stopWebcam()
            setStep(lstStep.ERROR_CAPTURE_NO_FACE)
            return;
        }

        if(numOfFace > 1){
            _stopWebcam()
            setStep(lstStep.ERROR_CAPTURE_MORE_FACE)
            return;
        }

        // const canvas = document.createElement('canvas');

        // canvas.width = VIDEO_DIMENSION.WIDTH;
        // canvas.height = VIDEO_DIMENSION.HEIGHT;

        // const video = captureVideo.current;
        // canvas.getContext('2d').drawImage(video, 0, 0, VIDEO_DIMENSION.WIDTH, VIDEO_DIMENSION.HEIGHT);

        // const img = canvas.toDataURL("image/png");

        


        const img = faceImages/* [0].toDataURL("image/png"); */
        
        _stopWebcam()
        setImgCapture(img)
        setStep(lstStep.CONFIRM_CAPTURE)
    }

    const _handleClosePopup = () => {
        _stopWebcam()
        dispatch(closePopup())
    }

    const _stopWebcam = async () => {
        // console.log("ping")

        const video = captureVideo.current;
        if(video){
            video.src=""
        }
        setTimeout(() => {
            // console.log(stream)
            // console.log(streamRef)
            // stream && stream.getTracks().forEach((track) => track.stop())
            streamRef.current && streamRef.current.getTracks().forEach((track) => track.stop())
        }, 0)
        
    }

    const _handleRetry = () => {
        setTimeout(() => {
            setErrorCamera(false);
            _startVideo()
        }, 500)
    }


    return (
        <Bound>
            {
                errorCamera && isRenderErrorCamera &&
                <div className="error-camera" ref={errorCameraRef}>
                    <img 
                        src={ice_error} 
                        alt="error"
                        onClick={() => setIsRenderErrorCamera(false)}
                    />
                    <span>Could not find your camera</span>
                    <button onClick={_handleRetry}>Retry</button>
                </div>
            }
            <div 
                className="face-recognize-area"
                ref={wrapFaceRecognize}
            >
                <video 
                    id="inputVideo" 
                    ref={captureVideo}
                    // width={480}
                    height={640}
                    autoPlay 
                    muted 
                    playsInline
                />
                {
                    !isLoadedVideo &&
                    <div className="black-screen"></div>
                }
                {/* <canvas id="overlay" ref={canvasFace} /> */}
                {/* <img
                    src=""
                    id="test"
                /> */}
            </div>
            <img 
                src={ic_close_black} 
                alt="close-popup" 
                className="close-popup"
                onClick={_handleClosePopup}
            />
            <ButtonCaptureStyle 
                type="PRIMARY"
                text="CAPTURE"
                onClick={_handleCapture}
            /> 
        </Bound>
    )
}

export default CaptureVideo
