export const DAY_OFF_SETTING = "DAY_OFF_SETTING";
export const REMOVE_VISITOR = "REMOVE_VISITOR";
export const REMOVE_EMPLOYEE_PROFILE = "REMOVE_EMPLOYEE_PROFILE";
export const POPUP_CAMERA = "POPUP_CAMERA";
export const POPUP_CONFIRM_IMAGE_RECOGNIZE_FACE = "POPUP_CONFIRM_IMAGE_RECOGNIZE_FACE";
export const POPUP_NOTICE_ERROR_IMG_NO_FACE = "POPUP_NOTICE_ERROR_IMG_NO_FACE";
export const POPUP_NOTICE_ERROR_IMG_MORE_FACE = "POPUP_NOTICE_ERROR_IMG_MORE_FACE";
export const REMOVE_DEVICE = "REMOVE_DEVICE";
export const MORE_SETTINGS = "MORE_SETTINGS";
export const ADJUS_TIME_SHEET = "ADJUS_TIME_SHEET";
export const NOT_FOUND_PROFILE = "NOT_FOUND_PROFILE";
export const SHOW_QR_CODE = "SHOW_QR_CODE";
export const UNLINK_LOCATION = "UNLINK_LOCATION";
export const UNLINK_LOCATION_FROM_DEVICE = "UNLINK_LOCATION_FROM_DEVICE";
export const UNLINK_DEVICE_FROM_LOCATION = "UNLINK_DEVICE_FROM_LOCATION";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const REMOVE_LOCATION = "REMOVE_LOCATION";
export const ADD_CHILD_LOCATION = "ADD_CHILD_LOCATION";
export const LINK_DEVICE = "LINK_DEVICE";
export const CONFIRM_CHANGE_DETAIL_LOCATION = "CONFIRM_CHANGE_DETAIL_LOCATION";
export const ALERT_LOGOUT = "ALERT_LOGOUT";
export const ALERT_OUT_SESSION = "ALERT_OUT_SESSION"
export const POPUP_DISABLE_VISTIOR = "POPUP_DISABLE_VISTIOR";
export const POPUP_DISABLE_EMPLOYEE = "POPUP_DISABLE_EMPLOYEE"
export const POPUP_CONFIRM_CANCEL = "POPUP_CONFIRM_CANCEL"
export const POPUP_CONFIRM_CHANGE = "POPUP_CONFIRM_CHANGE"
export const POPUP_ADD_VISITOR = "POPUP_ADD_VISITOR"

///// ERR
export const ERR_CONNECT_SERVER = "ERR_CONNECT_SERVER";
export const EMPLOYEE_ID_ALREADY_EXIT = "EMPLOYEE_ID_ALREADY_EXIT";
export const FACE_HAS_ENROLLED = "FACE_HAS_ENROLLED";
export const SOCIAL_ID_EXISTED = "SOCIAL_ID_EXISTED";
export const UNABLE_EDIT_PROFILE = "UNABLE_EDIT_PROFILE";
export const CURRENT_PASS_WRONG = "CURRENT_PASS_WRONG";
export const CHANGE_PASS_NOT_MATCH = "CHANGE_PASS_NOT_MATCH";
export const UNABLE_CHANGE_PASS_NOT_FILL = "UNABLE_CHANGE_PASS_NOT_FILL";
export const NOT_FOUND_CAMERA = "NOT_FOUND_CAMERA";
export const UNABLE_REMOVE_PROFILE_VISITOR = "UNABLE_REMOVE_PROFILE_VISITOR";
export const CITIZEN_ID_IS_ALREADY = "CITIZEN_ID_IS_ALREADY";
export const ADD_EMPLOYEE_FAIL_EMPLOYEE_ID = "ADD_EMPLOYEE_FAIL_EMPLOYEE_ID";
export const CANNOT_RECOGNIZE_FACE = "CANNOT_RECOGNIZE_FACE";
export const LOCATION_NAME_ALREADY_EXIST = "LOCATION_NAME_ALREADY_EXIST";
export const FILE_TYPE_INVALID = "FILE_TYPE_INVALID"
export const EXPIRATION_DATE_INVALID = "EXPIRATION_DATE_INVALID"
export const PERMITTED_LOCATION_INVALID = "PERMITTED_LOCATION_INVALID"

///// ALERT
export const MUST_HAVE_REMOVED_EMPLOYEE = "MUST_HAVE_REMOVED_EMPLOYEE";
export const MUST_TAKE_PICTURE_EMPLOYEE = "MUST_TAKE_PICTURE_EMPLOYEE";
export const MUST_TAKE_PICTURE_VISITOR = "MUST_TAKE_PICTURE_VISITOR";
export const SOME_FIELD_NOT_FILLED = "SOME_FIELD_NOT_FILLED";
export const PLEASE_ENTER_ALL_INPUT = "PLEASE_ENTER_ALL_INPUT";

///// SUCCESS
export const PASS_HAS_BEEN_CHANGED = "PASS_HAS_BEEN_CHANGED";
export const ADD_EMPLOYEE_PROFILE_SUCCESS = "ADD_EMPLOYEE_PROFILE_SUCCESS";
export const REMOVE_VISITOR_SUCCESS = "REMOVE_VISITOR_SUCCESS";
export const REMOVE_EMPLOYEE_SUCCESS = "REMOVE_EMPLOYEE_SUCCESS";
export const INDEPENDENCE_DAY_UPDATE_SUCCESS =
  "INDEPENDENCE_DAY_UPDATE_SUCCESS";
export const INDEPENDENCE_DAY_ADD_DAYOF = "INDEPENDENCE_DAY_ADD_DAYOF";
export const ADD_VISITOR_PROFILE_SUCCESS = "ADD_VISITOR_PROFILE_SUCCESS";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const REMOVE_DEVICE_SUCCESS = "REMOVE_DEVICE_SUCCESS";
export const ADD_NEW_LOCATION_SUCCESS = "ADD_NEW_LOCATION_SUCCESS";
export const REMOVE_LOCATION_SUCCESS = "REMOVE_LOCATION_SUCCESS";
export const DEVICE_LINK_LOCATION_SUCCESS = "DEVICE_LINK_LOCATION_SUCCESS";
export const DEVICE_UNLINK_LOCATION_SUCCESS = "DEVICE_UNLINK_LOCATION_SUCCESS";
export const IS_NOT_CLOSE_POPUP = 0;
export const ADD_VISITOR_LIST_SUCCESS = "ADD_VISITOR_LIST_SUCCESS";
export const EDIT_VISITOR_LIST_SUCCESS = "EDIT_VISITOR_LIST_SUCCESS"
export const ADD_USER_ERROR_EMPTY_FIELD = "ADD_USER_ERROR_EMPTY_FIELD";
export const ADD_USER_ERROR_EMPTY_AVATAR = "ADD_USER_ERROR_EMPTY_AVATAR";
export const EDIT_USER_DATA_NOT_CHANGE = "EDIT_USER_DATA_NOT_CHANGE";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const REMOVE_VISITOR_STATUS_DISABLED_SUCCESS = "REMOVE_VISITOR_STATUS_DISABLED_SUCCESS"
export const REMOVE_EMPLOYEE_STATUS_DISABLED_SUCCESS = "REMOVE_EMPLOYEE_STATUS_DISABLED_SUCCESS"

export const ADD_EMPLOYEE_LIST_SUCCESS = "ADD_EMPLOYEE_LIST_SUCCESS";

export const dataPopupNotificationERR = {
  ERR_CONNECT_SERVER: {
    width: "336px",
    contentFirst: "Error connect server"
  },
  EMPLOYEE_ID_ALREADY_EXIT: {
    width: "336px",
    contentFirst: "Employee ID already exists"
  },

  LOCATION_NAME_ALREADY_EXIST: {
    width: "336px",
    contentFirst: "Location name already exists",
    showBtn: true,
    nameBtn: "View"
  },
  FACE_HAS_ENROLLED: {
    width: "336px",
    contentFirst: "This face recognition photo already exists",
    showBtn: true,
    nameBtn: "View"
  },
  SOCIAL_ID_EXISTED:{
    width: "336px",
    contentFirst: "Social ID already exists",
    showBtn: true,
    nameBtn: "View"
  },
  CHANGE_PASS_NOT_MATCH: {
    width: "656px",
    contentFirst:
      "Unable to change password: new password and confirm password does not match"
  },
  CURRENT_PASS_WRONG: {
    width: "506px",
    contentFirst: "Unable to change password: Your current password is wrong"
  },
  UNABLE_EDIT_PROFILE: {
    width: "506px",
    contentFirst: "Unable to edit profile: some of required field not filled"
  },
  UNABLE_CHANGE_PASS_NOT_FILL: {
    width: "506px",
    contentFirst: "Unable to change password: some of required field not filled"
  },
  NOT_FOUND_CAMERA: {
    width: "352px",
    contentFirst: "Could not found your camera",
    isAutoClose: IS_NOT_CLOSE_POPUP,
    showBtn: true,
    nameBtn: "Retry"
  },
  UNABLE_REMOVE_PROFILE_VISITOR: {
    width: "391px",
    contentFirst: "Unable to remove profile: Error connect server"
  },
  CITIZEN_ID_IS_ALREADY: {
    width: "336px",
    contentFirst: "Citizen ID already exists",
    // isAutoClose: IS_NOT_CLOSE_POPUP,
    showBtn: true,
    nameBtn: "VIEW"
  },
  ADD_EMPLOYEE_FAIL_EMPLOYEE_ID: {
    width: "336px",
    contentFirst: "Employee ID already exists",
    // isAutoClose: IS_NOT_CLOSE_POPUP,
    showBtn: true,
    nameBtn: "VIEW"
  },
  CANNOT_RECOGNIZE_FACE: {
    width: "440px",
    contentFirst: "Cannot recognize face, please select another image !"
  },
  FILE_TYPE_INVALID: {
    width: "336px",
    contentFirst: "File type invalid"
  },
  ADD_USER_ERROR_EMPTY_FIELD: {
    width: "336px",
    contentFirst: "Unable to add profile: please enter all the general info input field"
  },
  ADD_USER_ERROR_EMPTY_AVATAR: {
    width: "336px",
    contentFirst: "You have to upload the visitor's avatar"
  },
  EXPIRATION_DATE_INVALID:{
    width: "336px",
    contentFirst: "Expiration date invalid"
  },
  PERMITTED_LOCATION_INVALID:{
    width: "336px", 
    contentFirst: "Some Locations you don't have permission to change"
  }
};

export const dataPopupNotificationALERT = {
  MUST_HAVE_REMOVED_EMPLOYEE: {
    width: "600px",
    contentFirst: "You have removed",
    contentLast: "from Employee Profile"
  },
  MUST_TAKE_PICTURE_EMPLOYEE: {
    width: "478px",
    contentFirst: "You must take a picture or upload image to add employee"
  },
  MUST_TAKE_PICTURE_VISITOR: {
    width: "478px",
    contentFirst: "You must take a picture or upload image to add visitor"
  },
  SOME_FIELD_NOT_FILLED: {
    width: "370px",
    contentFirst: "Some of required field not filled"
  },
  PLEASE_ENTER_ALL_INPUT: {
    width: "435px",
    contentFirst: "Unable to add profile: please enter all the input field"
  },
  EDIT_USER_DATA_NOT_CHANGE: {
    width: "336px",
    contentFirst: "Nothing change!"
  },
};
export const dataPopupNotificationSUCCESS = {
  PASS_HAS_BEEN_CHANGED: {
    width: "401px",
    contentFirst: "Your password has been changed successfully"
  },
  ADD_EMPLOYEE_PROFILE_SUCCESS: {
    width: "599px",
    contentLast: "has been added Employee Profile"
  },
  INDEPENDENCE_DAY_UPDATE_SUCCESS: {
    width: "504px",
    contentLast: "has been successfully updated"
  },
  INDEPENDENCE_DAY_ADD_DAYOF: {
    width: "504px",
    contentLast: "has been added Day Off"
  },
  ADD_VISITOR_PROFILE_SUCCESS: {
    width: "406px",
    contentLast: "has been added Active visitors"
  },
  ADD_DEVICE_SUCCESS: {
    width: "406px"
  },
  REMOVE_DEVICE_SUCCESS: {
    width: "406px"
  },
  ADD_NEW_LOCATION_SUCCESS: {
    width: "406px"
  },
  REMOVE_LOCATION_SUCCESS: {
    width: "406px"
  },
  DEVICE_UNLINK_LOCATION_SUCCESS: {
    width: "406px"
  },
  DEVICE_LINK_LOCATION_SUCCESS: {
    width: "406px"
  },
  ADD_VISITOR_LIST_SUCCESS: {
    width: "394px",
    contentLast: "has been added Visitors List"
  },
  ADD_EMPLOYEE_LIST_SUCCESS: {
    width: "394px",
    contentFirst: "Employee profile",
    contentLast: "has been added Employee profile"
  },
};

export const dataPopupNotificationUPDATESUCCESS = {   
  REMOVE_EMPLOYEE_SUCCESS: {
    width: "599px",
    contentFirst: "Employee profile",
    contentLast: "has been removed"
  },
  REMOVE_VISITOR_SUCCESS: {
    width: "599px",
    contentFirst: "Visitor profile",
    contentLast: "has been removed"
  },
  PROFILE_UPDATE_SUCCESS: {
    width: "599px",
    contentFirst: "View profile",
    contentLast: "has been successfully updated"
  },
  REMOVE_VISITOR_STATUS_DISABLED_SUCCESS: {
    width: "599px",
    contentFirst: "Visitor profile",
    contentLast: "has been disabled"
  }
}

//////////////////////////////////////////////
/////////////// Test Day Off /////////////////
//////////////////////////////////////////////

export const dataNameDepartment = [
  "Hành chính - Nhân sự",
  "Tài chính - Kế toán",
  "Sản xuất - Công nghệ - R&D",
  "Quản lý chất lượng - QA/QC",
  "Kỹ thuật - Bảo trì",
  "Kế hoạch - Kinh doanh",
  "Mua hàng (cung ứng) - Xuất Nhập Khẩu"
];

export const dataTestDayOff = [
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
    // Type1: true,
    // Type2: true,
    // Type3: false,
    // Type4: true,
    // Type5: true,
    // Type6: true,
    // Type7: true
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  },
  {
    ID: "0001",
    DayOffName: "TET Holiday",
    DayStart: "15/02/2019",
    DayEnd: "30/04/2019",
    NumberDayOff: "01",
    ListType: [true, false, false, true, false, true, true]
  }
];
