import * as types from "../../actions/types";
import { permission } from '../../../constant/permission'
import * as keys from '../../../constant/key'
import * as actions from '../../../constant/action'

export const initialState = {
  data: {},
  error: "",
  checkEmail: null,
  checkLogin: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHECK_EMAIL_LOGIN_START:
      return {
        ...state,
        type: action.type,
        isLoading: true
      };
    case types.CHECK_EMAIL_LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        type: action.type,
        checkEmail: { ...action.payload },
        checkLogin:null,
        isLoading: false
      };
    case types.CHECK_EMAIL_LOGIN_FAIL:
      return {
        ...state,
        type: action.type,
        checkEmail: action.payload,
        err: action.err,
        isLoading: false
      };
   
    case types.LOGIN_START:
      return {
        ...state,
        type: action.type,
        isLoading: false,
        error: ""
      };
    case types.LOGIN_SUCCESS:
      let checkLogin = action.payload
      let permissionRole = permission[action.payload.Role]
      checkLogin.Permission = {
        [keys.VISITOR]: permissionRole[keys.VISITOR]?{
          accessGetAll: permissionRole[keys.VISITOR].find(item => item === actions.GET_ALL)?true:false,
          accessView: permissionRole[keys.VISITOR].find(item => item === actions.DETAIL)?true:false,
          accessNew: permissionRole[keys.VISITOR].find(item => item === actions.INSERT)?true:false,
          accessEditInfo: permissionRole[keys.VISITOR].find(item => item === actions.UPDATE_INFO)?true:false,
          accessAccessLocation: permissionRole[keys.VISITOR].find(item => item === actions.ACCESS_LOCAION)?true:false,
          accessRemove: permissionRole[keys.VISITOR].find(item => item === actions.REMOVE)?true:false
        }:null,
        [keys.LOCATION]: permissionRole[keys.LOCATION]?{
          accessGetAll: permissionRole[keys.LOCATION].find(item => item === actions.GET_ALL)?true:false,
          accessNew: permissionRole[keys.LOCATION].find(item => item === actions.INSERT)?true:false,
          accessView: permissionRole[keys.LOCATION].find(item => item === actions.DETAIL)?true:false,
          accessEdit: permissionRole[keys.LOCATION].find(item => item === actions.UPDATE || item === actions.ACCESS_LOCAION)?true:false,
          accessRemove: permissionRole[keys.LOCATION].find(item => item === actions.REMOVE)?true:false
        }:null,
        [keys.DEVICE]: permissionRole[keys.DEVICE]?{
          accessGetAll: permissionRole[keys.DEVICE].find(item => item === actions.GET_ALL)?true:false,
          accessNew: permissionRole[keys.DEVICE].find(item => item === actions.INSERT)?true:false,
          accessView: permissionRole[keys.DEVICE].find(item => item === actions.DETAIL)?true:false,
          accessEdit: permissionRole[keys.DEVICE].find(item => item === actions.UPDATE || item === actions.ACCESS_LOCAION)?true:false,
          accessRemove: permissionRole[keys.DEVICE].find(item => item === actions.REMOVE)?true:false
        }:null,
        [keys.ACTIVITY]: permissionRole[keys.ACTIVITY]?{
          accessGetAll: permissionRole[keys.ACTIVITY].find(item => item === actions.GET_ALL)?true:false,
        }:null,
        [keys.EMPLOYEE]: permissionRole[keys.EMPLOYEE]?{
          accessGetAll: permissionRole[keys.EMPLOYEE].find(item => item === actions.GET_ALL)?true:false,
          accessNew: permissionRole[keys.EMPLOYEE].find(item => item === actions.INSERT)?true:false,
          accessView: permissionRole[keys.EMPLOYEE].find(item => item === actions.DETAIL)?true:false,
          accessEditInfo: permissionRole[keys.EMPLOYEE].find(item => item === actions.UPDATE_INFO)?true:false,
          accessAccessLocation: permissionRole[keys.EMPLOYEE].find(item => item === actions.ACCESS_LOCAION)?true:false,
          accessRemove: permissionRole[keys.EMPLOYEE].find(item => item === actions.REMOVE)?true:false
        }:null,
        [keys.ATTENDANCE]: permissionRole[keys.ATTENDANCE]?{
          accessGetAll: permissionRole[keys.ATTENDANCE].find(item => item === actions.GET_ALL)?true:false,
        }:null
      }
      return {
        ...state,
        type: action.type,
        checkLogin,
        isLoading: false,
        error: ""
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        checkLogin: action.payload,
        isLoading: false,
        error: "Login fail"
      };
      case types.LOGOUT_SUCCESS:
        return {
          ...state,
          checkLogin: null,
          checkEmail:null,
          isLoading: false,
          error: "Login fail"
        };
    
    default:
      return state;
  }
}
