import * as types from "../types";
import { host } from "../../../host/host";
import Axios from "axios";
import moment from "moment";
import * as keys from '../../../constant/key'
import { closePopup, openPopup } from "../popupAction/popupContainerAction";
var hash = require("hash.js");

const SuccessCode = 0;

export const dateTimeExpiredInMilisecon = 24 * 60 * 60 * 1000;
// let timeoutLogout = null;
const removeLogedInDateTime = () => {
  localStorage.removeItem('dateTimeLogedIn');
  clearTimeout(window.timeoutLogout)
}

export const checkEmailLogin = UserName => async dispatch => {
  const apiCheckEmailLogin = host + "admin/check-email";
  const dataQueryFormat = JSON.stringify({
    UserName
  });
  
  dispatch({
    type: types.CHECK_EMAIL_LOGIN_START
  });
  try {
    const res = await Axios.post(apiCheckEmailLogin, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    if (callApiSuccess) {
      data.UserName = UserName;
      dispatch({
        type: types.CHECK_EMAIL_LOGIN_SUCCESS,
        payload: data
      });
    } else {
      dispatch({
        type: types.CHECK_EMAIL_LOGIN_FAIL,
        payload: data
      });
    }
  } catch (err) {
    dispatch({
      type: types.CHECK_EMAIL_LOGIN_FAIL,
      err
    });
  }
};

export const login = (UserName, Pwd) => async dispatch => {
  const apiLogin = host + "admin/login";
  const dataQueryFormat = JSON.stringify({
    UserName,
    Pwd: hash
      .sha256()
      .update(Pwd)
      .digest("hex")
  });
  dispatch({
    type: types.LOGIN_START
  });
  try {
    const res = await Axios.post(apiLogin, dataQueryFormat);
    const { data } = res;
    const callApiSuccess = data.Code === SuccessCode ? true : false;
    // console.log(res);
    if (callApiSuccess) {
      data.UserName = UserName;
      localStorage.setItem(keys.WEE_IO_LOGIN_DATA, JSON.stringify({ ...data }));
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: data
      });
      setLogedInDateTime(dispatch)
    } else {
      dispatch({
        type: types.LOGIN_FAILURE,
        payload: data
      });
    }
  } catch (err) {
    // console.log(err);
    dispatch({
      type: types.LOGIN_FAILURE,
      err
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  const apiLogout = host + "admin/logout";
  const store = getState()
  if(store.loginReducer.checkLogin){
    const dataQueryFormat = JSON.stringify({
      UID: store.loginReducer.checkLogin.UID
    });
    try {
      const res = await Axios.post(apiLogout, dataQueryFormat);
      // console.log(res)
      const { data } = res;
      const callApiSuccess = data.Code === SuccessCode ? true : false;
      if (callApiSuccess) {
        
      } else {
        dispatch({
          type: types.LOGOUT_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: types.LOGOUT_FAIL,
        err
      });
    }
  }
  

  dispatch({
    type: types.LOGOUT_START
  });
  dispatch({
    type: types.LOGOUT_SUCCESS
  });
  dispatch({
    type: types.REFRESH_ROUTER
  });
  localStorage.removeItem(keys.WEE_IO_LOGIN_DATA);
  removeLogedInDateTime()
};

export const callInSetTimeOut = (dispatch) => {
  dispatch(logout());
  dispatch(closePopup());
  dispatch(openPopup("ALERT_OUT_SESSION"));
  removeLogedInDateTime()
}

const setLogedInDateTime = (dispatch) => {
  const dateTimeLogedIn = moment().toISOString();
  localStorage.setItem('dateTimeLogedIn', dateTimeLogedIn)
  window.timeoutLogout = setTimeout(() => {
    callInSetTimeOut(dispatch)
  }, dateTimeExpiredInMilisecon)
}
